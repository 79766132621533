import { twMerge } from 'tailwind-merge';

import type { IconName } from 'shared/ui/Icon';

import { getCategoryLabel } from 'pages/GalleryHome/helpers/getCategoryLabel';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Select } from 'shared/ui/Select';

const filterItems: filterItem[] = [
  { icon: 'box', value: 'all' },
  { icon: 'image', value: 'image-generation' },
  { icon: 'question', value: 'question-answering' },
  { icon: 'language', value: 'translation' },
  { icon: 'logs', value: 'summarization' },
  { icon: 'galleryHorizontalEnd', value: 'image-segmentation' },
  { icon: 'fullscreen', value: 'object-detection' },
  { icon: 'check', value: 'text-generation' }, // Todo: There is no icon for the following items
  { icon: 'check', value: 'depth-estimation' },
  { icon: 'check', value: 'text-classification' },
  // { icon: 'check', value: 'feature-extraction' }, // Todo: There is no model for this category
  { icon: 'check', value: 'image-classification' },
  { icon: 'check', value: 'token-classification' },
];

type ExtractValues<T> = T extends { value: infer V } ? V : never;
type filterItemValues = ExtractValues<(typeof filterItems)[number]>;

export type TypeFilter = filterItemValues;

export type SortFilter =
  | 'downloads-higest'
  | 'likes-higest'
  | 'name-higest'
  | 'name-lowest'
  | 'price-highest'
  | 'price-lowest'
  | 'ranking-higest'
  | 'ranking-lowest';

export type FilterState = {
  globalSearch?: string;
  sort?: SortFilter | null;
  type?: TypeFilter | null;
};

type Props = {
  filters: FilterState;
  onFilterChange: (filters: FilterState) => void;
};

type filterItem = {
  icon: IconName;
  value: string;
};

export const GalleryFilters = ({ filters, onFilterChange }: Props) => {
  const sm = useMinWidthMediaQuery('sm');

  const onSortChange = (value: SortFilter) => {
    onFilterChange({ sort: value });
  };

  const onTypeChange = (value: TypeFilter) => {
    if (value === 'all') {
      onFilterChange({ type: null });
    } else {
      onFilterChange({ type: value });
    }
  };

  return (
    <div className="mt-5 flex h-fit items-center justify-between px-4">
      <div className={twMerge('flex items-center gap-4', !sm && 'flex-col items-start gap-2')}>
        <span className="text-2xl font-bold text-blue-900">Gallery</span>
        <div className="flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
          <Select
            className="h-9 w-fit rounded-lg bg-gray-100 p-3 pl-5 text-sm inner-border-0"
            onValueChange={onTypeChange}
            placeholder="All"
            placeholderClassName="font-medium text-gray-600"
            value={filters.type ? filters.type : undefined}
          >
            <Select.Content className="z-10 w-fit rounded-lg px-2 pb-2 pt-1 shadow-sm">
              <div className="border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-gray-400">
                Select Multiple Model Types
              </div>

              <div className="mt-2 grid grid-cols-2 gap-1.5 lg:grid-cols-3">
                {filterItems.map((item) => (
                  <Select.Item
                    className="group flex h-fit w-56 flex-row justify-start gap-2 rounded-md border border-blue-50 p-3 text-sm font-medium text-corduroy-750 aria-selected:bg-clay-20 hover:bg-clay-20"
                    hasIndicator={false}
                    key={item.value}
                    startSlot={
                      <div className="size-8 rounded-lg bg-clay-10 p-2">
                        <Icon
                          className="size-3 p-0.5 text-clay-350 group-hover:text-clay-700"
                          name={item.icon}
                        />
                      </div>
                    }
                    value={item.value}
                  >
                    {getCategoryLabel(item.value)}
                  </Select.Item>
                ))}
              </div>

              <div className="mt-2 flex h-8 w-full justify-between">
                <Button className="pl-4" color="secondary" size="extra-small" variant="filled-light">
                  <Icon className="size-3 text-blue-800" name="refresh" />
                  <div className="mt-px text-[0.8125rem] font-medium text-blue-800">Reset all</div>
                </Button>
              </div>
            </Select.Content>
          </Select>

          <div className="flex h-9 cursor-not-allowed items-center gap-2 rounded-lg bg-gray-100 px-4 opacity-60">
            <Icon className="-mt-px size-4 text-primary-800" name="logo" />
            <span className="text-sm font-medium text-gray-600">Nesa&apos;s Pick</span>
          </div>

          <div className="flex h-9 cursor-not-allowed items-center gap-2 rounded-lg bg-gray-100 px-4 opacity-60">
            <Icon className="-mt-px size-4 text-green-500" name="star" />
            <span className="text-sm font-medium text-gray-600">Featured</span>
          </div>
        </div>
      </div>

      {sm && (
        <div className="right-4 box-border flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
          <Select
            className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
            iconName="arrowDownUp"
            onValueChange={onSortChange}
            placeholder="Sort by"
            placeholderClassName="font-medium text-gray-600"
            value={filters.sort ? filters.sort : undefined}
          >
            <Select.Content className="z-10 rounded-lg p-1 shadow-sm">
              <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                Sort by
              </div>
              <Select.Item
                className="text-sm text-corduroy-750 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="newest"
              >
                Newest
              </Select.Item>
              <Select.Item
                className="border-b border-blue-50 text-sm text-corduroy-750 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="oldest"
              >
                Oldest
              </Select.Item>
              <Select.Item
                className="text-sm text-corduroy-750 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="name-lowest"
              >
                Name Ascending
              </Select.Item>
              <Select.Item
                className="border-b border-blue-50 text-sm text-corduroy-750 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="name-highest"
              >
                Name Decending
              </Select.Item>
              <Select.Item
                className="text-sm text-corduroy-750 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="ranking-highest"
              >
                Most Rated
              </Select.Item>
              <Select.Item
                className="text-sm text-corduroy-750 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="likes-highest"
              >
                Most Likes
              </Select.Item>
              <Select.Item
                className="border-b border-blue-50 text-sm text-corduroy-750 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="downloads-highest"
              >
                Most Downloaded
              </Select.Item>
              <Select.Item
                className="text-sm text-corduroy-750 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="price-highest"
              >
                Price Highest
              </Select.Item>
              <Select.Item
                className="text-sm text-corduroy-750 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="price-lowest"
              >
                Price Lowest
              </Select.Item>
            </Select.Content>
          </Select>

          <Select
            className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
            disabled={true}
            iconName="filter"
            placeholder="Filter"
            placeholderClassName="font-medium text-gray-600"
          >
            <Select.Content className="z-10 rounded-lg px-2 pb-2 pt-1 shadow-sm">
              <div className="mb-2 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                Filter
              </div>

              <div className="flex w-full flex-col">
                <div className="flex justify-between pb-1.5">
                  <div className="text-xs font-medium text-corduroy-650">Data Range</div>
                  <div className="text-xs font-medium text-primary-800">Reset</div>
                </div>

                <div className="flex flex-col gap-1">
                  <div className="h-3.5 text-[0.6875rem] font-medium text-gray-400">From</div>
                  <div className="h-9 rounded-lg border border-corduroy-150 px-2 py-2.5">hi</div>
                </div>
              </div>

              {/* <hr className="border-t border-blue-50" /> */}

              <div className="mt-2 flex h-8 w-full justify-between">
                <div className="flex items-center gap-2 rounded-lg bg-corduroy-25 pl-2.5 pr-3">
                  <Icon className="size-3 text-blue-800" name="refresh" />
                  <div className="mt-px text-[0.8125rem] font-medium text-blue-800">Reset all</div>
                </div>

                <div className="flex items-center gap-2 rounded-lg bg-primary-800 pl-2.5 pr-3">
                  <Icon className="size-3 text-tusk-100" name="check" />
                  <div className="mt-px text-[0.8125rem] font-semibold text-white">Apply</div>
                </div>
              </div>
            </Select.Content>
          </Select>
        </div>
      )}
    </div>
  );
};
