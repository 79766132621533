import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance, setupInterceptors } from 'shared/config/axiosInstance';

import type { AuthResponse, LoginParams } from './types';

export const useLoginMutation = (options: UseMutationOptions<AuthResponse, Error, LoginParams> = {}) => {
  return useMutation({
    mutationFn: async (params: LoginParams) => {
      const { data } = await axiosInstance.post<AuthResponse>('/auth/login', params);

      setupInterceptors(data?.access_token);

      return data;
    },
    ...options,
  });
};
