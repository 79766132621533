import { memo, useEffect } from 'react';

import { motion } from 'framer-motion';

import type { QueryParams } from 'pages/QueryPage/ui/QueryHyperparams';
import type { Model } from 'shared/api/models/types';

import { useUser } from 'app/stores/user';
import { QAResponse } from 'features/ModelQuery/ui/QAResponse';
import { TokenClassificationResponse } from 'features/ModelQuery/ui/TokenClassificationResponse';
import { TranslationResponse } from 'features/ModelQuery/ui/TranslationResponse';
import { useErrorSubmitMutation } from 'shared/api/errors/useErrorSubmitMutation';
import { useGetFileByCidQuery } from 'shared/api/ipfs/useGetFileByCidQuery';
import { Button } from 'shared/ui/Button';
import { JsonEditor } from 'shared/ui/JsonEditor';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  ipfsLink?: string;
  model?: Model;
  requestData?: QueryParams;
  textList: string[];
};

// TODO: check what's going on with props and why are they changing often when user is waiting for response
export const ResponseContent = memo(({ ipfsLink, model, requestData, textList }: Props) => {
  const {
    data: fileData,
    error,
    isError,
    isLoading: isLoadingFile,
    refetch,
  } = useGetFileByCidQuery(ipfsLink!, {
    enabled: !!ipfsLink,
  });

  const { user } = useUser();
  const { mutateAsync: logError } = useErrorSubmitMutation();

  useEffect(() => {
    if (!isError) return;

    logError({
      error: 'IPFS fetch error',
      modelId: model?._id || '',
      params: {
        error: `${error}`,
        ipfsLink,
        modelName: model?.name,
      },
      userId: user?._id || '',
    });
  }, [error, isError, logError, model?._id, model?.name, user?._id, ipfsLink]);

  if (isLoadingFile) {
    return <Spinner className="size-4" />;
  }

  if (!!ipfsLink && isError) {
    return (
      <div className="my-auto flex flex-col items-center justify-center gap-3">
        <p className="text-center text-corduroy-500">Error while fetching a response</p>
        <Button onClick={() => refetch()}>Try again</Button>
      </div>
    );
  }

  if (fileData?.type === 'image') {
    return (
      <div className="flex flex-col overflow-hidden">
        <img
          alt="image"
          className="mr-auto max-h-[-webkit-fill-available] max-w-[-webkit-fill-available] object-contain"
          src={fileData?.data}
        />
      </div>
    );
  }

  if (fileData?.type === 'data') {
    if (model?.type === 'question-answering') {
      console.log('fileData', fileData);
      return <QAResponse context={requestData?.context} data={fileData.data} />;
    }

    if (model?.type === 'token-classification') {
      return <TokenClassificationResponse data={fileData.data} input={requestData?.question} />;
    }

    if (model?.type === 'translation') {
      return <TranslationResponse data={fileData.data} />;
    }

    if (typeof fileData?.data === 'object') {
      return (
        <div className="flex h-full flex-col">
          <JsonEditor className="h-full" readOnly value={fileData?.data} />
        </div>
      );
    }
    return <span className="break-all">{JSON.stringify(fileData?.data)}</span>;
  }

  if (textList.length > 0) {
    return (
      <div className="flex grow flex-col">
        <div className="inline-block text-base text-corduroy-900">
          {textList.map((item, i) => {
            return (
              <motion.span animate={{ opacity: 1 }} initial={{ opacity: 0 }} key={i}>
                {item}
              </motion.span>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <div className="absolute left-1/2 top-1/2 flex grow -translate-x-1/2 -translate-y-1/2 items-center justify-center text-center text-base font-light text-corduroy-500">
      No Data Available
    </div>
  );
});
