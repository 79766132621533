import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { GenerateModelImageParams, GenerateModelImageResponse } from './types';

export const useGenerateModelImageMutation = (
  options: UseMutationOptions<GenerateModelImageResponse, Error, GenerateModelImageParams> = {},
) => {
  return useMutation({
    mutationFn: async (params: GenerateModelImageParams) => {
      const { data } = await axiosInstance.post<GenerateModelImageResponse>(
        `/models/generate/image`,
        params,
        { timeout: 70000 },
      );

      return data;
    },
    ...options,
  });
};
