import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Tabs } from 'shared/ui/Tabs';

import { ExplorerTab } from './ui/ExplorerTab';
import { FAQTab } from './ui/FAQTab';
import { LeaderBoardTab } from './ui/LeaderBoardTab';
import { LeaderBoardTabs } from './ui/LeaderBoardTabs';

export const LeaderBoard = () => {
  return (
    <AnimateRoute className="flex-1 bg-white px-2 py-4 md:p-4 xl:p-9">
      <LeaderBoardTabs>
        <Tabs.Content value="leader-board">
          <LeaderBoardTab />
        </Tabs.Content>
        <Tabs.Content value="faq">
          <FAQTab />
        </Tabs.Content>
        <Tabs.Content value="explorer">
          <ExplorerTab />
        </Tabs.Content>
      </LeaderBoardTabs>
    </AnimateRoute>
  );
};
