import type { AnimationProps } from 'framer-motion';

import { type PropsWithChildren, createContext, useContext, useState } from 'react';

import { AnimatePresence, invariant, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { useEvent } from 'shared/hooks/useEvent';

import { Icon } from '../Icon';

type Props = {
  defaultExpanded?: boolean;
  isExpanded?: boolean;
  onChange?: (isExpanded: boolean) => void;
};

type AccordionProps = {
  isExpanded?: boolean;
  onChange?: () => void;
};

const AccordionContext = createContext<AccordionProps>({
  isExpanded: false,
  onChange: () => {},
});

const useAccordionState = () => useContext(AccordionContext);

export const Accordion = ({ children, defaultExpanded, isExpanded, onChange }: PropsWithChildren<Props>) => {
  const [innerExpanded, setInnerExpanded] = useState(defaultExpanded);
  const isOpen = isExpanded ?? innerExpanded;

  const handleChange = useEvent(() => {
    onChange?.(!isExpanded);
    setInnerExpanded((prev) => !prev);
  });

  return (
    <AccordionContext.Provider value={{ isExpanded: isOpen, onChange: handleChange }}>
      {children}
    </AccordionContext.Provider>
  );
};

const AccordionTrigger = ({
  children,
  className,
  containerClassName,
}: PropsWithChildren<{ containerClassName?: string } & ClassName>) => {
  const ctx = useAccordionState();

  invariant(!!ctx, 'AccordionTrigger should be wrapped in Accordion');

  const { isExpanded, onChange } = ctx;

  return (
    <div className={twMerge('flex cursor-pointer justify-between', containerClassName)} onClick={onChange}>
      <h5 className={twMerge('text-lg font-normal text-black', className)}>{children}</h5>
      <Icon
        className={twMerge('rotate-180 text-corduroy-600 transition-transform', !isExpanded && 'rotate-0')}
        name="arrowDownSm"
      />
    </div>
  );
};

const AccordionContent = ({
  animate = {},
  children,
  className,
}: PropsWithChildren<{ animate?: Omit<AnimationProps['animate'], 'boolean'> } & ClassName>) => {
  const ctx = useAccordionState();

  invariant(!!ctx, 'AccordionContent should be wrapped in Accordion');

  const { isExpanded } = ctx;

  return (
    <AnimatePresence>
      {isExpanded && (
        <motion.div
          animate={{
            height: 'auto',
            maxHeight: 300,
            opacity: 1,
            ...animate,
          }}
          className={twMerge('grid min-h-fit grid-cols-1 gap-2.5 overflow-y-scroll', className)}
          exit={{ height: 0, opacity: 0 }}
          initial={{ height: 0, opacity: 0 }}
        >
          <div className="col-span-full h-2"></div>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Accordion.Trigger = AccordionTrigger;
Accordion.Content = AccordionContent;
