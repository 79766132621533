import type { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = {
  selected: boolean;
};

export const ButtonTab = ({
  children,
  className,
  selected,
  ...props
}: Omit<ComponentProps<'button'>, keyof Props> & Props) => {
  return (
    <button
      {...props}
      aria-selected={selected}
      className={twMerge(
        'min-w-20 cursor-pointer rounded-lg bg-transparent px-3 py-2 text-sm font-semibold text-corduroy-800 transition-all duration-200',
        'hover:text-primary-1000',
        'aria-selected:bg-primary-1000 aria-selected:text-white',
        'aria-selected:hover:text-white',
        className,
      )}
    >
      {children}
    </button>
  );
};
