import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { twJoin, twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { UserModelList } from 'features/UserModelList/UserModelList';
import { UserNodeList } from 'features/UserNodeList';
import { useGetQueryHistoryByUserQuery } from 'shared/api/queryHistory/useGetQueryHistoryByUserQuery';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

import avatarMockSrc from './assets/avatar-mock.png';
import bgSrc from './assets/bg.png';

type ProfileTabs = 'models' | 'nodes';
const tabs: { label: string; value: ProfileTabs }[] = [
  { label: 'My Models', value: 'models' },
  { label: 'My Nodes', value: 'nodes' },
];

export const Profile = () => {
  const navigate = useNavigate();
  // const { id } = useParams<{ id: string }>();
  const { user } = useUser();
  const sm = useMinWidthMediaQuery('sm');

  const { data: queryHistoryData } = useGetQueryHistoryByUserQuery(
    { limit: 1, skip: 0, userId: user!._id },
    { enabled: !!user?._id },
  );

  const achievements = [
    { label: 'Rank', value: '-' },
    { label: 'No. of Queries', value: queryHistoryData?.total_count || '-' },
    { label: 'Models', value: '1' },
  ];

  console.log('data', queryHistoryData?.total_count);

  const [selectedSection, setSelectedSection] = useState<ProfileTabs>('models');

  const achievementsContent = (
    <div className="mb-6 mt-4 flex flex-1 flex-wrap items-end gap-3 sm:my-0">
      {achievements.map((el) => (
        <div
          className="flex min-w-20 flex-col items-center rounded-xl border border-corduroy-200 bg-white p-2 sm:min-w-32 2xl:gap-0.5 2xl:py-2"
          key={el.label}
        >
          <h4 className="text-sm font-bold leading-none text-primary-1000 sm:text-base 2xl:text-lg">
            {el.value}
          </h4>
          <p className="text-xs font-medium leading-none text-corduroy-800 sm:text-sm">{el.label}</p>
        </div>
      ))}
    </div>
  );

  const editProfileButtonContent = (
    <Button color="secondary" onClick={() => navigate('/settings')} size="small" variant="filled-light">
      <span className="inline-flex items-center gap-1">
        {/* <Icon className="-ml-1.5 size-5 text-primary-800" name="edit" /> */}
        <span>Edit Profile</span>
      </span>
    </Button>
  );

  const containerClassName = 'rounded-2xl bg-white p-4 mx-auto max-w-7xl drop-shadow-smAll';

  const hasName = !!user?.first_name || !!user?.last_name;

  return (
    <div className="relative px-3 pb-10 pt-36">
      <img
        alt=""
        className="absolute inset-x-0 top-0 h-36 w-full bg-no-repeat object-cover object-center"
        src={bgSrc}
      />
      <header className={twJoin('relative -mt-24 mb-8 sm:-mt-20 2xl:-mt-24', containerClassName)}>
        <div className="flex items-center sm:items-stretch">
          <img
            className="size-20 rounded-2xl bg-no-repeat object-cover object-center sm:size-32 2xl:size-40"
            src={avatarMockSrc}
          />
          <div className="ml-5 flex flex-1 flex-col">
            <div className="flex flex-wrap">
              <div className="flex-1">
                <div className="mb-1 flex items-center gap-1">
                  <h3 className="text-xl font-normal text-corduroy-900 2xl:text-2xl">
                    {hasName ? `${user?.first_name || ''} ${user?.last_name || ''}` : user?.email || ''}
                    {/* {user?.email || ''} */}
                  </h3>
                  {/* <IsUniqueIcon className="size-4" /> */}
                  {/* <div className="rounded-sm bg-corduroy-400 p-1 text-[10px] leading-none text-white">
                    {user.badge}
                  </div> */}
                </div>
                <div className="flex flex-wrap gap-x-4 gap-y-0.5">
                  {([{ icon: 'envelope', label: user?.email }] as const).map((el) => (
                    <div className="flex items-center gap-1" key={el.icon}>
                      <Icon className="mt-[-2px] size-5 text-corduroy-500" name={el.icon} />
                      <div className="text-base/none font-light text-corduroy-500">{el.label}</div>
                    </div>
                  ))}
                </div>
              </div>
              {sm && editProfileButtonContent}
            </div>
            {sm && achievementsContent}
          </div>
        </div>
        {!sm && achievementsContent}
        {!sm && editProfileButtonContent}
      </header>

      <div className="mx-auto max-w-7xl">
        <div className="mb-3 flex items-center gap-4">
          {tabs.map(({ label, value }) => {
            return (
              <div
                className={twMerge(
                  'cursor-pointer rounded-full border border-corduroy-200 bg-white px-4 py-3 text-base/none font-light transition-colors hover:bg-primary-40 hover:text-primary-800',
                  selectedSection == value && 'bg-primary-40 text-primary-800',
                )}
                key={value}
                onClick={() => setSelectedSection(value)}
              >
                {label}
              </div>
            );
          })}
        </div>

        <div className="flex flex-col gap-3">
          {selectedSection === 'models' && <UserModelList />}
          {selectedSection === 'nodes' && <UserNodeList />}
        </div>
      </div>
    </div>
  );
};
