import type { UseMutationOptions } from '@tanstack/react-query';

import { useMutation } from '@tanstack/react-query';

import { axiosInstance, setupInterceptors } from 'shared/config/axiosInstance';

import type { AuthResponse, SignUpParams } from './types';

export const useSignUpMutation = (options: UseMutationOptions<AuthResponse, Error, SignUpParams> = {}) => {
  return useMutation({
    mutationFn: async (params: SignUpParams) => {
      const { data } = await axiosInstance.post<AuthResponse>('/auth/signup', params);

      setupInterceptors(data?.access_token);

      return data;
    },
    ...options,
  });
};
