import type { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

export const LayoutWrapper = (props: ComponentProps<'div'>) => {
  return (
    <div {...props} className={twMerge('mx-auto px-2 lg:px-12', props.className)}>
      {props.children}
    </div>
  );
};
