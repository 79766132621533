import type { ComponentProps, ElementType, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName, PolymorphicProps } from 'shared/types';

import { Icon } from 'shared/ui/Icon';

import cornerSrc from '../assets/corner.svg';

export const Card = <E extends ElementType = 'div'>({
  as,
  children,
  className,
  corners,
  ...props
}: PropsWithChildren<{ corners?: boolean } & PolymorphicProps<E, ClassName>>) => {
  const Component = as || 'div';
  return (
    <Component
      {...props}
      className={twMerge('relative rounded-3xl p-6 2xl:p-8', corners && 'p-12', className)}
    >
      {corners && (
        <>
          <img className="absolute left-6 top-6" src={cornerSrc} />
          <img className="absolute right-6 top-6 rotate-90" src={cornerSrc} />
          <img className="absolute bottom-6 right-6 rotate-180" src={cornerSrc} />
          <img className="absolute bottom-6 left-6 -rotate-90" src={cornerSrc} />
        </>
      )}
      {children}
    </Component>
  );
};

export const CardTitle = (props: ComponentProps<'h5'>) => {
  return (
    <h5
      {...props}
      className={twMerge(
        'mb-2 whitespace-nowrap font-klapt text-xl font-semibold uppercase text-corduroy-900 lg:mb-4 lg:text-3xl/none',
        props.className,
      )}
    >
      {props.children}
    </h5>
  );
};

export const CardDescription = (props: ComponentProps<'p'>) => {
  return (
    <p {...props} className={twMerge('text-lg font-light', props.className)}>
      {props.children}
    </p>
  );
};

export const CardLink = (props: ComponentProps<'a'>) => {
  return (
    <a {...props} className={twMerge('mt-8 flex cursor-pointer items-center gap-2', props.className)}>
      <span className="text-base font-light">{props.children}</span>
      <Icon className="size-6 text-primary-1000 lg:size-5 2xl:size-6" name="arrowUpRightLong" />
    </a>
  );
};
