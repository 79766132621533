import { twMerge } from 'tailwind-merge';

import type { TableColumn, TableRowExt } from '../types';

type Props<ColumnKey extends string, Row extends TableRowExt<ColumnKey>> = {
  column: TableColumn<ColumnKey, Row>;
  row: Row;
};

export const Td = <ColumnKey extends string, Row extends TableRowExt<ColumnKey>>({
  column,
  row,
}: Props<ColumnKey, Row>) => {
  return (
    <td
      className={twMerge(
        'border-l border-t border-[#E5E9F0] bg-transparent p-3 text-sm font-normal text-corduroy-900 last:border-r group-last:border-b group-last:first:rounded-bl-lg group-last:last:rounded-br-lg',
        column.tdClassName,
      )}
    >
      {column.renderTd(row)}
    </td>
  );
};
