import type { ComponentProps } from 'react';

import * as SwitchUI from '@radix-ui/react-switch';
import { twMerge } from 'tailwind-merge';

type Props = ComponentProps<typeof SwitchUI.Root>;

export const Switch = ({ className, ...props }: Props) => {
  return (
    <SwitchUI.Root
      {...props}
      className={twMerge(
        'relative h-5 w-9 rounded-full bg-corduroy-200 transition-all duration-200 data-[state=checked]:bg-primary-1000',
        'hover:bg-corduroy-300 focus-visible:bg-corduroy-300',
        'data-[state=checked]:hover:bg-primary-800 data-[state=checked]:focus-visible:bg-primary-800',
        className,
      )}
    >
      <SwitchUI.Thumb className="absolute left-0.5 top-0.5 size-4 rounded-full bg-white transition-all duration-200 data-[state=checked]:translate-x-4" />
    </SwitchUI.Root>
  );
};
