import { useMemo } from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Icon } from 'shared/ui/Icon';

type Props = { file: File; onRemove: () => void } & ClassName;

export const FilePreview = ({ className, file, onRemove }: Props) => {
  const filePreview = useMemo(() => file && URL.createObjectURL(file), [file]);

  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      className={twMerge('grid min-h-fit grid-cols-1 gap-2 overflow-hidden', className)}
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      layout
    >
      <h5 className="font-semibold">Your Image</h5>
      <div className="relative size-32 overflow-hidden rounded-lg">
        <img alt="preview" className="size-full" src={filePreview} />

        <div className="absolute inset-0 z-50 flex items-center justify-center bg-corduroy-900/50">
          <Icon
            className="cursor-pointer rounded-full bg-red-800 p-2 text-corduroy-200 opacity-80 transition-opacity hover:opacity-100"
            name="close"
            onClick={onRemove}
          />
        </div>
      </div>
    </motion.div>
  );
};
