import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { ipfsKeys } from './queryKeys';

//QmUWsSJzxRjYbyKzSFQXWAs64ftndnoME2QWJ2hbcFJu1H
export const useGetFileByCidQuery = (
  cid: string,
  options: UseQueryOptions<
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any;
        type: string;
      }
    | undefined
  > = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      try {
        const { data, headers, ...other } = await axiosInstance.get(`/images/${cid}`, {
          signal,
        });

        const isImage = headers['content-type'].includes('image'); // image
        console.log({ other, type: headers['content-type'] });

        if (isImage) {
          const { data: blobData } = await axiosInstance.get<Blob>(`/images/${cid}`, {
            responseType: 'blob',
            signal,
            timeout: 60 * 1000,
          });

          return { data: URL.createObjectURL(blobData), type: 'image' };
        }

        return { data, type: 'data' };
      } catch (e) {
        console.log(e);
      }
    },
    queryKey: ipfsKeys.file({ cid }),
    ...options,
  });
};
