import { UserModelList } from 'features/UserModelList/UserModelList';
import { AnimateRoute } from 'shared/ui/AnimateRoute';

export const Models = () => {
  return (
    <AnimateRoute className="mx-auto w-full max-w-7xl px-4 py-10">
      <h1 className="mb-6 text-2xl font-light">My Models</h1>

      <UserModelList />
    </AnimateRoute>
  );
};
