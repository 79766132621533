import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Card } from 'shared/ui/Card';

import { ProgressStep } from './ProgressStep';

type Props = {
  blockCount: number;
  code: null | number;
  errorMessage: null | string;
  itemsMap: boolean[][];
} & ClassName;

export const InferenceMap = ({ blockCount, className, code, errorMessage, itemsMap: litItems }: Props) => {
  return (
    <Card className={twMerge('row-span-1 flex flex-col justify-between', className)}>
      <div className="mb-2 flex justify-between">
        <div className="mb-3 text-lg font-normal text-black">Realtime Inference Map</div>
        <div className="text-sm font-semibold text-corduroy-800">100% Uptime</div>
      </div>

      <div className="mb-2 flex flex-col gap-1">
        <div className="flex gap-1">
          {Array.from({ length: blockCount }).map((_, i) => {
            return (
              <div
                className={twMerge(
                  'h-5 w-full rounded-sm bg-corduroy-200 transition-colors lg:h-7 2xl:h-5',
                  litItems[0][i] && !litItems[1][i] && 'bg-primary-800/30',
                  litItems[0][i] && litItems[1][i] && 'bg-primary-800/65',
                )}
                key={i}
              ></div>
            );
          })}
        </div>
        <div className="flex gap-1">
          {Array.from({ length: blockCount }).map((_, i) => {
            return (
              <div
                className={twMerge(
                  'h-5 w-full rounded-sm bg-corduroy-200 transition-colors lg:h-7 2xl:h-5',
                  litItems[1][i] && 'bg-primary-800',
                )}
                key={i}
              ></div>
            );
          })}
        </div>
      </div>

      <ProgressStep code={code} errorMessage={errorMessage} />
    </Card>
  );
};
