import type { IContentLoaderProps } from 'react-content-loader';

import ContentLoaderComponent from 'react-content-loader';

type Props = {
  type?: 'primary' | 'secondary';
} & IContentLoaderProps;

const colors = {
  primary: ['#E1D3FF6f', '#8356E21f'],
  secondary: ['#FAFAFC', '#EEEFF3'],
} as const;

export const ContentLoader = ({ children, type = 'primary', ...props }: Props) => {
  const [backgroundColor, foregroundColor] = colors[type];
  return (
    <ContentLoaderComponent
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      x="0"
      y="0"
      {...props}
    >
      {children}
    </ContentLoaderComponent>
  );
};
