import { Link } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { Button } from 'shared/ui/Button';

import EmptyImage from './ui/empty.svg?react';

type Props = {
  className?: string;
  withButton?: boolean;
};

export const EmptyQueryHistory = ({ className, withButton }: Props) => {
  return (
    <div className={twMerge('flex flex-col items-center', className)}>
      <EmptyImage className="w-48 text-corduroy-400" />
      <div className="py-6 text-base font-light text-corduroy-400">No history available</div>
      {/* TODO: add empty id link */}
      {withButton && (
        <Button as={Link} className="mt-6" size="small" to="/models/663a05612c30d64ab6044c0a">
          Make a query
        </Button>
      )}
    </div>
  );
};
