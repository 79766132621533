import { motion } from 'framer-motion';

import { Label } from 'shared/ui/Label';
import { Slider } from 'shared/ui/Slider';

type TextGenerationQueryParams = {
  maxNewTokens?: number;
  numBeams?: number;
  question: string;
  systemPrompt?: string;
  temperature: number;
  topK?: number;
  topP: number;
};

type GptQueryParams = {
  apiKey?: string;
  frequencyPenalty: number;
  maxTokens?: number;
  presencePenalty: number;
  question: string;
  stopSequence?: string;
  temperature: number;
  topP: number;
};
export type QueryParams = {
  context?: string;
  file?: File;
  frequencyPenalty: number;
  presencePenalty: number;
  private?: boolean;
  question: string;
  temperature: number;
  topP: number;
  useZk?: boolean;
} & GptQueryParams &
  TextGenerationQueryParams;

type Props = {
  onChange: (params: Partial<QueryParams>) => void;
  value: QueryParams;
};

export const QueryHyperparams = ({ onChange, value: queryParams }: Props) => {
  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      className="grid min-h-fit grid-cols-2 gap-2.5 overflow-hidden"
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      layout
    >
      <div>
        <Label>Temperature</Label>
        <Slider
          displayValue
          max={2}
          min={0}
          onValueChange={(values) => onChange({ temperature: values[0] })}
          step={0.01}
          value={[queryParams.temperature]}
        />
      </div>
      <div>
        <Label>Top P</Label>
        <Slider
          displayValue
          max={1}
          min={0}
          onValueChange={(values) => onChange({ topP: values[0] })}
          step={0.01}
          value={[queryParams.topP]}
        />
      </div>
      <div>
        <Label>Frequency penalty</Label>
        <Slider
          displayValue
          max={2}
          min={0}
          onValueChange={(values) => onChange({ frequencyPenalty: values[0] })}
          step={0.01}
          value={[queryParams.frequencyPenalty]}
        />
      </div>
      <div>
        <Label>Presence penalty</Label>
        <Slider
          displayValue
          max={2}
          min={0}
          onValueChange={(values) => onChange({ presencePenalty: values[0] })}
          step={0.01}
          value={[queryParams.presencePenalty]}
        />
      </div>
    </motion.div>
  );
};
