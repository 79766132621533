import { unicodeToChar } from 'shared/helpers/unicodeToChar';

type Props = {
  data: { translation_text: string }[];
};

export const TranslationResponse = ({ data }: Props) => {
  return (
    <div className="flex flex-col overflow-hidden">
      {data.map(({ translation_text }, i) => {
        return (
          <div className="overflow-y-scroll" key={i}>
            {unicodeToChar(translation_text)}
          </div>
        );
      })}
    </div>
  );
};
