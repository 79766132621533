import * as RadixSlider from '@radix-ui/react-slider';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = { displayValue?: boolean } & ClassName & RadixSlider.SliderProps;

export const Slider = ({ className, displayValue, ...props }: Props) => {
  return (
    <div className="flex items-center gap-2">
      <RadixSlider.Root
        className={twMerge('relative flex h-5 w-full touch-none select-none items-center', className)}
        defaultValue={[50]}
        max={100}
        step={1}
        {...props}
      >
        <RadixSlider.Track className="relative h-1 grow rounded-full bg-corduroy-200">
          <RadixSlider.Range className="absolute h-full rounded-full bg-primary-800" />
        </RadixSlider.Track>
        <RadixSlider.Thumb
          aria-label="Volume"
          className="block size-2 cursor-pointer rounded-[10px] bg-primary-1000 shadow-[0_2px_10px] transition-colors hover:bg-primary-900 focus:shadow-[0_0_0_5px] focus:outline-none"
        />
      </RadixSlider.Root>
      {displayValue && (
        <span className="w-9 min-w-9 text-sm font-medium text-corduroy-500">{props.value}</span>
      )}
    </div>
  );
};
