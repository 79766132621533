import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { UserModelListRequestParams } from 'shared/api/models/types';

import emptyImgSrc from 'app/assets/images/empty-illustration.svg';
import { useUser } from 'app/stores/user';
import { ModelCard } from 'features/ModelCard';
import { useGetModelStatistics } from 'shared/api/models/useGetModelStatistics';
import { useGetUserModelListQuery } from 'shared/api/models/useGetUserModelListQuery';
import { Button } from 'shared/ui/Button';
import { Spinner } from 'shared/ui/Spinner';

export const UserModelList = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [filters] = useState<Omit<UserModelListRequestParams, 'userId'>>({ limit: 10, skip: 0 });

  const { data, isPending } = useGetUserModelListQuery(
    { userId: user!._id, ...filters },
    { enabled: !!user?._id },
  );

  const flatModelList = data?.pages.flat() || [];
  const modelIds = flatModelList.map(({ _id }) => _id);
  const { data: stats } = useGetModelStatistics({ ids: modelIds }, { enabled: modelIds.length > 0 });

  return (
    <div className="mt-3 grid grid-cols-1 gap-x-6">
      {isPending ? (
        <div className="flex w-full flex-col items-center justify-between py-14">
          <Spinner className="size-8" />
        </div>
      ) : flatModelList.length > 0 ? (
        <>
          {flatModelList.map((item, i) => {
            const stat = stats?.data[item._id];
            return (
              <ModelCard
                // className="cursor-pointer border border-transparent transition-colors hover:bg-primary-40"
                index={i}
                kernel={item}
                key={item._id}
                onClick={() => navigate(`/models/${item._id}/details`)}
                requestCount={stat?.requestCount}
              />
            );
          })}
        </>
      ) : (
        <div className="flex flex-col items-center justify-center gap-3 py-10 text-center text-corduroy-500">
          <img alt="img" className="size-40" src={emptyImgSrc} />
          <div className="text-base font-normal text-black">No models uploaded yet</div>
          <Button onClick={() => navigate('/upload-model')}>Upload model</Button>
        </div>
      )}
    </div>
  );
};
