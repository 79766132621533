import type { ComponentProps, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

export const Label = ({ children, className, ...props }: PropsWithChildren<ComponentProps<'label'>>) => {
  return (
    <label
      className={twMerge('mb-1.5 flex text-sm/none font-light lg:text-base/none 2xl:text-sm/none', className)}
      {...props}
    >
      {children}
    </label>
  );
};
