import { useNavigate } from 'react-router-dom';

import { Modal } from 'shared/ui/Modal';

import imageSrc from './assets/comingSoon.svg';

type Props = {
  isOpen?: boolean;
  text?: string;
};
export const ComingSoonModal = ({ isOpen, text }: Props) => {
  const navigate = useNavigate();

  return (
    <Modal onOpenChange={() => navigate('/')} open={isOpen ?? true}>
      <Modal.Content className="!max-w-sm">
        <Modal.CloseButton />
        <div className="flex flex-col items-center gap-6 p-5">
          <img className="w-full" src={imageSrc} />

          <div className="flex flex-col items-center justify-center gap-2 text-center">
            <h1 className="text-xl font-normal"> Coming Soon</h1>
            <p className="text-sm font-light text-corduroy-500 ">
              {text ||
                'We’re working on some maintenance updates. Thanks for checking out and we’ll be back soon.'}
            </p>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
