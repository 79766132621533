import { Link } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import logoNameBlackSrc from 'app/assets/images/logo.svg';
import logoNameSrc from 'app/assets/images/logo-white.svg';

type Props = {
  className?: string;
  isBlack?: boolean;
};

export const GalleryLogo = ({ className, isBlack }: Props) => {
  return (
    <Link className={twMerge('flex items-center gap-2.5', className)} to="/">
      <img alt="logo" className="w-7" src={isBlack ? logoNameBlackSrc : logoNameSrc} />
    </Link>
  );
};
