import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useLocalStorage } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useAccount } from 'graz';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { TransactionData } from 'pages/QueryPage/types';
import type { QueryParams } from 'pages/QueryPage/ui/QueryHyperparams';

import { queryClient } from 'app/App';
import { useNotShowAIKernalsMobile } from 'app/stores/configApp';
import { useUser } from 'app/stores/user';
import { BackButton } from 'app/ui/BackButton';
import { LLMChatContent } from 'features/LLMQuery/ui/LLMChatContent';
import { Reviews } from 'features/Reviews';
import { WalletProviderModal } from 'features/WalletProviderModal';
import { ERROR_CODES, defaultQueryParams } from 'pages/QueryPage/config';
import { addLLMMessage } from 'pages/QueryPage/helpers/addLLMMessage';
import { getErrorMessage } from 'pages/QueryPage/helpers/getErrorMessage';
import { getIsApiModel } from 'pages/QueryPage/helpers/getIsApiModel';
import { getModelParams } from 'pages/QueryPage/helpers/getModelParams';
import { useBlockAnimation } from 'pages/QueryPage/hooks/useBlockAnimation';
import { useChatClient } from 'pages/QueryPage/hooks/useChatClient';
import { useChatStatus } from 'pages/QueryPage/hooks/useChatStatus';
import { InferenceMap } from 'pages/QueryPage/ui/InferenceMap';
import { InsufficientBalanceModal } from 'pages/QueryPage/ui/InsufficientBalanceModal';
import { LLMParams } from 'pages/QueryPage/ui/LLMParams';
import { LLMSessionHistory } from 'pages/QueryPage/ui/LLMSessionHistory';
import { ModelNotFound } from 'pages/QueryPage/ui/ModelNotFound';
import { Payments } from 'pages/QueryPage/ui/Payments';
import { ReviewModal } from 'pages/QueryPage/ui/ReviewModal';
import { useErrorSubmitMutation } from 'shared/api/errors/useErrorSubmitMutation';
import { getLlmSessionMessageQueryKey } from 'shared/api/messages/useGetLlmSessionMessagesQuery';
import { useGetModelByIdQuery } from 'shared/api/models/useGetModelByIdQuery';
import { useGetUserReviewByModelQuery } from 'shared/api/reviews/useGetUserReviewByModelQuery';
import { cleanObject } from 'shared/helpers/cleanObject';
import { useMessageEncryption } from 'shared/hooks/useMessageEncryption';
import { useStateX } from 'shared/hooks/useStateX';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { ModelCard } from 'shared/ui/ModelCard';
import { Spinner } from 'shared/ui/Spinner';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';

const BLOCK_COUNT = 24;

type Stream = { destroy: () => void; on: (event: string, fn: unknown) => void } & unknown;

const isProd = import.meta.env.MODE === 'production';

export const LLMQuery = () => {
  const navigate = useNavigate();

  const { id: modelId } = useParams<{ id: string }>();

  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [isReviewsExpanded, setIsReviewsExpanded] = useState(false);
  const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);
  const [isPaymentsExpanded, setIsPaymentsExpanded] = useState(false);
  const [isParamsExpanded, setIsParamsExpanded] = useLocalStorage('llmParamOpen', true);
  const [showRightCurtain, setShowRightCurtain] = useState(false);
  const [showWalletProvider, setShowWalletProvider] = useState(false);

  const [modelReviews, setModelReviews] = useLocalStorage<Record<string, boolean>>('nesaModelReviews', {});

  const { data: model, isLoading, isPending } = useGetModelByIdQuery(modelId!, { enabled: !!modelId });
  const { mutateAsync: logError } = useErrorSubmitMutation();

  const { data: account, walletType } = useAccount();
  const { user } = useUser();
  const { encryptMessage } = useMessageEncryption();

  const rightBlockRef = useRef<HTMLDivElement>(null);

  const { data: reviewData } = useGetUserReviewByModelQuery(
    { modelId: model?._id || '', userId: user?._id || '' },
    { enabled: !!model?._id && !!user?._id },
  );
  const userReview = reviewData?.data;

  const client = useChatClient({
    address: account?.bech32Address,
    modelName: model?.name,
    onClientChange: () => {
      console.log('onClientChange');
      setTxData({
        executionTime: undefined,
        minerRates: [],
        paymentProgress: [],
        sessionId: undefined,
        status: 'idle',
        transactionHash: undefined,
      });

      transactionHashRef.current = '';
      readableStream?.destroy();

      resetAnimation();
    },
    walletType,
  });

  const { txDataCode } = useChatStatus(client);

  const [responseCount, setResponseCount] = useState(0);
  const [queryParams, setQueryParams] = useStateX<QueryParams>(defaultQueryParams);
  const [txData, setTxData] = useStateX<TransactionData>({
    minerRates: [],
    paymentProgress: [],
    status: 'idle',
  });

  const [readableStream, setReadableStream] = useState<Stream>();
  const [txDataErrorMessage, setTxDataErrorMessage] = useState<null | string>(null);
  const [isRequestingSession, setIsRequestingSession] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useStateX({ insufficientBalance: false });

  const transactionHashRef = useRef('');

  const timeoutRef = useRef<NodeJS.Timeout | null | number>(null);
  const txTimeoutRef = useRef<NodeJS.Timeout | null | number>(null);

  const { endAnimationSuccessfully, litItems, resetBlockAnimation, startBlockAnimation } =
    useBlockAnimation();

  useEffect(() => {
    return () => {
      const historyQueryKey = getLlmSessionMessageQueryKey({
        modelName: model?.name,
        sessionId: 'default',
        userId: user?._id || '',
      });

      queryClient.setQueryData(historyQueryKey, {
        pageParams: [],
        pages: [],
      });
    };
  }, [model?.name, user?._id]);

  useNotShowAIKernalsMobile();

  const resetAnimation = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    resetBlockAnimation();
    setTxData({ status: 'idle' });
  };

  const requestSession = async (cb?: () => void) => {
    try {
      if (!account?.bech32Address) {
        toast.error('No account');
        return;
      }

      if (!client) {
        toast.error('No client defined');
        return;
      }

      setIsRequestingSession(true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sessionStream: any = await client.requestSession();

      sessionStream.on('data', (data: { code: number; message: string }) => {
        //  Processing transmission data
        console.log('sessionStream', data);

        setTxDataErrorMessage(null);

        const { code, message } = data;

        if (code === 200) {
          // Streaming data return for TransactionHash

          setTxData({ transactionHash: message || '' });
          transactionHashRef.current = message || '';

          if (cb && typeof cb === 'function') {
            cb();
          }
        } else if (code === 302) {
          setIsRequestingSession(false);
        } else if (ERROR_CODES.includes(code)) {
          console.log('ERROR', { code, message });
          const { text: error, type } = getErrorMessage(message.toLowerCase());
          transactionHashRef.current = '';

          if (type === 'insufficientBalance') {
            setErrorModalOpen({ insufficientBalance: true });
          }

          toast.error(error);
          setTxDataErrorMessage(error);

          isProd &&
            logError({
              error: getErrorMessage(message.toLowerCase()).text,
              modelId: model?._id || '',
              params: {
                ...txData,
                code,
                full: message,
                msg: error,
              },
              userId: user?._id || '',
            });

          setTxData({ transactionHash: '' });
          setIsRequestingSession(false);
        }
      });
      sessionStream.on('end', () => {
        // End of transmission
        setIsRequestingSession(false);
      });
      // });
    } catch (e) {
      console.log('ERROR SESSION', e);
      console.error(e);

      const errorMessage = getErrorMessage(`${e}`).text;

      isProd &&
        logError({
          error: `${e}`,
          modelId: model?._id || '',
          params: { ...txData, msg: txDataErrorMessage },
          userId: user?._id || '',
        });

      toast.error(errorMessage);

      setIsRequestingSession(false);
      resetAnimation();
    }
  };

  const startTxTimeout = (stream: { destroy: () => void } & unknown) => {
    txTimeoutRef.current && clearTimeout(txTimeoutRef.current);

    txTimeoutRef.current = setTimeout(
      () => {
        console.log('timeout');
        resetAnimation();
        setTxData({ status: 'error' });
        resetBlockAnimation();
        logError({
          error: `model timeout`,
          modelId: model?._id || '',
          params: { ...txData },
          userId: user?._id || '',
        });

        stream?.destroy();

        txTimeoutRef.current && clearTimeout(txTimeoutRef.current);
      },
      5 * 60 * 1000,
    );
  };

  const handleLLmError = async (qParams: Partial<QueryParams> = {}, skipMsg?: boolean) => {
    readableStream?.destroy();
    client?.requestCloseHeartbeat();

    await requestSession(() => setTimeout(() => handleSendQuery(qParams, skipMsg), 5000));
  };

  const handleSendQuery = async (qParams: Partial<QueryParams> = {}, skipMsg?: boolean) => {
    const params: QueryParams = { ...queryParams, ...qParams };

    if (params.question && !skipMsg) {
      addLLMMessage({
        messageText: params.question,
        modelName: model?.name || '',
        params,
        role: 'user',
        sessionId: txData.sessionId,
        userId: user?._id || '',
      });
    }

    setTxData({ status: 'progress' });

    resetBlockAnimation();

    const blockProcessingTimeout = setTimeout(() => {
      startBlockAnimation();
    }, 1000);

    const startTime = DateTime.now();

    if (!client) {
      console.error('No client defined!');

      return;
    }

    const modelParams = getModelParams(params, model);

    let chatRequest = {
      messages: [{ content: params.question, context: params.context, role: 'user' }],
      model: model?.name || '',
      model_params: modelParams,
      session_id: JSON.stringify(
        cleanObject({ api_key: params.apiKey, session_id: txData.sessionId || null, user_id: user?._id }),
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    if (params.private) {
      const userId = user?._id || '';
      const { encryptionParams, messageEncrypted: chatRequestEncrypted } = await encryptMessage({
        message: JSON.stringify(chatRequest),
        userId,
      });

      const enc = { params: encryptionParams, request: chatRequestEncrypted, userId };

      chatRequest = {
        ...chatRequest, // Present until backend encryption support + testing
        enc,
        private: true,
      };
    }

    client
      ?.requestChat(chatRequest)
      ?.then((readableStream1) => {
        const readableStream = readableStream1 as Stream;

        let execTime: number | undefined = undefined;

        const isSessionReinitiated = false;

        startTxTimeout(readableStream);
        setReadableStream(readableStream);

        readableStream.on(
          'data',
          (data: {
            code: number;
            content?: string;
            message: string;
            session_id?: string;
            total_payment: { amount: number; denom: string };
          }) => {
            console.log('readable stream', data);

            startTxTimeout(readableStream);

            // Processing transmission data
            if (data.code === 200) {
              if (!execTime) {
                execTime = Math.abs(startTime.diffNow('seconds').seconds);
              }

              if (data.message === '[DONE]' || data.message === '##') {
                return;
              }

              try {
                const parsedSessionField = data.session_id && JSON.parse(data.session_id);

                console.log('parsedSessionField', parsedSessionField);

                setTxData((prev) => ({
                  ...prev,
                  executionTime: execTime,
                  paymentProgress: [
                    ...prev.paymentProgress,
                    { ...data.total_payment, date: DateTime.now().toISO() },
                  ],
                  sessionId: parsedSessionField.session_id,
                }));

                addLLMMessage({
                  messageText: data.message,
                  modelName: model?.name || '',
                  params,
                  role: 'assistant',
                  sessionId: parsedSessionField.session_id ?? 'default',
                  userId: user?._id || '',
                });

                setResponseCount((prev) => prev + 1);
                const reviewStorageKey = `${user?._id}-${model?._id}`;

                if (responseCount + 1 === 2 && !modelReviews[reviewStorageKey] && !userReview) {
                  setModelReviews((prev) => ({ ...prev, [reviewStorageKey]: true }));

                  setTimeout(() => {
                    setIsReviewOpen(true);
                  }, 500);
                }
              } catch (e) {
                console.error('json parse error');
              }
            }

            if (data.code === 205) {
              const parsed = data.message ? JSON.parse(data.message) : {};

              // console.log('ERROR BEF', parsed.msg);
              if (parsed.msg === 'session status mismatch') {
                // setResultArr([]);
                resetBlockAnimation();

                setTimeout(() => {
                  handleLLmError(qParams, true);
                }, 0);

                return;
              }

              const isLLMError = ['LLM Network error', 'LLM Backend error']
                .map((s) => s.toLowerCase())
                .includes((parsed.msg as string).toLowerCase());
              const isAbnormal = (parsed.msg as string).toLowerCase() === 'LLM Abnormal'.toLowerCase();
              const text = isLLMError
                ? 'Network experiencing extreme load, try again later.'
                : isAbnormal
                  ? 'LLM Network error'
                  : parsed.msg;

              if (parsed.msg.includes('balance insufficient')) {
                setErrorModalOpen({ insufficientBalance: true });
                setTxData({ transactionHash: undefined });
              } else {
                text && toast.error(text);
              }

              resetAnimation();
            }
          },
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        readableStream.on('end', async () => {
          txTimeoutRef.current && clearTimeout(txTimeoutRef.current);
          if (isSessionReinitiated) {
            readableStream.destroy();
            return;
          }

          setTxData({ status: 'completed' });
          endAnimationSuccessfully();
          timeoutRef.current && clearTimeout(timeoutRef.current);

          clearTimeout(blockProcessingTimeout);

          readableStream.destroy();
          // End of transmission
        });
      })
      .catch((error) => {
        console.log('ERRROR', error);

        console.error(error);
        setTxData({ status: error });
        resetBlockAnimation();

        isProd &&
          logError({
            error: `${error}`,
            modelId: model?._id || '',
            params: { ...txData, msg: txDataErrorMessage },
            userId: user?._id || '',
          });
      });
  };

  if (!model && !isPending) {
    return <ModelNotFound />;
  }

  return (
    <AnimateRoute className="flex grow flex-col gap-4 px-2 py-4 xs:p-4 lg:overflow-hidden">
      <BackButton onClick={() => navigate('/')}>Back to models</BackButton>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <Card className="relative row-span-1 flex overflow-hidden p-5 @container">
          <ModelCard
            className="my-auto w-full"
            descriptionClassName="line-clamp-3"
            imgClassName="lg:!size-[11rem] lg:!min-w-[11rem] rounded-lg 2xl:size-[9.5rem] 2xl:!min-w-[9.5rem]"
            isLoading={isLoading && !model}
            key={model?._id || 'loading-card'}
            model={model}
            onChipClick={(chipType) => {
              if (chipType === 'reviews') {
                setIsHistoryExpanded(false);
                setIsParamsExpanded(false);
                setIsPaymentsExpanded(false);

                setIsReviewsExpanded(true);
              }
            }}
            size="l"
          />
        </Card>

        <InferenceMap
          blockCount={BLOCK_COUNT}
          code={txDataCode}
          errorMessage={txDataErrorMessage}
          itemsMap={litItems}
        />
      </div>

      <motion.div
        className="relative flex grow flex-col gap-4 overflow-visible lg:flex-row lg:overflow-hidden"
        layoutRoot
      >
        <div className="flex flex-1 flex-col">
          <Card
            className={twMerge(
              'relative flex flex-1 flex-col !p-0 scrollbar-none lg:overflow-y-scroll',
              !txData.transactionHash && 'overflow-hidden',
            )}
          >
            {!model ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <>
                {!txData.transactionHash && (
                  <div className="absolute inset-0 z-10 flex items-center justify-center bg-white/75">
                    {!account?.bech32Address ? (
                      <Button onClick={() => setShowWalletProvider(true)}>Connect wallet</Button>
                    ) : (
                      <Button isLoading={isRequestingSession} onClick={() => requestSession()}>
                        Start Inference
                      </Button>
                    )}
                  </div>
                )}
                <LLMChatContent
                  apiKey={queryParams.apiKey}
                  isTyping={txData.status === 'progress'}
                  model={model}
                  onApiKeyChange={(apiKey) => setQueryParams({ apiKey })}
                  onMessageSend={(message) => {
                    setQueryParams({ question: message });
                    handleSendQuery({ question: message });
                  }}
                  onReviewClick={() => setIsReviewOpen(true)}
                  sessionId={txData.sessionId}
                />
              </>
            )}
          </Card>
        </div>

        <AnimatePresence>
          {showRightCurtain && (
            <motion.div
              animate={{ opacity: 1 }}
              className="absolute right-0 top-[-3px] z-10 h-6 min-h-6 w-1/2 bg-gradient-to-b from-steel-50 to-steel-50/0"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            ></motion.div>
          )}
        </AnimatePresence>

        <motion.div
          className="relative flex flex-1 flex-col gap-4 lg:overflow-y-auto"
          onScroll={(e) => {
            if (e.currentTarget.scrollTop > 0 && !showRightCurtain) {
              setShowRightCurtain(true);
            }

            if (e.currentTarget.scrollTop === 0 && showRightCurtain) {
              setShowRightCurtain(false);
            }
          }}
          ref={rightBlockRef}
        >
          <StretchedSkeleton enable={!model} rx={14} ry={14} />
          {model && (
            <LLMParams
              isApiModel={getIsApiModel(model)}
              isExpanded={isParamsExpanded}
              model={model}
              onChange={setQueryParams}
              onExpandedChange={setIsParamsExpanded}
              queryParams={queryParams}
            />
          )}
          <AnimatePresence>
            {txData.status === 'completed' && (
              <Card animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <Payments
                  executionTime={txData.executionTime}
                  isOpen={isPaymentsExpanded}
                  onOpenChange={setIsPaymentsExpanded}
                  pricePerToken={0.001}
                  rates={txData.minerRates}
                  totalPayment={txData.paymentProgress[txData.paymentProgress.length - 1]?.amount || 0}
                  transactionHash={txData.transactionHash}
                />
              </Card>
            )}
          </AnimatePresence>
          {model && (
            <LLMSessionHistory
              activeSessionId={txData.sessionId}
              isExpanded={isHistoryExpanded}
              modelId={model._id}
              onClick={(item) => {
                setTxData({ sessionId: item._id === txData.sessionId ? undefined : item._id });
              }}
              onExpandedChange={setIsHistoryExpanded}
              userId={user?._id || ''}
            />
          )}

          {model && (
            <Reviews
              isExpanded={isReviewsExpanded}
              model={model}
              onExpandedChange={(isExpanded) => {
                if (isExpanded) {
                  setIsHistoryExpanded(false);
                  setIsParamsExpanded(false);
                  setIsPaymentsExpanded(false);
                }

                setIsReviewsExpanded(isExpanded);
              }}
            />
          )}
        </motion.div>
      </motion.div>

      <InsufficientBalanceModal
        isOpen={errorModalOpen.insufficientBalance}
        onOpenChange={(isOpen) => setErrorModalOpen({ insufficientBalance: isOpen })}
      />

      <WalletProviderModal onOpenChange={setShowWalletProvider} open={showWalletProvider} />

      {model && (
        <ReviewModal
          isOpen={isReviewOpen}
          model={model}
          onOpenChange={setIsReviewOpen}
          sessionId={txData.sessionId}
        />
      )}
    </AnimateRoute>
  );
};
