import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { ResetPasswordParams } from './types';

export const useResetPasswordMutation = (
  options: UseMutationOptions<null, Error, ResetPasswordParams> = {},
) => {
  return useMutation({
    mutationFn: async ({ token, userId, ...params }: ResetPasswordParams) => {
      const { data } = await axiosInstance.post<null>(
        `/auth/reset/password/users/${userId}/${token}`,
        params,
      );

      return data;
    },
    ...options,
  });
};
