import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Button } from 'shared/ui/Button';
import { Chip } from 'shared/ui/Chip';
import { Icon } from 'shared/ui/Icon';

type Props = {
  onReviewClick: () => void;
} & ClassName;

export const ReviewText = ({ className, onReviewClick }: Props) => {
  return (
    <Button
      className={twMerge(
        'flex items-center gap-1 border border-corduroy-200 bg-corduroy-50 pl-4 pr-2 leading-none',
        className,
      )}
      color="secondary"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onReviewClick();
      }}
      variant="filled-light"
    >
      <span className="leading-none">Write a review </span>
      <Chip className="flex items-center gap-0 truncate">
        <Icon className="-mt-px mr-1 size-3 p-0 text-primary-800" name="starSolid" />
        <span className="leading-none">NES Rewards</span>
      </Chip>
    </Button>

    // <div
    //   className={twMerge(
    //     'flex cursor-pointer flex-col items-end justify-end gap-1 text-right text-base/none text-primary-800 transition-colors hover:text-primary-1000',
    //     className,
    //   )}
    //   onClick={onReviewClick}
    // >
    //   <span className="flex items-center">
    //     <Icon className="mr-1 size-5 p-0 text-yellow-500" name="starSolid" /> NES Rewards:
    //   </span>{' '}
    //   Submit your review of this model
    // </div>
  );
};
