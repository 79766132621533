import type { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

export const GridContent = (props: ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={twMerge('grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3', props.className)}
    >
      {props.children}
    </div>
  );
};
