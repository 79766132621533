import type { ReactNode } from 'react';

import { twJoin } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';
import { Tabs } from 'shared/ui/Tabs';

type LeaderBoardTabsProps = {
  children: ReactNode;
};

const tabs = [
  { disabled: false, icon: 'ranking', title: 'Leaderboard', value: 'leader-board' },
  { disabled: true, icon: 'questionCircle', title: 'FAQ', value: 'faq' },
  { disabled: true, icon: 'search', title: 'Explorer', value: 'explorer' },
] as const;

export function LeaderBoardTabs({ children }: LeaderBoardTabsProps) {
  return (
    <Tabs.Root
      defaultValue="leader-board"
      tabs={
        <Tabs.List>
          {tabs.map((tab) => (
            <Tabs.Trigger
              className="min-w-40 justify-center"
              disabled={tab.disabled}
              key={tab.value}
              value={tab.value}
            >
              <Icon
                className={twJoin('-ml-1 size-6', tab.disabled ? 'text-corduroy-500' : 'text-primary-800')}
                name={tab.icon}
              />
              <span>{tab.title}</span>
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      }
    >
      {children}
    </Tabs.Root>
  );
}
