import { useRef, useState } from 'react';

import { useEvent } from 'shared/hooks/useEvent';

const BLOCK_COUNT = 24;

const initialBlockState = [
  Array.from({ length: BLOCK_COUNT }).fill(false) as boolean[],
  Array.from({ length: BLOCK_COUNT }).fill(false) as boolean[],
];
const filledBlockState = [
  Array.from({ length: BLOCK_COUNT }).fill(true) as boolean[],
  Array.from({ length: BLOCK_COUNT }).fill(true) as boolean[],
];

export const useBlockAnimation = () => {
  const [litItems, setLitItems] = useState(initialBlockState);

  const blockAnimIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const startBlockAnimation = useEvent(() => {
    const isStarted = litItems[0].some((val) => val);

    if (isStarted) return;
    const firstBlockToLit = Math.floor(Math.random() * 23);

    setLitItems((currentState) => [
      [...currentState[0].map((isLit, i) => !isLit && i === firstBlockToLit)],
      currentState[1],
    ]);

    blockAnimIntervalRef.current = setInterval(() => {
      const randomizedBlockToLit = Math.floor(Math.random() * 23);

      setLitItems((currentState) => {
        const isFirstRowLitUp = currentState[0].every(Boolean);

        if (!isFirstRowLitUp) {
          const blockToLit = currentState[0][randomizedBlockToLit]
            ? currentState[0].findIndex((val) => !val)
            : randomizedBlockToLit;

          return [[...currentState[0].map((isLit, i) => isLit || i === blockToLit)], currentState[1]];
        } else {
          const blockToLit = currentState[1][randomizedBlockToLit]
            ? currentState[1].findIndex((val) => !val)
            : randomizedBlockToLit;

          return [currentState[0], [...currentState[1].map((isLit, i) => isLit || i === blockToLit)]];
        }
      });
    }, 200);
  });

  const stopBlockAnimation = useEvent(() => {
    blockAnimIntervalRef.current && clearInterval(blockAnimIntervalRef.current);
  });

  const resetBlockAnimation = useEvent(() => {
    stopBlockAnimation();
    setLitItems(initialBlockState);
  });

  const endAnimationSuccessfully = useEvent(() => {
    stopBlockAnimation();
    setLitItems(filledBlockState);
  });

  return { endAnimationSuccessfully, litItems, resetBlockAnimation, startBlockAnimation, stopBlockAnimation };
};
