import { twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';
import type { ClassName } from 'shared/types';

import LogoWhiteIcon from 'app/assets/images/logo-white.svg?react';
// import { Logo } from 'app/ui/Logo';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';

type Props = { index?: number; kernel: Model; onClick?: () => void; requestCount?: number } & ClassName;

export const ModelCard = ({ className, index: i, kernel, onClick, requestCount }: Props) => {
  const { _id, description, image } = kernel;

  return (
    <Card
      className={twMerge(
        'p-0 xs:p-0 ',
        !!onClick && 'cursor-pointer border border-transparent transition-colors hover:bg-primary-40',
        className,
      )}
      onClick={onClick}
    >
      <header className="flex flex-col justify-between gap-3 border-b border-corduroy-200 p-3 sm:flex-row sm:p-4">
        <div className="flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-3">
          <div className="text-lg font-medium text-corduroy-900">Model {i !== undefined && `#${i + 1}`}</div>
          <span className="hidden text-corduroy-200 sm:inline-block">| </span>
          <div className="text-sm font-light text-corduroy-500">{_id}</div>
        </div>
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-1 rounded-full border border-corduroy-200 bg-white px-2 py-1 text-sm/none text-corduroy-800">
            <Icon className="size-4" name="globeDigital" />
            <span className="text-sm/none">Public</span>
          </div>
        </div>
      </header>

      <div className="flex flex-col gap-4 p-3 sm:flex-row">
        <div className="flex flex-col gap-6 sm:flex-row sm:gap-4">
          <div className="flex justify-center">
            <img
              alt="kernel"
              className="size-40 overflow-hidden rounded-xl sm:size-40 sm:min-w-40"
              src={image}
            />
          </div>

          <div className="flex flex-col gap-2 sm:gap-4 sm:border-b-0 sm:border-r sm:border-corduroy-200 sm:pr-4 ">
            <p className="text-base font-light text-corduroy-600">{description}</p>

            <div>
              <div className="flex w-fit items-center rounded-full border border-corduroy-200 px-4">
                <div className="flex items-center gap-1 border-r border-corduroy-200 py-2 pr-2">
                  <Icon className="size-6 text-primary-800" name="fileEdit" />
                  <span className="text-lg font-bold md:text-xl">
                    {requestCount?.toLocaleString() || '-'}
                  </span>
                </div>

                <div className="pl-2 text-sm font-normal md:text-base">Request served</div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex min-w-fit flex-row items-center gap-2 sm:flex-col sm:justify-center sm:px-4">
          <div className="flex size-9 items-center justify-center rounded-full bg-primary-800 p-1.5">
            <LogoWhiteIcon />
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="text-xl font-bold sm:text-center">{0} NES</div>
            <div className="text-sm/none font-light text-corduroy-500 sm:text-center">
              Earned by node to date
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
