import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = {
  image?: string;
  isUser?: boolean;
  text: string;
  title?: string;
} & ClassName;

export const Message = ({ className, image, isUser, text }: Props) => {
  return (
    <div className={twMerge('flex gap-3', isUser && 'flex-row-reverse', className)}>
      {!isUser && (
        <div
          className="mt-auto flex size-10 min-w-10 items-center justify-center rounded-full bg-primary-200 bg-cover bg-center bg-no-repeat text-lg text-primary-800"
          style={!isUser && image ? { backgroundImage: `url(${image})` } : {}}
        ></div>
      )}
      {/* <div className={twMerge('flex flex-col gap-2')}> */}
      <p
        className={twMerge(
          'max-w-[60%] whitespace-pre-line rounded-2xl rounded-bl-none bg-corduroy-100 px-4 py-2 text-sm/5 text-corduroy-700 lg:text-base/5 2xl:text-sm/5',
          isUser && 'rounded-bl-2xl rounded-br-none bg-primary-100 text-end',
        )}
      >
        {text}
      </p>
      {/* </div> */}
    </div>
  );
};
