type Options = {
  decimal?: string;
  decimals?: number;
  plus?: boolean;
  separator?: string;
};

export const formatNumber = (
  num: number | string,
  { decimal = '.', decimals = 2, plus = false, separator = ',' }: Options = {},
): string => {
  const n = Number(num);
  const neg = n < 0 ? '-' : plus ? '+' : '';

  const result = Math.abs(n).toFixed(decimals);
  const [integer, fractional] = result.split('.');

  let x1 = integer;
  const x2 = fractional ? decimal + fractional : '';
  let x3 = '';
  let j = 0;
  const factor = 3;
  for (let i = 0, len = x1.length; i < len; ++i) {
    if (i !== 0 && j % factor === 0) {
      x3 = separator + x3;
    }
    j++;
    x3 = x1[len - i - 1] + x3;
  }
  x1 = x3;

  return neg + x1 + x2;
};
