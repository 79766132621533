import { Link, useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

export const MyModel = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  if (!user) return null;

  return (
    <div className="flex h-10 items-center">
      <div
        className={twMerge(
          'flex h-full w-fit cursor-pointer items-center justify-center rounded-lg bg-clay-20 py-0.5 pl-2.5 pr-0.5 transition-colors hover:bg-primary-40 2xl:min-w-[19rem]',
        )}
        onClick={() => navigate('/models')}
      >
        <div className="flex size-fit items-center gap-1">
          <div className="text-[0.8125rem] font-semibold">My AI Node</div>
          <div className="size-1 self-start rounded bg-green-600"> </div>
        </div>

        <div className="ml-1.5">
          <span className="text-[0.625rem] font-semibold text-gray-400">ID:&nbsp;</span>
          <span className="text-[0.625rem] font-normal text-gray-400">32560317486</span>
        </div>

        <div className="ml-3 mr-px flex h-8 items-center rounded-l-md bg-white p-2">
          <Icon className="size-3 text-gray-500" name="box" />
          <span className="ml-1 text-xs font-semibold">234</span>
        </div>

        <div className="flex h-8 items-center rounded-r-md bg-white p-2">
          <Icon className="size-3 text-primary-800" name="crown" />
          <span className="ml-1 text-xs font-semibold">98</span>
        </div>
      </div>

      <Button
        as={Link}
        className="ml-3 pl-3 pr-4"
        // size="extra-small"
        to="/upload-model"
      >
        <Icon className="-mt-px size-3 text-white" name="plus" />
        Upload Model
      </Button>
    </div>
  );
};
