import { useState } from 'react';
import { toast } from 'react-toastify';

import { useAccount } from 'graz';

import emptySrc from 'app/assets/images/empty-illustration.svg';
import emptyWalletSrc from 'app/assets/images/empty-wallet.svg';
import { WalletProviderModal } from 'features/WalletProviderModal';
import { Button } from 'shared/ui/Button';

export const UserNodeList = () => {
  const { data: account, isLoading } = useAccount();
  const address = !!account?.bech32Address;

  const [showWalletProvider, setShowWalletProvider] = useState(false);

  const nodes = [];

  return (
    <div className="flex flex-col">
      {!address && !isLoading ? (
        <div className="flex flex-col items-center justify-center gap-1.5 py-12">
          <img className="size-40" src={emptyWalletSrc} />

          <h1 className="text-center text-lg">Wallet is not connected</h1>
          <p className="mb-4 text-base font-light text-corduroy-600">
            You need to connect your wallet to run a nesa node
          </p>

          <Button onClick={() => setShowWalletProvider(true)}>Connect Wallet</Button>
        </div>
      ) : nodes.length > 0 ? (
        <div></div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-1.5 py-12">
          <img className="size-40" src={emptySrc} />

          <h1 className="text-lg">{`You don't have any nodes`}</h1>

          <Button className="mt-4" onClick={() => toast.info('Coming soon')}>
            Run a node
          </Button>
        </div>
      )}

      <WalletProviderModal onOpenChange={setShowWalletProvider} open={showWalletProvider} />
    </div>
  );
};
