import { useEffect, useState } from 'react';

import { ChatClient } from 'nesa.js';

import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { useEvent } from 'shared/hooks/useEvent';

type Props = {
  address: string | undefined;
  modelName: string | undefined;
  onClientChange: () => void;
  walletType: string | undefined;
};

export const useChatClient = ({ address, modelName, onClientChange, walletType }: Props) => {
  const [client, setClient] = useState<ChatClient>();

  const handleClientChange = useEvent(onClientChange);

  useEffect(() => {
    if (!modelName || !walletType || !address) {
      return;
    }

    console.log('chat client creation', { address, handleClientChange, modelName, walletType });

    const chatUtils = new ChatClient({
      chainInfo: nesaTestnet,
      modelName: modelName,
      walletName: walletType === 'metamask_snap_leap' ? 'npm:@leapwallet/metamask-cosmos-snap' : 'keplr',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);

    handleClientChange();

    setClient(chatUtils);

    return () => {
      console.log('requestCloseHeartbeat UNMOUNT');

      chatUtils?.requestCloseHeartbeat();
      setClient(undefined);
      handleClientChange();
    };
  }, [modelName, walletType, address, handleClientChange]);

  // useEffect(() => {
  //   return () => {
  //     console.log('requestCloseHeartbeat UNMOUNT');

  //     client?.requestCloseHeartbeat();
  //   };
  // }, [client]);

  return client;
};
