import { JsonEditor } from 'shared/ui/JsonEditor';

type Props = {
  context: string | undefined;
  data: {
    answer: string;
    end: number;
    score: number;
    start: number;
  };
};

export const QAResponse = ({ context, data }: Props) => {
  return (
    <div className="flex flex-col gap-5">
      {context && (
        <div className="flex flex-col gap-2">
          <div className="rounded-md bg-corduroy-100/50 px-4 py-3">
            {context.slice(0, data.start)}{' '}
            <span className="rounded-sm bg-primary-100 p-0.5 text-primary-1000">
              {context.slice(data.start, data.end)}
            </span>
            {context.slice(data.end)}
          </div>
        </div>
      )}
      <div className="flex h-full flex-col gap-2">
        <JsonEditor className="h-full [&>div]:p-0" readOnly value={data} />
      </div>
    </div>
  );
};
