import type { ReactNode } from 'react';

import { motion } from 'framer-motion';

type Props = {
  children: ReactNode;
  className?: string;
};

export const AnimateRoute = ({ children, className }: Props) => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      className={className}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      {children}
    </motion.div>
  );
};
