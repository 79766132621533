import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';

import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';

import { FREE_MSG_LIMIT } from '../../../features/LLMQuery/ui/LLMChatContent';

type Props = {
  isOpen: boolean;
  model: Model;
  onKeySubmit: (key: string) => void;
  onOpenChange: (isOpen: boolean) => void;
  sentMessagesCount?: number;
};

export const LimitReachedModal = ({
  isOpen,
  model,
  onKeySubmit,
  onOpenChange,
  sentMessagesCount = FREE_MSG_LIMIT,
}: Props) => {
  const [apiKey, setApiKey] = useState('');
  const isLimitReached = sentMessagesCount >= FREE_MSG_LIMIT;
  const isOpenAi = model.name.includes('gpt');
  const isClaude = model.name.includes('claude');
  const apiCompanyName = model.name.includes('gpt') ? 'OpenAI' : 'Anthropic';

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content>
        <Modal.CloseButton />

        <div className="flex flex-col p-2">
          <div className="mb-4 flex flex-col gap-1">
            <div className="flex items-center justify-between">
              <div className="text-base/none font-medium lg:text-lg/none">
                Daily {apiCompanyName} Requests
              </div>
              <div
                className={twMerge(
                  'text-2xl font-semibold text-pink-500',
                  sentMessagesCount < 4 && 'text-primary-800',
                  sentMessagesCount >= 4 && sentMessagesCount < 5 && 'text-yellow-500',
                )}
              >
                {isLimitReached ? 0 : FREE_MSG_LIMIT - sentMessagesCount}
              </div>
            </div>

            <div className="flex w-full flex-col rounded-[7px] border border-corduroy-200 p-1">
              <div
                className={twMerge(
                  'flex h-[10px] w-full rounded-[4px] bg-pink-500',
                  sentMessagesCount < 4 && 'bg-primary-800',
                  sentMessagesCount >= 4 && sentMessagesCount < 5 && 'bg-yellow-500',
                )}
                style={{
                  width: !isLimitReached
                    ? `${sentMessagesCount > 0 ? (sentMessagesCount * 100) / FREE_MSG_LIMIT : 3}%`
                    : '100%',
                }}
              ></div>
            </div>

            {isLimitReached && <div className="text-base text-pink-500">Limit Reached</div>}
          </div>

          <div className="mb-2 text-xl font-medium lg:text-3xl 2xl:text-2xl">
            Add Your {apiCompanyName} API Key
          </div>
          <div className="mb-6 text-sm/tight text-corduroy-600 lg:text-lg/tight 2xl:text-base/tight">
            This model is owned by {apiCompanyName}. While Nesa offers free daily usage, you must enter an API
            key for continued usage.
            {/* Please refer to this link for assistance:  */}
          </div>

          <Input
            className="mb-4 w-full"
            label={`${apiCompanyName} API Key`}
            onChange={(e) => setApiKey(e.target.value)}
            placeholder="e.g., sk-xxxxxxx"
            value={apiKey}
          />

          <Button className="mb-6 max-w-fit" onClick={() => onKeySubmit(apiKey)}>
            Submit
          </Button>

          <div className="flex flex-col">
            {isOpenAi && (
              <div
                className="cursor-pointer text-[#0784C3] transition-all hover:brightness-75"
                onClick={() =>
                  window.open('https://platform.openai.com/docs/quickstart/account-setup', '_blank')
                }
              >
                How to get a OpenAI API Key?
              </div>
            )}
            {isClaude && (
              <div
                className="cursor-pointer text-[#0784C3] transition-all hover:brightness-75"
                onClick={() =>
                  window.open('https://docs.anthropic.com/en/api/getting-started#accessing-the-api', '_blank')
                }
              >
                How to get a Anthropic API Key?
              </div>
            )}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
