import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { Model, ModelListRequestParams, ModelListResponse } from './types';

import { modelsKeys } from './queryKeys';

export const useModelListQuery = (params: ModelListRequestParams, options: { enabled?: boolean } = {}) => {
  const limitItems = params.limit || 40;

  return useInfiniteQuery({
    getNextPageParam: (last: Model[], all: Model[][]) => (last.length === limitItems ? all.length : null),
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosInstance.get<ModelListResponse>('models', {
        params: {
          ...params,
          limit: limitItems,
          skip: pageParam * limitItems,
          sort: params.sort,
        },
        signal,
      });

      return res.data.data;
    },
    queryKey: modelsKeys.list({ ...params, limitItems }),
    ...(options || {}),
  });
};
