import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AxiosError } from 'axios';
import { twMerge } from 'tailwind-merge';

import { BackButton } from 'app/ui/BackButton';
import { useGenerateModelImageMutation } from 'shared/api/models/useGenerateModelImageMutation';
import { useGetModelTypesQuery } from 'shared/api/models/useGetModelTypesQuery';
import { useUploadModelMutation } from 'shared/api/models/useUploadModelMutation';
import { useStateX } from 'shared/hooks/useStateX';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';
import { RadioGroup } from 'shared/ui/Radio';
import { Select } from 'shared/ui/Select';
import { TextArea } from 'shared/ui/TextArea';

import globeSrc from './assets/globe.svg';
import imageSrc from './assets/image.svg';
import lockSrc from './assets/lock.svg';
import magicIcon from './assets/magicoon.svg';

const MODEL_MOCKS = [
  {
    description: 'Anyone on Nesa can see this model. Only you or members of your organization can commit.',
    iconSrc: globeSrc,
    id: 'public',
    label: 'Public',
    value: 'public',
  },
  {
    description: 'Only you or members of your organization can see and commit to this model.',
    iconSrc: lockSrc,
    id: 'private',
    label: 'Private',
    value: 'private',
  },
];

const DEVICE_MOCKS = [
  { id: 'gpu-worker', label: 'GPU Worker', value: 'gpu-worker' },
  { id: 'cpu-worker', label: 'CPU Worker', value: 'cpu-worker' },
];

export const UploadModel = () => {
  const navigate = useNavigate();

  const [state, setState] = useStateX<{
    description: string;
    deviceType: string;
    generatedImage?: string;
    modelAccessType: 'private' | 'public';
    modelName: string;
    modelType?: string;
  }>({
    description: '',
    deviceType: DEVICE_MOCKS[0].value,
    generatedImage: '',
    modelAccessType: 'public',
    modelName: '',
  });
  const { deviceType, modelAccessType, modelType } = state;

  const { data: modelTypes, isLoading: isLoadingModelTypes } = useGetModelTypesQuery({
    allowed_custom: true,
  });

  const { isPending: isGenerating, mutateAsync: generateImage } = useGenerateModelImageMutation();

  const { isPending: isUploading, mutateAsync } = useUploadModelMutation();

  const handleGenerateImage = async () => {
    try {
      if (!state.modelName || !state.description) {
        toast.error('Model name and description are required in order to generate an image');

        return;
      }

      const { link } = await generateImage({ description: state.description, name: state.modelName });

      setState({ generatedImage: link });
    } catch (e) {
      toast.error('Something went wrong during image generation');
    }
  };

  const handleUploadModel = async () => {
    try {
      if (!state.modelName || !state.modelType) {
        toast.error('Not all required fields are filled');
        return;
      }
      const { model_id } = await mutateAsync({
        description: state.description,
        model_name: state.modelName,
        model_type: state.modelType,
        private: state.modelAccessType === 'private',
      });

      if (!model_id) {
        toast.error('No model available');
        return;
      }

      navigate(`/models/${model_id}`);
    } catch (e) {
      console.log('ERROR', e);
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toast.error(axiosError || 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <AnimateRoute className="mx-auto w-full max-w-4xl p-4 pb-10">
      <BackButton className="mb-3" onClick={() => navigate('/')}>
        Back to home
      </BackButton>
      <h2 className="mb-4 text-2xl  font-normal lg:text-3xl">Upload model</h2>

      <div className="mb-4 flex w-full flex-col gap-2">
        <Card>
          <div className="mb-4 text-lg font-normal">Your model’s image</div>
          <div className="flex w-full gap-4">
            <div
              className="flex size-40 min-w-40 max-w-40 flex-col items-center justify-center overflow-hidden rounded-2xl bg-corduroy-100 p-4 text-center text-xs text-corduroy-400"
              style={
                state.generatedImage
                  ? {
                      backgroundImage: `url(${state.generatedImage})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }
                  : {}
              }
            >
              <img className="mb-2 size-12" src={imageSrc} />
              {!state.generatedImage && (
                <div className="text-xs font-light text-corduroy-500">
                  Image to be <br /> generated by AI
                </div>
              )}
            </div>

            <div className="w-full">
              <TextArea
                disabled={isUploading || isGenerating}
                label="Enter your Image’s description text"
                placeholder="Ex. Spaceship flying through fire"
                rows={2}
              />

              <Button
                className={twMerge(
                  'border-none bg-gradient-to-r from-primary-900 to-[#FF66CB] hover:brightness-95',
                )}
                isLoading={isGenerating}
                onClick={handleGenerateImage}
              >
                <img className="mr-2 size-4" src={magicIcon} /> Generate
              </Button>
            </div>
          </div>
        </Card>

        <div className="flex gap-4">
          <Card className="flex flex-col">
            <TextArea
              disabled={isUploading}
              label="Your model's hardware specifications"
              placeholder="Ex. H100, NVIDIA, Apple M1 Pro"
            />

            <div className="mt-auto">
              <Label className="mb-3 block">Device Type</Label>

              <RadioGroup className="grid grid-cols-2 gap-2" disabled={isUploading} value={deviceType}>
                {DEVICE_MOCKS.map(({ id, label, value }) => (
                  <RadioGroup.Item
                    className={twMerge(
                      'rounded-full border border-corduroy-200 p-3 transition-colors',
                      value === deviceType && 'border-primary-800 bg-primary-50 text-primary-800',
                    )}
                    disabled={isUploading}
                    id={id}
                    indicatorClassName={twMerge(value === deviceType && 'border-none')}
                    itemClassName={twMerge(value === deviceType && 'border-none')}
                    key={id}
                    onClick={() => setState({ deviceType: value })}
                    value={value}
                  >
                    {label}
                  </RadioGroup.Item>
                ))}
              </RadioGroup>
            </div>
          </Card>
          <Card className="pb-0">
            <TextArea
              className="h-full"
              disabled={isUploading}
              label="Your model's description"
              onChange={(e) => setState({ description: e.target.value })}
              placeholder="Ex. This model generate images"
              rows={8}
              value={state.description}
            />
          </Card>
        </div>

        <Card className="grid grid-cols-2 gap-3">
          <Input
            className="col-span-2"
            disabled={isUploading}
            label="Huggingface Repository Name"
            onChange={(e) => setState({ modelName: e.target.value })}
            placeholder="ie. meta-llama/Llama-2-7b"
            value={state.modelName}
          />
          {/* <Input label="Model Name" placeholder="Name your model" /> */}
          <Input disabled={isUploading} label="License" placeholder="License name or link" />
          <Input disabled label="Pipeline Tag" placeholder="Auto detected" />

          <Select
            disabled={isLoadingModelTypes || isUploading}
            label="Model type"
            onValueChange={(val) => {
              setState({ modelType: val });
            }}
            placeholder="Add tag to describe type of model"
            value={modelType}
          >
            <Select.Content>
              {modelTypes?.types.map(({ model_type }) => {
                return (
                  <Select.Item key={model_type} value={model_type}>
                    {model_type}
                  </Select.Item>
                );
              })}
            </Select.Content>
          </Select>
        </Card>

        <Card>
          <Label className="mb-3 !text-lg font-normal">Model Type</Label>

          <div className="grid grid-cols-2 gap-3">
            {MODEL_MOCKS.map(({ description, iconSrc, id, label, value }) => {
              return (
                <div
                  className={twMerge(
                    'flex cursor-pointer flex-col rounded-2xl border border-corduroy-200 p-4 transition-colors hover:border-primary-1000',
                    value === modelAccessType && 'border-primary-900 bg-primary-50',
                    isUploading && 'pointer-events-none opacity-60',
                  )}
                  key={id}
                  onClick={() => setState({ modelAccessType: value as 'private' | 'public' })}
                >
                  <div className="mb-2 flex size-10 items-center justify-center rounded-2xl bg-primary-200/70">
                    <img className="size-6" src={iconSrc} />
                  </div>
                  <div className="mb-1 font-medium" id={id}>
                    {label}
                  </div>
                  <p className="text-sm/4 font-light text-corduroy-600">{description}</p>
                </div>
              );
            })}
          </div>
        </Card>
      </div>
      <div className="flex gap-4">
        <Button isLoading={isUploading} onClick={handleUploadModel}>
          Upload Model
        </Button>
        <Button color="secondary" onClick={() => navigate('/')} variant="filled-light">
          Cancel
        </Button>
      </div>
    </AnimateRoute>
  );
};
