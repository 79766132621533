import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { UploadModelParams, UploadModelResponse } from './types';

export const useUploadModelMutation = (
  options: UseMutationOptions<UploadModelResponse, Error, UploadModelParams> = {},
) => {
  return useMutation({
    mutationFn: async (params: UploadModelParams) => {
      const { data } = await axiosInstance.post<UploadModelResponse>(`/models/upload`, params, {
        timeout: 70000,
      });

      return data;
    },
    ...options,
  });
};
