import { type FormEventHandler, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Turnstile } from '@marsidev/react-turnstile';
import { AxiosError } from 'axios';
import isEmail from 'validator/lib/isEmail';

import { useRequestResetPasswordMutation } from 'shared/api/user/useRequestResetPasswordMutation';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';

type FormValues = {
  email: string;
};

export const PasswordRecovery = () => {
  const { state } = useLocation();

  const [captchaToken, setCaptchaToken] = useState('');

  const {
    formState: { errors, isValid },
    getValues,
    register,
  } = useForm<FormValues>({
    defaultValues: { email: state?.email || '' },
    mode: 'onChange',
  });

  const { isPending, mutateAsync } = useRequestResetPasswordMutation();

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      e.preventDefault();

      if (!captchaToken) {
        toast.error('You have to complete captcha before submitting a request');

        return;
      }

      const { email } = getValues();

      await mutateAsync({ captchaToken, email: email.trim() });

      toast.success('Email has been sent. Please check your inbox.');
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toast.error(axiosError || 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <AnimateRoute className="flex size-full min-h-dvh flex-col items-center justify-center">
      <form className="mx-auto flex w-full max-w-96 flex-1 flex-col justify-center gap-2" onSubmit={onSubmit}>
        <h2 className="mb-4 text-lg font-semibold">Recover your password</h2>

        <Input
          {...register('email', { validate: (val) => (!isEmail(val) ? 'Invalid email' : true) })}
          disabled={isPending}
          error={errors.email?.message}
          errorSpacing
          placeholder="Your email"
        />

        <Button disabled={!isValid || !captchaToken} isLoading={isPending} type="submit">
          Send recovery email
        </Button>

        <Turnstile
          onSuccess={setCaptchaToken}
          options={{ appearance: 'interaction-only' }}
          siteKey={import.meta.env.VITE_CLOUDFLARE_SITE_KEY}
          style={{ maxWidth: '100%' }}
        />
      </form>
    </AnimateRoute>
  );
};
