import type { ModelPreviewListRequestParams } from 'shared/api/models/types';

import type { FilterState } from '../ui/GalleryFilters/GalleryFilters';

export const getFiltersParams = (filters: FilterState): ModelPreviewListRequestParams => {
  return {
    direction: filters.sort?.endsWith('-highest')
      ? 'desc'
      : filters.sort?.endsWith('-lowest')
        ? 'asc'
        : undefined,
    sort: filters.sort?.startsWith('price')
      ? 'price'
      : filters.sort?.startsWith('likes')
        ? 'likes'
        : filters.sort?.startsWith('downloads')
          ? 'downloads'
          : filters.sort?.startsWith('ranking')
            ? 'ranking'
            : filters.sort?.startsWith('name')
              ? 'name'
              : null,
  };
};
