import type { TableColumn } from 'shared/ui/Table';

import { ContentLoader } from 'shared/ui/ContentLoader';
import { Table } from 'shared/ui/Table';

import { columns } from '../config';

export const Skeleton = () => {
  return (
    <Table
      columns={
        columns.map((el) => ({
          ...el,
          renderTd: () => (
            <ContentLoader height="24" type="secondary" width="100%">
              <rect height="24" rx="8" ry="8" width="100%" x="0" y="0" />
            </ContentLoader>
          ),
        })) as TableColumn<string, Record<string, number>>[]
      }
      data={new Array(20).fill(null).map((_, i) => ({ id: i }))}
    />
  );
};
