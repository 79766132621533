import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { QueryHistory } from 'shared/api/queryHistory/types';
import type { ClassName } from 'shared/types';
import type { IconName } from 'shared/ui/Icon';

import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';

type Props = {
  historyItem: QueryHistory;
} & ClassName;

const InfoSection = ({ icon, text, title }: { icon: IconName; text: string; title: string }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center justify-center rounded-md border border-corduroy-200 p-1">
        <Icon className="size-6 text-primary-800" name={icon} />
      </div>
      <div className="flex flex-col gap-0.5">
        <div className="text-xs text-corduroy-500">{title}</div>
        <div className="text-xs">{text}</div>
      </div>
    </div>
  );
};

export const QueryHistoryCard = ({ className, historyItem }: Props) => {
  const historyDate = DateTime.fromISO(historyItem.date || '');
  return (
    <Card className={twMerge('p-0', className)}>
      <div className="flex w-full items-center justify-between border-b border-corduroy-100 p-3 py-2">
        <div className="flex items-center gap-1 text-corduroy-500">
          <Icon name="calendar" />

          {historyDate.isValid ? historyDate.toFormat('dd MMM, yyyy HH:mm a') : '-'}
        </div>
        <div className="flex items-center justify-center gap-2 rounded-full border border-corduroy-100 p-1 pr-3">
          <Icon className="size-7" name="nesaCoin" />
          <div className="flex flex-col gap-0.5">
            <div className="text-xs/none text-corduroy-500">Price per token</div>

            <div className="text-base/none font-semibold">{historyItem.pricePerToken || '-'} NES</div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col gap-3 p-4">
        <div className="flex flex-col gap-0.5">
          <div className="text-xs text-corduroy-500">Request</div>
          <div className="text-base font-semibold">{historyItem.question}</div>
        </div>
        <div className="flex flex-col gap-0.5">
          <div className="text-xs text-corduroy-500">Response</div>
          <div className="text-base font-semibold">{historyItem.result || '-'}</div>
        </div>
        <div className="grid grid-cols-2">
          <InfoSection icon="wallet" text={historyItem.walletAddress} title="Address" />
          <InfoSection
            icon="clock"
            text={historyItem.executionTime ? `${historyItem.executionTime}s` : '-'}
            title="Execution time"
          />
        </div>
      </div>
    </Card>
  );
};
