export const getExtensionsAndMimeTypes = (accept?: string) => {
  const acceptTokens = accept
    ? accept
        .split(',')
        .map((el) => el.trim())
        .filter(Boolean)
    : [];

  const extensions = new Set<string>();
  const mimeTypes = new Set<string>();
  const mimeTypeGroups = new Set<string>();

  acceptTokens.forEach((el) => {
    if (el.startsWith('.')) {
      extensions.add(el);
    } else if (el.includes('/*')) {
      const [prefix] = el.split('/*');
      mimeTypeGroups.add(prefix);
    } else {
      mimeTypes.add(el);
    }
  });

  return {
    extensions: [...extensions],
    mimeTypeGroups: [...mimeTypeGroups],
    mimeTypes: mimeTypes,
  };
};
