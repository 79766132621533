import type { ChangeEvent, DragEvent } from 'react';
import { useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { Icon } from '../Icon';
import { getExtensionsAndMimeTypes } from './helpers';

type Props = {
  accept?: string;
  className?: string;
  multiple?: boolean;
  onFileChange: (files: File[]) => void;
  title?: string;
};

export const FilePicker = ({
  accept,
  className,
  multiple = false,
  onFileChange,
  title = 'Drop your image here',
}: Props) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const [isDragFile, setIsFragFile] = useState(false);

  const handleClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    const files = fileList ? [...fileList] : [];

    onFileChange(files);
  };

  const onDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsFragFile(true);
  };

  const onDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsFragFile(false);
  };

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsFragFile(false);

    if (!fileRef.current) return;

    const initFiles = [...(e.dataTransfer.files || [])];
    const acceptFiles: File[] = [];

    const { extensions, mimeTypeGroups, mimeTypes } = getExtensionsAndMimeTypes(accept);

    if (!extensions.length && !mimeTypeGroups.length && !mimeTypes.size) {
      acceptFiles.push(...initFiles);
    } else {
      initFiles.forEach((file) => {
        if (
          mimeTypeGroups.some((prefix) => file.type.startsWith(prefix)) ||
          mimeTypes.has(file.type) ||
          extensions.some((ext) => file.name.endsWith(ext))
        ) {
          acceptFiles.push(file);
        }
      });
    }

    const dataTransfer = new DataTransfer();
    acceptFiles.forEach((file) => dataTransfer.items.add(file));
    fileRef.current.files = dataTransfer.files;

    const changeEvent = new Event('change', { bubbles: true });
    const inputEvent = new InputEvent('input', { bubbles: true, composed: true });

    fileRef.current.dispatchEvent(inputEvent);
    fileRef.current.dispatchEvent(changeEvent);
  };

  return (
    <div
      className={twMerge(
        'flex min-h-32 flex-col items-center justify-center rounded-lg border-2 border-dashed p-6',
        'transition-all duration-200',
        isDragFile ? 'border-primary-1000 text-primary-1000' : 'border-corduroy-400 text-corduroy-400',
        className,
      )}
      onClick={handleClick}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragEnter}
      onDrop={onDrop}
    >
      <input
        accept={accept}
        className="hidden"
        hidden
        multiple={multiple}
        onChange={handleChange}
        ref={fileRef}
        type="file"
      />
      <div className="flex items-center justify-center gap-4">
        <Icon className="size-9" name="imagePlus" />
        <div className="text-base font-semibold ">{title}</div>
      </div>
    </div>
  );
};
