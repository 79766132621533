import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance, setupInterceptors } from 'shared/config/axiosInstance';

export const useLogoutMutation = (options: UseMutationOptions = {}) => {
  return useMutation({
    mutationFn: async () => {
      await axiosInstance.post('/auth/logout');
      setupInterceptors('');
    },
    ...options,
  });
};
