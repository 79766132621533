import type { ComponentProps, ElementType, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName, PolymorphicProps } from 'shared/types';

export const Card = <E extends ElementType = 'div'>({
  as,
  children,
  className,
  ...props
}: PropsWithChildren<PolymorphicProps<E, ClassName>>) => {
  const Component = as || 'div';
  return (
    <Component {...props} className={twMerge('rounded-2xl p-6 2xl:p-8', className)}>
      {children}
    </Component>
  );
};

export const TitleCard = (props: ComponentProps<'h5'>) => {
  return (
    <h5
      {...props}
      className={twMerge(
        'mb-6 whitespace-nowrap text-2xl/none font-semibold text-corduroy-900 2xl:text-3xl/none',
        props.className,
      )}
    >
      {props.children}
    </h5>
  );
};

export const DescriptionCard = (props: ComponentProps<'p'>) => {
  return (
    <p {...props} className={twMerge('text-sm font-normal text-corduroy-500 2xl:text-base', props.className)}>
      {props.children}
    </p>
  );
};
