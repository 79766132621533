import { AnimatePresence, motion } from 'framer-motion';
import { useAccount } from 'graz';
import { DateTime } from 'luxon';

import type { QueryHistory as QueryHistoryItem } from 'shared/api/queryHistory/types';

import { useGetQueryHistoryByUserQuery } from 'shared/api/queryHistory/useGetQueryHistoryByUserQuery';
import { Accordion } from 'shared/ui/Accordion';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  modelId: string;
  onClick: (item: QueryHistoryItem) => void;
  userId: string;
};

export const QueryHistory = ({ modelId, onClick, userId }: Props) => {
  const { data: account } = useAccount();

  const { data: queryHistory, isLoading } = useGetQueryHistoryByUserQuery(
    {
      limit: 10,
      modelId: modelId,
      skip: 0,
      userId: userId,
      walletAddress: account?.bech32Address,
    },
    { enabled: !!userId && !!account?.bech32Address && !!modelId },
  );

  const history = queryHistory?.data || [];

  return (
    <Card animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
      <Accordion>
        <Accordion.Trigger>Query History</Accordion.Trigger>
        <Accordion.Content className="grid max-h-36 grid-cols-1 gap-2 overflow-scroll scrollbar-none">
          {isLoading ? (
            <div className="flex size-full items-center justify-center">
              <Spinner className="mx-auto size-6" />
            </div>
          ) : history.length > 0 ? (
            <div className="flex flex-1 flex-wrap gap-2 overflow-y-scroll scrollbar-none">
              {history.map((item) => {
                const { date, question } = item;
                return (
                  <AnimatePresence key={item._id}>
                    <motion.div
                      animate={{ opacity: 1 }}
                      className="flex w-40 min-w-40 cursor-pointer flex-col items-start justify-center gap-0.5 overflow-hidden rounded-2xl border border-corduroy-100 bg-white px-2 py-3 text-corduroy-700 transition-colors hover:border-primary-900 hover:bg-primary-40"
                      initial={{ opacity: 0 }}
                      key={item._id}
                      onClick={() => {
                        onClick(item);
                      }}
                    >
                      <div className="w-full overflow-hidden truncate px-1 text-corduroy-800">{question}</div>

                      <div className="flex items-center justify-start gap-1 font-fira text-xs text-corduroy-500">
                        <Icon className="size-4" name="clock" />
                        {date ? DateTime.fromISO(date).toFormat('dd/MM HH:mm a') : '-'}
                      </div>
                    </motion.div>
                  </AnimatePresence>
                );
              })}
            </div>
          ) : (
            <div className="absolute left-1/2 top-1/2 -z-10 flex size-full grow -translate-x-1/2 -translate-y-1/2 items-center justify-center pb-4 pt-8 text-center text-base font-light text-corduroy-500">
              No history available
            </div>
          )}
        </Accordion.Content>
      </Accordion>
    </Card>
  );
};
