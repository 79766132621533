import { twMerge } from 'tailwind-merge';

import { useEvent } from 'shared/hooks/useEvent';

import type { TableProps, TableRowExt, TableSort } from './types';

import { Th, TrTd } from './ui';

export const Table = <ColumnKey extends string, Row extends TableRowExt<ColumnKey>>({
  className,
  columns,
  data,
  keyTrFormatter,
  onRowClick,
  onSortChange,
  sort,
}: TableProps<ColumnKey, Row>) => {
  const onSort = useEvent((sort: TableSort<ColumnKey>) => {
    onSortChange?.(sort);
  });
  const onRowClickMemo = useEvent((row: Row) => {
    onRowClick?.(row);
  });

  return (
    <table className={twMerge('w-full border-separate border-spacing-0', className)}>
      <thead>
        <tr>
          {columns.map((column) => (
            <Th column={column} key={column.key} onSortChange={onSort} sort={sort} />
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => (
          <TrTd columns={columns} key={keyTrFormatter?.(row, i) || i} onRowClick={onRowClickMemo} row={row} />
        ))}
      </tbody>
    </table>
  );
};
