import type { Model } from 'shared/api/models/types';

import { Accordion } from 'shared/ui/Accordion';
import { Card } from 'shared/ui/Card';
import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';
import { Slider } from 'shared/ui/Slider';
import { Switch } from 'shared/ui/Switch';

import type { QueryParams } from './QueryHyperparams';

import { getIsZkAvailable } from '../helpers/getIsZkAvailable';

type Props = {
  isApiModel?: boolean;
  isExpanded?: boolean;
  model: Model;
  onChange: (params: Partial<QueryParams>) => void;
  onExpandedChange?: (val: boolean) => void;
  queryParams: QueryParams;
};

export const LLMParams = ({
  isApiModel,
  isExpanded,
  model,
  onChange,
  onExpandedChange,
  queryParams,
}: Props) => {
  const isZkAvailable = getIsZkAvailable(model);

  //

  if (!isApiModel) {
    return (
      <Card animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={isExpanded ? undefined : { opacity: 0 }}>
        <Accordion isExpanded={isExpanded} onChange={onExpandedChange}>
          <Accordion.Trigger>Parameters</Accordion.Trigger>
          <Accordion.Content className="grid-cols-2">
            <div>
              <Label>Temperature</Label>
              <Slider
                displayValue
                max={2}
                min={0}
                onValueChange={(values) => onChange({ temperature: values[0] })}
                step={0.01}
                value={[queryParams.temperature]}
              />
            </div>
            <div>
              <Label>Top P</Label>
              <Slider
                displayValue
                max={1}
                min={0}
                onValueChange={(values) => onChange({ topP: values[0] })}
                step={0.01}
                value={[queryParams.topP]}
              />
            </div>
            <div>
              <Label>Top K</Label>
              <Slider
                displayValue
                max={100}
                min={0}
                onValueChange={(values) => onChange({ topK: values[0] })}
                step={1}
                value={[queryParams.topK || 0]}
              />
            </div>
            <div>
              <Label>Num Beams</Label>
              <Slider
                displayValue
                max={20}
                min={1}
                onValueChange={(values) => onChange({ numBeams: values[0] })}
                step={1}
                value={[queryParams.numBeams || 1]}
              />
            </div>
            <div>
              <Label>Max New Tokens</Label>
              <Slider
                displayValue
                max={4096}
                min={1}
                onValueChange={(values) => onChange({ maxNewTokens: values[0] })}
                step={1}
                value={[queryParams.maxNewTokens || 1]}
              />
            </div>
            <div className="col-span-2">
              <Input
                label="System Prompt"
                onChange={(e) => onChange({ systemPrompt: e.target.value })}
                placeholder="Your System Prompt"
                value={queryParams.systemPrompt}
              />
            </div>

            <Label className="flex max-w-fit cursor-pointer items-center gap-2">
              <Switch
                checked={queryParams.private}
                onCheckedChange={(isChecked) => onChange({ private: isChecked })}
              />
              <span>Private</span>
            </Label>

            {isZkAvailable && (
              <Label className="flex max-w-fit cursor-pointer items-center gap-2">
                <Switch
                  checked={queryParams.useZk}
                  onCheckedChange={(isChecked) => onChange({ useZk: isChecked })}
                />
                <span>Use ZK</span>
              </Label>
            )}
          </Accordion.Content>
        </Accordion>
      </Card>
    );
  }

  return (
    <Card animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={isExpanded ? undefined : { opacity: 0 }}>
      <Accordion isExpanded={isExpanded} onChange={onExpandedChange}>
        <Accordion.Trigger>Parameters</Accordion.Trigger>
        <Accordion.Content className="grid-cols-2">
          <div>
            <Label>Temperature</Label>
            <Slider
              displayValue
              max={2}
              min={0}
              onValueChange={(values) => onChange({ temperature: values[0] })}
              step={0.01}
              value={[queryParams.temperature]}
            />
          </div>
          <div>
            <Label>Maximum Tokens</Label>
            <Slider
              displayValue
              max={256}
              min={0}
              onValueChange={(values) => onChange({ maxTokens: values[0] || 0 })}
              step={0.01}
              value={[queryParams.maxTokens || 0]}
            />
          </div>

          <div className="col-span-2">
            <Label>Stop sequence</Label>
            <Input
              onChange={(e) => onChange({ stopSequence: e.target.value })}
              placeholder="Enter sequence and press tab"
              value={queryParams.stopSequence}
            />
          </div>
          <div className="col-span-2">
            <Label>API Key</Label>
            <Input
              onChange={(e) => onChange({ apiKey: e.target.value })}
              placeholder="Your api key"
              value={queryParams.apiKey}
            />
          </div>
          <div>
            <Label>Top P</Label>
            <Slider
              displayValue
              max={1}
              min={0}
              onValueChange={(values) => onChange({ topP: values[0] })}
              step={0.01}
              value={[queryParams.topP]}
            />
          </div>
          <div>
            <Label>Frequency penalty</Label>
            <Slider
              displayValue
              max={2}
              min={0}
              onValueChange={(values) => onChange({ frequencyPenalty: values[0] })}
              step={0.01}
              value={[queryParams.frequencyPenalty]}
            />
          </div>
          <div>
            <Label>Presence penalty</Label>
            <Slider
              displayValue
              max={2}
              min={0}
              onValueChange={(values) => onChange({ presencePenalty: values[0] })}
              step={0.01}
              value={[queryParams.presencePenalty]}
            />
          </div>

          <Label className="col-span-2 flex max-w-fit cursor-pointer items-center gap-2">
            <Switch
              checked={queryParams.private}
              onCheckedChange={(isChecked) => onChange({ private: isChecked })}
            />
            <span>Private</span>
          </Label>
        </Accordion.Content>
      </Accordion>
    </Card>
  );
};
