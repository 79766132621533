import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { LlmHistory, LlmHistoryRequestParams, LlmHistoryResponse } from './types';

import { messagesKeys } from './queryKeys';

export const getLlmHistoryQueryKey = ({
  limit = 20,
  modelId,
  skip,
  userId,
  ...params
}: LlmHistoryRequestParams) => {
  return messagesKeys.list({ ...params, limit, modelId, skip, type: 'llm', userId });
};

export const useGetLlmHistoryQuery = (
  { limit = 20, modelId, userId, ...params }: LlmHistoryRequestParams,
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: LlmHistory[] = [], all: LlmHistory[][]) =>
      last.length === limit ? all.length : null,
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosInstance.get<LlmHistoryResponse>(
        `messages/models/${modelId}/${userId}/history`,
        {
          params: {
            ...params,
            limit,
            modelId,
            skip: pageParam * limit,
            userId,
          },
          signal,
        },
      );

      return res.data.list;
    },
    queryKey: getLlmHistoryQueryKey({ ...params, limit, modelId, userId }),
    ...(options || {}),
  });
};
