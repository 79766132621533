import { toast } from 'react-toastify';

import { twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';
import type { Review, ReviewFilter } from 'shared/api/reviews/types';
import type { ClassName } from 'shared/types';

import { queryClient } from 'app/App';
import { useUser } from 'app/stores/user';
import { theme } from 'app/theme';
import { ReviewModal } from 'pages/QueryPage/ui/ReviewModal';
import { reviewsKeys } from 'shared/api/reviews/queryKeys';
import { useDeleteReviewMutation } from 'shared/api/reviews/useDeleteReviewMutation';
import { useGetReviewSummaryQuery } from 'shared/api/reviews/useGetReviewSummaryQuery';
import { useGetUserReviewByModelQuery } from 'shared/api/reviews/useGetUserReviewByModelQuery';
import { useStateX } from 'shared/hooks/useStateX';
import { Accordion } from 'shared/ui/Accordion';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Chip } from 'shared/ui/Chip';
import { StarRating } from 'shared/ui/StarRating';

import { ConfirmationModal } from './ui/ConfirmationModal';
import { ReviewItem } from './ui/ReviewItem';
import { ReviewList } from './ui/ReviewList';
import { StarRow } from './ui/StarRow';

type Props = { isExpanded?: boolean; model: Model; onExpandedChange?: (val: boolean) => void } & ClassName;

const FILTERS: { id: ReviewFilter; title: string }[] = [
  { id: 'newest', title: 'Newest' },
  { id: 'highest', title: 'Highest' },
  { id: 'lowest', title: 'Lowest' },
];

export const Reviews = ({ isExpanded, model, onExpandedChange }: Props) => {
  const [state, setState] = useStateX<{
    filter: ReviewFilter;
    isDeleteConfirmOpen: boolean;
    isReviewOpen: boolean;
    selectedReview?: Review;
  }>({
    filter: 'newest',
    isDeleteConfirmOpen: false,
    isReviewOpen: false,
    selectedReview: undefined,
  });

  const { user } = useUser();
  const { data: userReviewData, isLoading } = useGetUserReviewByModelQuery({
    modelId: model._id,
    userId: user?._id || '',
  });
  const { data: reviewSummary } = useGetReviewSummaryQuery({
    modelId: model._id,
  });

  const userReview = userReviewData?.data;

  const { isPending: isDeleting, mutateAsync: deleteReview } = useDeleteReviewMutation();

  const handleDeleteConfirmation = async () => {
    try {
      if (!state.selectedReview?._id) {
        toast.error('No review selected');
        return;
      }

      await deleteReview({ id: state.selectedReview?._id });

      const key = reviewsKeys.list({ modelId: model._id, userId: user?._id });
      queryClient.removeQueries({ queryKey: key });

      setState({ isDeleteConfirmOpen: false, selectedReview: undefined });
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <Card className="!py-2">
      <Accordion isExpanded={isExpanded} onChange={onExpandedChange}>
        <Accordion.Trigger
          className="flex min-h-12 w-full items-center justify-between"
          containerClassName="items-center"
        >
          <div className="flex w-full flex-wrap items-center justify-between gap-1.5">
            <div className="flex w-fit items-center gap-2">
              Reviews{' '}
              <Chip className="truncate">
                Review this model and be rewarded with <b>20 NES</b> points!
              </Chip>
            </div>

            <div>
              {!userReview && !isLoading && (
                <Button
                  className="border border-corduroy-200 bg-corduroy-50 pl-4 pr-2"
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setState({ isReviewOpen: true, selectedReview: undefined });
                  }}
                  variant="filled-light"
                >
                  Write a review{' '}
                  <Chip className="truncate">
                    Get <b>20 NES</b> points!
                  </Chip>
                </Button>
              )}
            </div>
          </div>
        </Accordion.Trigger>
        <Accordion.Content animate={{ maxHeight: 'fit' }}>
          <div className="mb-4 flex w-full flex-1 items-center gap-4">
            <div className="flex flex-col items-center justify-center gap-3 border-r border-corduroy-100 px-8">
              <div className="text-3xl/none font-bold">{reviewSummary?.avg?.toFixed(1)}</div>
              <StarRating
                className="gap-0.5"
                rating={reviewSummary?.avg || 0}
                starColor={theme.colors.primary[800]}
                svgProps={{ height: 18, width: 18 }}
              />
              <div className="text-base text-corduroy-600">{reviewSummary?.totalCount} reviews</div>
            </div>

            <div className="flex w-full flex-col gap-3">
              {reviewSummary?.stars &&
                Object.entries(reviewSummary?.stars)
                  .reverse()
                  .map(([rating, count]) => {
                    return (
                      <StarRow
                        currentReviewsCount={count || 0}
                        key={rating}
                        rating={Number(rating)}
                        reviewsCount={reviewSummary?.totalCount || 0}
                      />
                    );
                  })}
            </div>
          </div>

          <div className="flex w-full items-center gap-2">
            {FILTERS.map(({ id, title }) => {
              const isActive = id === state.filter;
              return (
                <div
                  className={twMerge(
                    'cursor-pointer rounded-full border border-corduroy-100 bg-corduroy-50 px-4 py-3 text-sm/none font-light transition-colors hover:border-primary-200 hover:bg-primary-40 hover:text-primary-800',
                    isActive && 'border-primary-200 bg-primary-40 text-primary-900',
                  )}
                  key={id}
                  onClick={() => setState({ filter: id })}
                >
                  {title}
                </div>
              );
            })}
          </div>

          <div className="flex flex-col">
            {userReviewData?.data && (
              <ReviewItem
                isOwner
                onDelete={() => {
                  setState({ isDeleteConfirmOpen: true, selectedReview: userReviewData.data });
                }}
                onEdit={() => {
                  console.log('edit');
                  setState({ isReviewOpen: true, selectedReview: userReviewData.data });
                }}
                review={userReviewData?.data}
              />
            )}

            <ReviewList
              modelId={model._id}
              onReviewDelete={(review) => {
                setState({ isDeleteConfirmOpen: true, selectedReview: review });
              }}
              onReviewEdit={(review) => {
                setState({ isReviewOpen: true, selectedReview: review });
              }}
              sort={state.filter}
              userId={user?._id || ''}
            />
          </div>
        </Accordion.Content>
      </Accordion>

      <ReviewModal
        isOpen={state.isReviewOpen}
        model={model}
        onOpenChange={(isReviewOpen) => setState({ isReviewOpen })}
        review={state.selectedReview}
      />

      <ConfirmationModal
        content={'Are you sure you want to delete this review?'}
        isLoading={isDeleting}
        isOpen={state.isDeleteConfirmOpen}
        onConfirm={handleDeleteConfirmation}
        onOpenChange={(isOpen) => setState({ isDeleteConfirmOpen: isOpen })}
      />
    </Card>
  );
};
