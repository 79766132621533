import type { FormEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useIntersectionObserver } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';

import { useUser } from 'app/stores/user';
import { getIsApiModel } from 'pages/QueryPage/helpers/getIsApiModel';
import { LimitReachedModal } from 'pages/QueryPage/ui/LimitReachedModal';
import { Message } from 'pages/QueryPage/ui/Message';
import { ReviewText } from 'pages/QueryPage/ui/ReviewText';
import { useGetLlmSessionMessagesQuery } from 'shared/api/messages/useGetLlmSessionMessagesQuery';
import { Button } from 'shared/ui/Button';
import { Spinner } from 'shared/ui/Spinner';
import { TextArea } from 'shared/ui/TextArea';

type Props = {
  apiKey?: string;
  isTyping?: boolean;
  model: Model;
  onApiKeyChange?: (apiKey?: string) => void;
  onMessageSend: (message: string, apiKey?: string) => void;
  onReviewClick?: () => void;
  sessionId: string | undefined;
};

type FormValues = {
  message: string;
};

export const FREE_MSG_LIMIT = 5;

export const LLMChatContent = ({
  apiKey,
  isTyping,
  model,
  onApiKeyChange,
  onMessageSend,
  onReviewClick,
  sessionId = 'default',
}: Props) => {
  const { user } = useUser();
  const { data, fetchNextPage, hasNextPage, isPending } = useGetLlmSessionMessagesQuery(
    { modelName: model.name, sessionId: sessionId!, userId: user!._id },
    { enabled: !!user!._id && !!model.name, staleTime: Infinity },
  );

  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();
  const [isLimitOpen, setIsLimitOpen] = useState(false);

  const isApiModel = getIsApiModel(model);

  const {
    formState: { isValid },
    getValues,
    register,
    resetField,
  } = useForm<FormValues>({
    defaultValues: { message: '' },
    mode: 'onChange',
  });

  const messageHistory = useMemo(() => data?.pages?.flat() || [], [data?.pages]);

  const aiMessagesCount = useMemo(() => {
    const startToday = DateTime.now().startOf('day').toMillis();

    return messageHistory.filter((msg) => msg.history.role === 'assistant' && msg.timestamp - startToday > 0)
      .length;
  }, [messageHistory]);

  const hasMessages = messageHistory.length > 0;

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();

    if (isTyping) return;

    if (isApiModel && aiMessagesCount >= FREE_MSG_LIMIT && !apiKey) {
      setIsLimitOpen(true);

      return;
    }

    const { message } = getValues();

    onMessageSend(message);

    resetField('message');
  };

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-col items-start justify-center gap-1 border-b border-corduroy-100 bg-corduroy-50 px-5 py-4">
        <h2 className="text-center text-base/none font-normal">Chat</h2>
        {isApiModel && (
          <div className="text-center text-sm font-light text-corduroy-600">
            Number of free uses remaining:{' '}
            <span className="font-bold text-black">
              {aiMessagesCount >= FREE_MSG_LIMIT ? 0 : FREE_MSG_LIMIT - aiMessagesCount}/{FREE_MSG_LIMIT}.{' '}
            </span>
            <span
              className="cursor-pointer font-medium text-primary-800 transition-colors hover:text-primary-900"
              onClick={() => setIsLimitOpen(true)}
            >
              Learn more
            </span>
          </div>
        )}
      </div>

      <div className="flex flex-1 flex-col-reverse overflow-scroll p-4 scrollbar-none">
        {hasMessages &&
          messageHistory.map((message, i) => {
            const isUser = message.history.role === 'user';
            return (
              <AnimatePresence key={message._id}>
                {i === messageHistory.length - 1 && <div ref={ref} />}
                <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} key={message._id}>
                  <Message
                    className={twMerge('py-5', i === messageHistory.length - 1 && 'border-none')}
                    image={isUser ? undefined : model?.image}
                    isUser={isUser}
                    text={message.history.content}
                    title={isUser ? 'user' : model.name}
                  />
                </motion.div>
              </AnimatePresence>
            );
          })}

        {isPending ? (
          <div className="flex h-full items-center justify-center text-center text-corduroy-400">
            <Spinner className="size-6" />
          </div>
        ) : (
          !hasMessages && (
            <div className="flex h-full items-center justify-center text-center font-light text-corduroy-400">
              No messages
            </div>
          )
        )}
      </div>

      <form className="mt-auto flex w-full flex-col px-4 pb-4" onSubmit={handleSubmit}>
        <div className="relative flex flex-col pt-6">
          <AnimatePresence>
            {isTyping && (
              <motion.div
                animate={{ opacity: 1 }}
                className="absolute -top-0 right-0 flex items-center justify-end gap-1 text-right text-corduroy-500"
                initial={{ opacity: 0 }}
                transition={{ delay: 1 }}
              >
                <Spinner className="size-4" />
                {model.name} typing...
              </motion.div>
            )}
          </AnimatePresence>
          <TextArea
            placeholder="Write Message here"
            rows={3}
            {...register('message', { minLength: 1, required: true })}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey == false) {
                handleSubmit(e);
              }
            }}
          />
        </div>

        <div className="flex items-center justify-between">
          <Button className="w-fit px-10" disabled={!isValid || isTyping} isLoading={isTyping} type="submit">
            Submit
          </Button>

          {onReviewClick && <ReviewText onReviewClick={onReviewClick} />}
        </div>
      </form>

      <LimitReachedModal
        isOpen={isLimitOpen}
        model={model}
        onKeySubmit={(key) => {
          onApiKeyChange?.(key);
          setIsLimitOpen(false);
        }}
        onOpenChange={setIsLimitOpen}
        sentMessagesCount={aiMessagesCount}
      />
    </div>
  );
};
