import type { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

export const TitleBlock = (props: ComponentProps<'h2'>) => {
  return (
    <h2
      {...props}
      className={twMerge(
        'mb-6 text-center text-4xl font-semibold text-steel-900 2xl:text-[2.75rem] 2xl:leading-none',
        props.className,
      )}
    >
      {props.children}
    </h2>
  );
};
