import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import axios from 'axios';

let refreshingPromise: Promise<AxiosResponse<{ access_token: string }, unknown>> | null = null;

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  headers: {
    'Access-Control-Allow-Origin': `${import.meta.env.VITE_API_ENDPOINT}`,
    'Content-Type': 'application/json;charset=utf-8',
  },
  timeout: 15000,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      const accessToken = localStorage.getItem('user');

      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      return config;
    } catch (e) {
      window.console.error('axiosInstance', e);
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

export const setupInterceptors = (accessToken?: string) => {
  localStorage.setItem('user', accessToken || '');

  axiosInstance.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;
    if (error.response && error.response.status === 401) {
      if (!refreshingPromise) {
        try {
          refreshingPromise = axios.post<{ access_token: string }>(
            `${import.meta.env.VITE_API_ENDPOINT}/auth/refresh_token`,
            {},
            {
              headers: {
                'Access-Control-Allow-Origin': `${import.meta.env.VITE_API_ENDPOINT}`,
                'Content-Type': 'application/json;charset=utf-8',
              },
              timeout: 15000,
              withCredentials: true,
            },
          );
          const { data } = await refreshingPromise;
          refreshingPromise = null;
          setupInterceptors(data.access_token);
          error.config.headers['Authorization'] = `Bearer ${data.access_token}`;

          return axiosInstance.request(originalRequest);
        } catch (refreshError) {
          refreshingPromise = null;
          return Promise.reject(error);
        }
      }
      await refreshingPromise;
      return axiosInstance.request(originalRequest);
    }
    return Promise.reject(error);
  },
);
