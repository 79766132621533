import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { ContentLoader } from '../ContentLoader';

type Props = { enable?: boolean; rx?: number; ry?: number } & ClassName;

export const StretchedSkeleton = ({ className, enable, rx = 4, ry = 4 }: Props) => {
  return (
    <AnimatePresence>
      {enable && (
        <motion.div
          animate={{ opacity: 1 }}
          className={twMerge('absolute left-0 top-0 size-full flex-1', className)}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
        >
          <ContentLoader height="100%" width="100%">
            <rect height="100%" rx={rx} ry={ry} width="100%" x="0" y="0" />
          </ContentLoader>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
