import type { QueryParams } from './ui/QueryHyperparams';

export const defaultQueryParams: QueryParams = {
  context: undefined,
  frequencyPenalty: 0,
  presencePenalty: 0,
  question: '',
  temperature: 1,
  topP: 1,
};

export const ERROR_CODES = [311, 312, 313, 314, 315, 316, 317, 318, 319];
