import { useEffect } from 'react';

import { create } from 'zustand';

export type ConfigAppStore = {
  setShowAIKernals: (showAIKernals: boolean) => void;
  showAIKernals: boolean;
};

export const useConfigApp = create<ConfigAppStore>((set) => ({
  setShowAIKernals: (showAIKernals: boolean) => {
    set({ showAIKernals });
  },
  showAIKernals: true,
}));

export const useNotShowAIKernalsMobile = () => {
  const { setShowAIKernals } = useConfigApp();
  useEffect(() => {
    setShowAIKernals(false);

    return () => {
      setShowAIKernals(true);
    };
  }, [setShowAIKernals]);
};
