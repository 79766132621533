import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { modelsKeys } from './queryKeys';

type Response = {
  data: Record<string, { id: string; requestCount: number }>;
};
export const useGetModelStatistics = (
  { ids }: { ids: string[] },
  options: UseQueryOptions<Response> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<Response>(`/models/statistics`, {
        params: { ids: ids.join(',') },
        signal,
      });

      return data;
    },
    queryKey: modelsKeys.list({ ids, type: 'model-stat' }),
    ...options,
  });
};
