import { useEffect } from 'react';

import { useIntersectionObserver } from '@uidotdev/usehooks';

import type { Review, ReviewFilter } from 'shared/api/reviews/types';

import { useGetReviewsByModelQuery } from 'shared/api/reviews/useGetReviewsByModelQuery';
import { Spinner } from 'shared/ui/Spinner';

import { ReviewItem } from './ReviewItem';

type Props = {
  modelId: string;
  onReviewDelete: (review: Review) => void;
  onReviewEdit: (review: Review) => void;
  sort: ReviewFilter;
  userId: string;
};
export const ReviewList = ({ modelId, onReviewDelete, onReviewEdit, sort, userId }: Props) => {
  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const {
    data: modelReviewsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useGetReviewsByModelQuery({
    limit: 4,
    modelId,
    sort,
    userId,
  });

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  const modelReviews = modelReviewsData?.pages?.flat() || [];

  return isLoading ? (
    <div className="flex items-center justify-center py-8">
      <Spinner className="size-6" />
    </div>
  ) : modelReviews.length > 0 ? (
    <>
      {modelReviews.map((review, i) => {
        if (review.userId === userId) return;

        return (
          <div key={`review-${review._id}`}>
            <ReviewItem
              key={review._id}
              onDelete={() => onReviewDelete(review)}
              onEdit={() => onReviewEdit(review)}
              review={review}
            />
            {i === modelReviews.length - 1 && <div key={`last-${review._id}`} ref={ref} />}
          </div>
        );
      })}

      {isFetchingNextPage && (
        <div className="flex items-center justify-center py-3">
          <Spinner className="size-6" />
        </div>
      )}
    </>
  ) : (
    <div className="flex items-center justify-center py-10 text-sm font-light text-corduroy-500">
      No reviews available
    </div>
  );
};
