import type { UseMutationOptions } from '@tanstack/react-query';

import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

export const useVerifyDiscordMutation = (options: UseMutationOptions<{ link: string }, Error> = {}) => {
  return useMutation({
    mutationFn: async () => {
      const { data } = await axiosInstance.post<{ link: string }>(`/auth/discord`, undefined, {
        timeout: 40000,
      });

      return data;
    },
    ...options,
  });
};
