import type { UseMutationOptions } from '@tanstack/react-query';

import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { EmailVerificationResponse } from './types';

export const useSendEmailVerificationMutation = (
  options: UseMutationOptions<EmailVerificationResponse, Error> = {},
) => {
  return useMutation({
    mutationFn: async () => {
      const { data } = await axiosInstance.post<EmailVerificationResponse>(
        `/email/send_verification`,
        undefined,
        { timeout: 40000 },
      );

      return data;
    },
    ...options,
  });
};
