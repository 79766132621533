import { useUser } from 'app/stores/user';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Spinner } from 'shared/ui/Spinner';

import bgImage from './assets/cover-bg.png';
import { FaucetContent } from './ui/FaucetContent';

export const Faucet = () => {
  const { isLoading } = useUser();
  return (
    <AnimateRoute className="pb-8">
      <div
        className="flex min-h-72 w-full flex-col items-center justify-end bg-[#2A213E] bg-cover bg-center bg-no-repeat py-12"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="mx-auto mb-3 mt-auto w-full text-center text-xl font-semibold text-white md:text-3xl">
          Nesa Testnet Faucet
        </h2>
        <p className="text-center font-thin text-white/50">
          Dear Nesa Explorers! <br /> Welcome to our testnet realm! Looking for tokens to launch your journey?
        </p>
      </div>

      {isLoading ? (
        <div className="flex w-full items-center justify-center py-12">
          <Spinner className="size-6" />
        </div>
      ) : (
        <FaucetContent />
      )}
    </AnimateRoute>
  );
};
