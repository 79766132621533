import { useCallback, useId, useState } from 'react';

import { DateTime } from 'luxon';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import type { PeriodType } from 'pages/Wallet/types';

import { theme } from 'app/theme';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';

type Props = {
  data: {
    countTransactions: null | number;
    date: string;
  }[];
  isEmptyData: boolean;
  periodType: PeriodType;
};

const axisFontSize = 10;

export const Chart = ({ data, isEmptyData, periodType }: Props) => {
  const [yAxisWidth, setYAxisWidth] = useState(20);

  const xs = useMinWidthMediaQuery('xs');

  const gradId = useId();

  const tickFormatter = useCallback((balance: number) => {
    const label = `${balance}`;
    const width = label.length * axisFontSize;
    setTimeout(() => setYAxisWidth((prev) => (prev > width ? prev : width)));

    return label;
  }, []);

  const intervalXAxis = xs ? (['day', 'month'].includes(periodType) ? 1 : 0) : periodType === 'week' ? 0 : 1;

  return (
    <ResponsiveContainer>
      <AreaChart data={data} margin={{ bottom: 12, left: 0, right: 20, top: 24 }}>
        <CartesianGrid stroke="#EEEFF3f0" strokeWidth={1} />

        <YAxis
          allowDataOverflow={false}
          allowDecimals={false}
          axisLine={false}
          domain={isEmptyData ? [0, 4] : undefined}
          minTickGap={0}
          tick={{
            color: theme.colors.corduroy[700],
            fontFamily: 'DM Sans',
            fontSize: axisFontSize,
            fontWeight: 400,
          }}
          tickFormatter={tickFormatter}
          tickLine={false}
          width={yAxisWidth}
        />

        <XAxis
          allowDataOverflow={false}
          axisLine={false}
          dataKey="date"
          interval={intervalXAxis}
          minTickGap={0}
          tick={({ payload, x, y }: AxisTickProps) => {
            const date = DateTime.fromISO(payload.value);

            return (
              <g transform={`translate(${x},${y})`}>
                <text
                  dy={12}
                  fill={theme.colors.corduroy[700]}
                  fontFamily="DM Sans"
                  fontSize={axisFontSize}
                  fontWeight={400}
                  textAnchor="middle"
                  x={0}
                  y={0}
                >
                  {periodType === 'year'
                    ? date.toFormat('LL.yy')
                    : periodType === 'day'
                      ? date.toFormat('T')
                      : date.toFormat('dd.LL')}
                </text>
              </g>
            );
          }}
          tickLine={false}
        />

        <defs>
          <linearGradient gradientUnits="objectBoundingBox" id={gradId} x1="1" x2="1" y1="0" y2="1">
            <stop stopColor="#8356E2" />
            <stop offset="1" stopColor="#8356E2" stopOpacity="0" />
          </linearGradient>
        </defs>

        <Area
          activeDot={{
            fill: theme.colors.white,
            height: 8,
            stroke: theme.colors.primary[900],
            strokeWidth: 1.5,
            width: 8,
          }}
          dataKey="countTransactions"
          fill={`url(#${gradId})`}
          stroke={theme.colors.primary[900]}
          strokeWidth={2}
          type="monotone"
        />

        <Tooltip
          content={({ active, payload }) => {
            const data: Props['data'][number] = payload?.[0]?.payload;
            if (!data || !active) return null;
            return (
              <div className="z-30 rounded-md bg-white px-3 py-2 shadow-sm">
                <div className="text-xs font-medium text-corduroy-900">
                  Period:{' '}
                  {periodType === 'day' &&
                    `${DateTime.fromISO(data.date).toFormat('T')} - ${DateTime.fromISO(data.date).endOf('hour').toFormat('T')}`}
                  {(periodType === 'week' || periodType === '2week' || periodType === 'month') &&
                    `${DateTime.fromISO(data.date).toFormat('LLL d, T')} - ${DateTime.fromISO(data.date).endOf('day').toFormat('T')}`}
                  {periodType === 'year' && `${DateTime.fromISO(data.date).toFormat('LLL yyyy')}`}
                </div>
                <div className="mt-2 flex items-center justify-between gap-5">
                  <div className="text-xs text-corduroy-900">Transactions count:</div>
                  <div className="text-xs font-semibold text-corduroy-900">{data.countTransactions}</div>
                </div>
              </div>
            );
          }}
          cursor={false}
          wrapperStyle={{ outline: 'none' }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

type AxisTickProps = {
  payload: { value: string };
  x: number;
  y: number;
};
