import { AnimatePresence, motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { LlmHistory } from 'shared/api/messages/types';

import { useGetLlmHistoryQuery } from 'shared/api/messages/useGetLlmHistoryQuery';
import { Accordion } from 'shared/ui/Accordion';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  activeSessionId?: string;
  isExpanded?: boolean;
  modelId: string;
  onClick: (item: LlmHistory) => void;
  onExpandedChange?: (val: boolean) => void;
  userId: string;
};

export const LLMSessionHistory = ({
  activeSessionId,
  isExpanded,
  modelId,
  onClick,
  onExpandedChange,
  userId,
}: Props) => {
  const { data, isPending } = useGetLlmHistoryQuery({ modelId, userId });

  const history = data?.pages.flat() || [];
  return (
    <Card animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
      <Accordion isExpanded={isExpanded} onChange={onExpandedChange}>
        <Accordion.Trigger>Session History</Accordion.Trigger>
        <Accordion.Content
          animate={{ maxHeight: 256 }}
          className="grid max-h-36 grid-cols-1 gap-2 overflow-scroll scrollbar-none"
        >
          {isPending ? (
            <div className="flex size-full items-center justify-center">
              <Spinner className="mx-auto size-6" />
            </div>
          ) : history.length > 0 ? (
            <div className="flex flex-1 flex-wrap gap-2 overflow-y-scroll scrollbar-none">
              {history.map((item) => {
                const { _id, messages, timestamp } = item;
                const firstMessage = messages[0].content;
                return (
                  <AnimatePresence key={_id}>
                    <motion.div
                      animate={{ opacity: 1 }}
                      className={twMerge(
                        'flex w-40 min-w-40 cursor-pointer flex-col items-start justify-center gap-0.5 overflow-hidden rounded-2xl border border-corduroy-100 bg-white px-2 py-3 text-corduroy-700 transition-colors hover:border-primary-900 hover:bg-primary-40',
                        item._id === activeSessionId && 'border-primary-900 bg-primary-50',
                      )}
                      initial={{ opacity: 0 }}
                      key={_id}
                      onClick={() => {
                        onClick(item);
                      }}
                    >
                      <div className="w-full overflow-hidden truncate px-1 text-corduroy-800">
                        {firstMessage}
                      </div>

                      <div className="flex items-center justify-start gap-1 font-fira text-xs text-corduroy-500">
                        <Icon className="size-4" name="clock" />
                        {timestamp ? DateTime.fromISO(timestamp).toFormat('dd/MM HH:mm a') : '-'}
                      </div>
                    </motion.div>
                  </AnimatePresence>
                );
              })}
            </div>
          ) : (
            <div className="z-[-1] flex size-full grow items-center justify-center pb-3 text-center text-base font-light text-corduroy-500">
              No history available
            </div>
          )}
        </Accordion.Content>
      </Accordion>
    </Card>
  );
};
