import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDebounce } from '@uidotdev/usehooks';
import { twMerge } from 'tailwind-merge';

import { getCategoryLabel } from 'pages/GalleryHome/helpers/getCategoryLabel';
import { useModelListQuery } from 'shared/api/models/useGetModelListQuery';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Popover } from 'shared/ui/Popover';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  className?: string;
};

export const Search = ({ className }: Props) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const debouncedValue = useDebounce(searchValue, 300);

  const { data, isPending } = useModelListQuery({
    limit: 5,
    search: debouncedValue,
  });

  const modelsResult = data?.pages.flat() || [];

  const resetValue = () => {
    setSearchValue('');
    setIsPopoverOpen(false);
  };

  return (
    <form
      className={twMerge('flex w-1/3', className)}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
        <Popover.Trigger
          asChild
          className="ml-1 flex w-full cursor-pointer items-center gap-0 text-xs font-medium outline-none transition-colors hover:text-primary-900"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="w-full">
            <Input
              className="group justify-items-center"
              classNameInputWrapper="shadow-sm rounded-xl px-2"
              endSlot={
                searchValue && (
                  <Icon
                    className="cursor-pointer text-corduroy-500 transition-colors hover:text-primary-900"
                    name="close"
                    onClick={resetValue}
                  />
                )
              }
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                const value = e.target.value;

                setIsPopoverOpen(value.length > 2);
                setSearchValue(value);
              }}
              onFocusCapture={() => {
                if (searchValue.length > 2) setIsPopoverOpen(true);
              }}
              placeholder="Find anything"
              size="small"
              startSlot={
                <Icon
                  className="ml-1 size-4 text-clay-300 transition-colors group-focus-within:text-clay-600"
                  name="search"
                />
              }
              value={searchValue}
            />
          </div>
        </Popover.Trigger>
        <Popover.Content
          alignOffset={0}
          className="flex w-full max-w-full flex-col p-3 sm:w-[512px]"
          onOpenAutoFocus={(e) => e.preventDefault()}
          sideOffset={10}
        >
          {isPending && (
            <div className="flex items-center justify-center py-4">
              <Spinner className="size-5" />
            </div>
          )}

          {!isPending && !modelsResult.length && (
            <div className="flex flex-col items-center  py-3 ">
              <div className="mx-auto mb-2 flex size-11 items-center justify-center rounded-full border border-clay-20 shadow-boxy">
                <Icon className="size-4 text-clay-350" name="search" />
              </div>
              <h4 className="mb-2 text-lg">No Result Found</h4>

              <div className="mb-3 text-center text-sm font-light text-clay-400">
                <span className="font-normal text-clay-900">“{searchValue}”</span> did not match any models or
                commands. Please try again.
              </div>

              <Button color="secondary" onClick={resetValue} variant="filled-light">
                Clear search
              </Button>
            </div>
          )}

          {!isPending && modelsResult.length > 0 && (
            <div className="flex  flex-col ">
              <div className="mb-2 border-b border-clay-20 pb-2 text-sm font-light text-clay-350">
                Search Results
              </div>

              {modelsResult.map((model) => {
                return (
                  <div
                    className="flex h-8 cursor-pointer items-center gap-3 rounded-lg px-2 text-sm font-normal text-clay-700 transition-colors hover:bg-clay-20"
                    key={model._id}
                    onClick={() => {
                      navigate(`/models/${model._id}`);
                      setIsPopoverOpen(false);
                    }}
                  >
                    <span className="flex items-center gap-1 truncate">
                      <Icon className="size-4 text-clay-350" name="box" />
                      {model.name}
                    </span>

                    <div className="ml-auto truncate rounded-[4px] border border-clay-20 bg-white px-1 py-0.5 text-xs uppercase text-clay-300">
                      {getCategoryLabel(model.type)}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Popover.Content>
      </Popover>
    </form>
  );
};
