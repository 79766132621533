import { useMemo } from 'react';

import { DateTime } from 'luxon';
import { twJoin } from 'tailwind-merge';

import type { WalletTransaction } from 'pages/Wallet/types';

import { COIN_NAME, DATETIME_MED } from 'shared/const';
import { formatNumber } from 'shared/helpers/formatNumber';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';

import EmptyIcon from './empty-illustration.svg?react';

type Props = {
  isLoading?: boolean;
  showMore?: boolean;
  transactions: WalletTransaction[];
};

export const Transactions = ({ isLoading, showMore, transactions }: Props) => {
  const sm = useMinWidthMediaQuery('sm');

  const lastTransactions = useMemo(() => {
    return transactions.slice(showMore ? -60 : -6).reverse();
  }, [transactions, showMore]);

  return (
    <div className="flex max-w-full grow flex-col overflow-scroll">
      {isLoading ? (
        <div className="flex items-center justify-center py-20">
          <Spinner className="size-6" />
        </div>
      ) : lastTransactions.length === 0 ? (
        <div className="flex flex-col items-center gap-4 py-20">
          <EmptyIcon className="w-32" />
          <div className="text-base text-corduroy-600">No History Available</div>
        </div>
      ) : (
        lastTransactions.map((transaction) => {
          const isReceived = transaction.type === 'received';

          return (
            <div
              className="flex flex-wrap items-center gap-3 border-b border-corduroy-100 py-2 last:border-none 2xl:py-3"
              key={transaction.id}
            >
              <div className="flex flex-1 items-center justify-between gap-2.5">
                <div
                  className={twJoin(
                    'flex size-10 items-center justify-center rounded-lg',
                    isReceived ? 'bg-[#DEFFEF]' : 'bg-[#FFEFE3]',
                  )}
                >
                  <Icon
                    className={twJoin('size-7 2xl:size-8', isReceived ? 'text-[#08BF9E]' : 'text-[#F88E13]')}
                    name={isReceived ? 'arrowUpLong' : 'arrowUpRightLong'}
                  />
                </div>
                <div className="flex-1">
                  <div className="mb-0.5 text-base/none font-normal text-corduroy-900">
                    <b>{formatNumber(transaction.value, { decimals: 6 })}</b> {COIN_NAME}
                  </div>
                  <div className="text-sm font-light text-corduroy-500">
                    {DateTime.fromISO(transaction.date).toFormat(DATETIME_MED)}
                  </div>
                </div>
              </div>
              {sm && transaction.data && (
                <div className="min-w-24 text-xs font-light text-corduroy-400 sm:min-w-28">
                  {transaction.data}
                </div>
              )}
              <div className="flex min-w-20 justify-end">
                <div
                  className={twJoin(
                    'w-fit rounded-full px-2 py-1 text-xs font-normal uppercase text-black',
                    isReceived ? 'bg-[#DEFFEF] !text-[#08BF9E]' : 'bg-[#FFEFE3] !text-[#F88E13]',
                  )}
                >
                  {transaction.type}
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
