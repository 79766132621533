import type { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

export const DescriptionBlock = (props: ComponentProps<'h2'>) => {
  return (
    <h2
      {...props}
      className={twMerge(
        'mb-8 text-center text-sm font-normal text-corduroy-700 2xl:mb-12 2xl:text-base',
        props.className,
      )}
    >
      {props.children}
    </h2>
  );
};
