import { useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  className?: string;
  tooltipText?: string;
};
export const UserLink = ({ className, tooltipText }: Props) => {
  const { user } = useUser();
  const navigate = useNavigate();

  return (
    <Tooltip content={tooltipText || ''} side="bottom">
      <div
        className={twMerge(
          'flex size-9 cursor-pointer items-center justify-center rounded-full bg-tusk-100 pb-1 font-klapt text-lg/none font-bold uppercase transition-colors hover:bg-tusk-300',
          className,
        )}
        onClick={() => navigate(`/account/${user?._id}`)}
      >
        {user?.first_name?.slice(0, 1) ?? (user?.email?.slice(0, 1) || '-')}
      </div>
    </Tooltip>
  );
};
