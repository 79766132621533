import { memo, useState } from 'react';
import { toast } from 'react-toastify';

import { AnimatePresence } from 'framer-motion';
import { useAccount } from 'graz';
import { twMerge } from 'tailwind-merge';

import type { TransactionData } from 'pages/QueryPage/types';
import type { QueryParams } from 'pages/QueryPage/ui/QueryHyperparams';
import type { Model } from 'shared/api/models/types';

import { useUser } from 'app/stores/user';
import { LoginModal } from 'features/LoginModal';
import { FilePreview } from 'features/ModelQuery/ui/FilePreview';
import { WalletProviderModal } from 'features/WalletProviderModal';
import { getIsImageModel } from 'pages/QueryPage/helpers/getIsImageModel';
import { getIsQuestionContextModel } from 'pages/QueryPage/helpers/getIsQuestionContextModel';
import { JsonData } from 'pages/QueryPage/ui/JsonData';
import { QueryHyperparams } from 'pages/QueryPage/ui/QueryHyperparams';
import { ReviewText } from 'pages/QueryPage/ui/ReviewText';
import { Button } from 'shared/ui/Button';
import { FilePicker } from 'shared/ui/FilePicker';
import { Icon } from 'shared/ui/Icon';
import { Switch } from 'shared/ui/Switch';
import { TextArea } from 'shared/ui/TextArea';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  errors: Record<string, boolean>;
  isRequestingSession?: boolean;
  model?: Model;
  onQueryParamsChange: (params: Partial<QueryParams>) => void;
  onRequestSession: () => void;
  onReviewClick: () => void;
  onSendQuery: (queryParams?: Partial<QueryParams>) => void;
  previewJson: Record<string, unknown>;
  queryParams: QueryParams;
  txData: TransactionData;
};

const MB_IN_BYTES = 1048576;

export const RequestContent = memo(
  ({
    errors,
    isRequestingSession,
    model,
    onQueryParamsChange,
    onRequestSession,
    onReviewClick,
    onSendQuery,
    previewJson,
    queryParams,
    txData,
  }: Props) => {
    const { data: account } = useAccount();
    const { user } = useUser();

    const [showFilters, setShowFilters] = useState(false);
    const [showWalletProvider, setShowWalletProvider] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const isModelExpectImage = getIsImageModel(model);
    const isQuestionNContext = getIsQuestionContextModel(model);

    const handleUploadFile = async (file: File) => {
      if (!file) return;

      if (file.size > 2 * MB_IN_BYTES) {
        toast.error('File is too big');

        return;
      }

      onQueryParamsChange({ file });
    };

    return (
      <>
        <div className="mb-3 text-lg font-normal text-black">Enter query</div>

        {!txData.transactionHash && (
          <div className="absolute inset-0 z-10 flex items-center justify-center overflow-hidden bg-white/75">
            {!account?.bech32Address ? (
              <Button onClick={() => setShowWalletProvider(true)}>Connect wallet</Button>
            ) : (
              <Button isLoading={isRequestingSession} onClick={onRequestSession}>
                Start Inference
              </Button>
            )}
          </div>
        )}

        <div className="mb-4 flex items-start gap-2">
          {!isModelExpectImage && (
            <div className="relative w-full overflow-hidden rounded-lg">
              <TextArea
                className="m-0 w-full p-0"
                innerClassName="pb-8"
                onChange={(e) => onQueryParamsChange({ question: e.target.value })}
                placeholder="Enter your query here"
                value={queryParams.question}
              />
              {isQuestionNContext && (
                <TextArea
                  className="mt-4 w-full p-0"
                  innerClassName="pb-8"
                  onChange={(e) => onQueryParamsChange({ context: e.target.value })}
                  placeholder="Enter your context here"
                  value={queryParams.context}
                />
              )}

              <div
                className={twMerge(
                  'absolute inset-x-px bottom-px flex items-center gap-0.5 rounded-b-md bg-primary-200 py-0.5 pl-2 text-xs text-primary-800 transition-colors',
                  errors.inputError && 'bg-primary-800 text-white',
                )}
              >
                <Icon name="infoCircle" />
                {model?.expectedInput === 'image'
                  ? 'This model requires an image input'
                  : model?.expectedInput === 'text'
                    ? 'This model requires text input'
                    : model?.expectedInput}
              </div>
            </div>
          )}
          {isModelExpectImage && (
            <FilePicker
              accept=".jpeg,.png,.svg,.jpg"
              className=" flex-1"
              onFileChange={([file]) => handleUploadFile(file)}
            />
          )}

          <Button
            className="w-fit"
            color="secondary"
            onClick={() => setShowFilters((prev) => !prev)}
            variant="filled-light"
          >
            <Icon
              className={twMerge('transition-colors', showFilters && 'text-primary-800')}
              name="filters"
            />
          </Button>
        </div>

        <div className="mb-4 flex items-center justify-start gap-1">
          <label className="flex items-center gap-1.5">
            <Switch
              checked={queryParams.private}
              onCheckedChange={(checked) => onQueryParamsChange({ private: checked })}
            />
            <span className="text-sm text-corduroy-800 lg:text-base 2xl:text-sm">Private</span>
          </label>
          <Tooltip content="Your request will be secured by ZK and/or TEE.">
            <Icon
              className="cursor-pointer text-corduroy-500 transition-colors hover:text-primary-900"
              name="questionCircle"
            />
          </Tooltip>
        </div>

        <AnimatePresence>
          {showFilters && <QueryHyperparams onChange={onQueryParamsChange} value={queryParams} />}
        </AnimatePresence>

        <AnimatePresence>
          {queryParams.file && (
            <FilePreview
              className="mt-2"
              file={queryParams.file}
              onRemove={() => onQueryParamsChange({ file: undefined })}
            />
          )}
        </AnimatePresence>

        <div className="my-4">
          <JsonData data={previewJson} />
        </div>

        <div className="flex gap-3">
          {user ? (
            <>
              <Button
                disabled={
                  (isModelExpectImage
                    ? !queryParams.file
                    : isQuestionNContext
                      ? !queryParams.question || !queryParams.context
                      : !queryParams.question) || txData.status === 'progress'
                }
                onClick={() => {
                  if (!account?.bech32Address) {
                    setShowWalletProvider(true);
                    return;
                  }

                  onSendQuery();
                }}
              >
                Send query
              </Button>
            </>
          ) : (
            <Button onClick={() => setShowLoginModal(true)}>Login</Button>
          )}
        </div>

        {onReviewClick && <ReviewText className="absolute bottom-3 right-5" onReviewClick={onReviewClick} />}

        <WalletProviderModal onOpenChange={setShowWalletProvider} open={showWalletProvider} />
        <LoginModal isOpen={showLoginModal} onOpenChange={setShowLoginModal} />
      </>
    );
  },
);
