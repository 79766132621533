import type { GetQueryHistoryByPeriodParams } from './types';

export const queryHistoryKeys = {
  base: ['query-history'],
  byPeriod: (params: GetQueryHistoryByPeriodParams) => [...queryHistoryKeys.base, 'by-period', params],
  list: (params: {
    id?: string;
    limit: number;
    skip: number;
    type?: string;
    userId?: string;
    walletAddress?: string;
  }) => [...queryHistoryKeys.base, params],
};
