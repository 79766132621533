import type { ReactNode } from 'react';

import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';

type Props = {
  content: ReactNode;
  isLoading?: boolean;
  isOpen: boolean;
  onConfirm: () => void;
  onOpenChange: (isOpen: boolean) => void;
};

export const ConfirmationModal = ({ content, isLoading, isOpen, onConfirm, onOpenChange }: Props) => {
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content>
        <Modal.CloseButton />
        <div className="mb-4 text-lg font-light">{content}</div>

        <div className="flex items-center gap-3">
          <Button
            className="!border-transparent bg-red-800 hover:bg-red-800 hover:brightness-90 "
            isLoading={isLoading}
            onClick={onConfirm}
          >
            Yes, delete
          </Button>
          <Button color="secondary" onClick={() => onOpenChange(false)} variant="filled-light">
            Cancel
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
