import { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { getIsColorLight } from 'shared/helpers/getIsColorLight';
import { getRandomColor } from 'shared/helpers/getRandomColor';
import { JsonEditor } from 'shared/ui/JsonEditor';
import { Popover } from 'shared/ui/Popover';

type TextGenData = {
  end: null | unknown;
  entity: string;
  index: number;
  score: number;
  start: null | unknown;
  word: string;
};
type Props = {
  data: TextGenData[];
  input: string | undefined;
};

const generateParts = (input: string, data: TextGenData[]) => {
  const inputByWords = input.trim().split(' ');

  if (!data[0]) return [];

  const firstItemIdx = data[0] ? data[0]?.index - 1 : 0;

  const startPart = inputByWords.slice(0, firstItemIdx).join(' ');

  const result = data.reduce<{ entity?: string; isWordStart?: boolean; str: string; wordIndex?: number }[]>(
    (acc, item, idx) => {
      const isFullWord = item.word === inputByWords[item.index - 1];

      if (isFullWord) {
        acc.push({ entity: item.entity, str: item.word.replace(/##|▁/, ''), wordIndex: item.index });

        return acc;
      }

      const isWordStart = inputByWords[item.index - 1]?.startsWith(item.word);
      const prevWord = acc[idx];

      acc.push({
        entity: item.entity,
        isWordStart,
        str: item.word.replace(/##|▁/, ''),
        wordIndex: isWordStart ? item.index : prevWord.wordIndex,
      });

      return acc;
    },
    [{ entity: undefined, str: startPart }],
  );

  const lastItem = result[result.length - 1].wordIndex;

  return [...result, { entity: undefined, str: lastItem ? inputByWords.slice(lastItem + 1).join(' ') : '' }];
};

// My name is Wolfgang and I live in Berlin
// My name is Wolfgang and I live in Berlin and Munchen
export const TokenClassificationResponse = ({ data, input }: Props) => {
  const colorsByEntity = useMemo(() => {
    // TODO: update this function to have only one reduce
    const entities = data.reduce<string[]>((acc, item) => {
      if (!acc.includes(item.entity)) {
        acc.push(item.entity);
      }
      return acc;
    }, []);

    return entities.reduce<Record<string, string>>((acc, entity) => {
      acc[entity] = getRandomColor();
      return acc;
    }, {});
  }, [data]);

  const parts = generateParts(input || '', data);

  const colors = Object.entries(colorsByEntity);

  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="flex gap-2">
        {colors.map(([entity, color]) => {
          const isLight = getIsColorLight(color);

          return (
            <div
              className={twMerge('rounded-sm p-2 ', !isLight && 'text-white')}
              key={entity}
              style={{ backgroundColor: color }}
            >
              {entity}
            </div>
          );
        })}
      </div>
      {parts?.length > 0 && (
        <div className="rounded-md bg-corduroy-100/50 px-4 py-3">
          {parts?.map((data, index) => {
            const { entity, isWordStart, str } = data;

            if (!entity) {
              return `${str} `;
            }

            const color = entity && colorsByEntity[entity];

            const isLight = color && getIsColorLight(color);

            return (
              <Popover key={str + index}>
                <Popover.Trigger>
                  <span
                    className={twMerge(
                      'mr-1 rounded-sm px-1 py-0.5',
                      color && !isLight && 'text-white',
                      isWordStart && 'mr-0',
                    )}
                    style={color ? { backgroundColor: color } : undefined}
                  >
                    {`${str} `}{' '}
                  </span>
                </Popover.Trigger>
                <Popover.Content>{JSON.stringify(data)}</Popover.Content>
              </Popover>
            );
          })}
        </div>
      )}

      <div className="flex h-full flex-col gap-2">
        <JsonEditor className="h-full [&>div]:p-0" readOnly value={data} />
      </div>
    </div>
  );
};
