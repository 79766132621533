import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any
export const useCbRef = <T extends any>(cb: T) => {
  const ref = useRef(cb);

  useEffect(() => {
    ref.current = cb;
  }, [cb]);

  return ref;
};
