import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { UpdateUserParams, User } from './types';

export const useUpdateUserMutation = (options: UseMutationOptions<User, Error, UpdateUserParams> = {}) => {
  return useMutation({
    mutationFn: async (user: UpdateUserParams) => {
      const { data } = await axiosInstance.put<User>(`/users/${user._id}`, user);

      return data;
    },
    ...options,
  });
};
