import type { JSONEditorOptions } from 'jsoneditor';

import { useEffect, useRef, useState } from 'react';

import Editor from 'jsoneditor';
import { twMerge } from 'tailwind-merge';

import { useCbRef } from 'shared/hooks/useCbRef';

type Props = {
  className?: string;
  initialJson?: Record<string, unknown>;
  readOnly?: boolean;
  value?: Record<string, unknown> | unknown[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & JSONEditorOptions;

export const JsonEditor = ({ className, initialJson: initialJsonValue, readOnly, value, ...rest }: Props) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const options = useCbRef(rest);
  const initialJson = useCbRef(initialJsonValue);
  const [jsonEditor, setJsonEditor] = useState<Editor>();

  // TODO: fix this
  useEffect(() => {
    jsonEditor?.set(value);
  }, [value, jsonEditor]);

  useEffect(() => {
    if (!editorRef.current) return;

    const editor = new Editor(
      editorRef.current,
      {
        history: false,
        mainMenuBar: false,
        mode: 'code',
        statusBar: false,
        ...(options.current || {}),
      },
      initialJson.current,
    );

    if (readOnly) {
      editor.aceEditor.setReadOnly(true);
    }

    // editor.set(initialJson.current || {});

    setJsonEditor(editor);

    return () => {
      editor.destroy();
    };
  }, [initialJson, options, readOnly]);

  return (
    <div
      className={twMerge('size-full overflow-hidden font-fira scrollbar-none', className)}
      ref={editorRef}
    ></div>
  );
};
