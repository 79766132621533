import type { HTMLAttributeAnchorTarget } from 'react';

import type { IconName } from 'shared/ui/Icon';

import { useUser } from 'app/stores/user';
import { ExternalLink } from 'app/ui/ExternalLink';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Tooltip } from 'shared/ui/Tooltip';

import { GalleryLogo } from '../GalleryLogo';
import { UserLink } from '../UserLink';
import { MyModel } from './ui/MyModel';
import { Search } from './ui/Search';

type Props = {
  onOpenSidebar: () => void;
};

const LINKS: { icon: IconName; label: string; target?: HTMLAttributeAnchorTarget; to: string }[] = [
  { icon: 'book', label: 'Docs', target: '_blank', to: 'https://docs.nesa.ai/nesa' },
  { icon: 'link', label: 'Faucet', to: '/faucet' },
  { icon: 'compassCircle', label: 'Explorer', target: '_blank', to: 'https://explorer-test.nesa.ai/' },
];

export const GalleryHeader = ({ onOpenSidebar }: Props) => {
  const sm = useMinWidthMediaQuery('sm');
  const { user } = useUser();

  return (
    <>
      <header className="z-20 flex min-h-9 flex-row justify-between gap-2 px-4 sm:flex-col md:flex-row">
        {!sm ? (
          <>
            <div className="flex h-9 items-center gap-4">
              <div
                className="size-9 cursor-pointer rounded-lg bg-clay-20 p-2 transition-colors hover:bg-clay-40"
                onClick={onOpenSidebar}
              >
                <Icon className="size-3.5 text-clay-350" name="text" />
              </div>
              <GalleryLogo className="ml-1" isBlack />
            </div>

            <div className="flex h-9 items-center gap-2">
              <ExternalLink
                className="flex h-full items-center gap-2 rounded-lg bg-clay-20 px-2 py-1.5 font-medium text-clay-900 hover:text-clay-800"
                to="/wallet"
              >
                <Icon className="size-3.5 text-clay-900" name="wallet" />
                Wallet
              </ExternalLink>

              <div className="size-9 cursor-not-allowed rounded-lg bg-clay-20 p-2 hover:bg-clay-20">
                <Icon className="size-3.5 text-clay-900" name="search" />
              </div>

              {!!user && <UserLink tooltipText="Account" />}
            </div>
          </>
        ) : (
          <>
            <MyModel />
            <Search className="w-full md:w-1/3" />

            <div className="relative flex h-10 items-center gap-2">
              {LINKS.map(({ icon, label, target, to }) => {
                return (
                  <Tooltip content={label} key={to} side="bottom">
                    <div className="h-full">
                      <ExternalLink
                        className="flex h-full items-center gap-2 rounded-lg px-2 py-1.5 font-medium text-clay-800 transition-colors hover:bg-clay-20 hover:text-clay-800 "
                        key={to}
                        target={target}
                        to={to}
                      >
                        <Icon className="size-4 text-clay-400" name={icon} />
                      </ExternalLink>
                    </div>
                  </Tooltip>
                );
              })}

              <Button className="pl-3 pr-4" color="secondary" size="small">
                <Icon className="size-3.5 text-clay-400" name="wallet" />
                Wallet
              </Button>
            </div>
          </>
        )}
      </header>
    </>
  );
};
