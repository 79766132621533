import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import type { PeriodType } from 'pages/Wallet/types';

import LogoWhiteIcon from 'app/assets/images/logo-white.svg?react';
import { BackButton } from 'app/ui/BackButton';
import { chartTabs } from 'pages/Wallet/config';
import { Chart } from 'pages/Wallet/ui/Chart';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';

import mockSrc from './assets/mock.png';
const isLoading = false;
const hasChatData = false;

export const NodePage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [period, setPeriod] = useState<PeriodType>('day');

  return (
    <AnimateRoute className="mx-auto max-w-7xl px-4 py-10">
      <BackButton className="mb-2" onClick={() => navigate(-1)}>
        Go back
      </BackButton>
      <h1 className="mb-6 text-2xl font-light">
        Node <b>{id}</b>
      </h1>

      <Card className={twMerge('mb-4 p-0 xs:p-0')}>
        <header className="flex flex-col justify-between gap-3 border-b border-corduroy-200 p-3 sm:flex-row sm:px-5 sm:py-4">
          <div className="flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-3">
            <div className="flex flex-col border-r border-transparent pr-4 text-lg font-medium text-corduroy-900 sm:border-corduroy-200">
              <span className="text-sm font-light text-corduroy-500">Nickname</span>
              <span>
                Node <b>{id}</b>
              </span>
            </div>
            {/* <span className="hidden text-corduroy-200 sm:inline-block">| </span> */}
            <div className="flex flex-col pl-0 text-sm font-light text-corduroy-500 sm:pl-2">
              <span className="text-sm font-light text-corduroy-500">Wallet Address</span>
              <span className="text-lg font-normal text-black">4275a212-4059-4b1d-b5ea-7ef4dc35e365</span>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <div className="flex h-7 items-center gap-1 rounded-full border border-corduroy-200 bg-white px-2 py-1 text-sm/none text-corduroy-800">
              <Icon className="size-4" name="globeDigital" />
              <span className="text-sm/none">United States</span>
            </div>
            <div className="flex h-7 items-center gap-1 rounded-full bg-green-800  py-1 pl-1 pr-2 text-sm/none text-white">
              <div className="flex size-5 items-center justify-center rounded-full bg-white p-0">
                <Icon className="size-full text-green-800" name="check" />
              </div>
              <span className="text-sm/none">Online</span>
            </div>
          </div>
        </header>

        <div className="flex flex-col gap-4 p-3 sm:flex-row">
          <div className="flex w-full flex-1 flex-col gap-6 sm:flex-row sm:gap-4">
            <div className="flex justify-center">
              <img
                alt="kernel"
                className="size-40 overflow-hidden rounded-xl sm:size-40 sm:min-w-40"
                src={mockSrc}
              />
            </div>

            <div className="flex flex-1 flex-col gap-2 sm:gap-4 sm:border-b-0 sm:border-r sm:border-corduroy-200 sm:pr-4 ">
              <p className="text-base font-light text-corduroy-600">
                This node is a top performing network resource, rated in the top 1% of performance on the
                inference network.
              </p>

              <div className="flex gap-2">
                <div className="flex w-fit items-center rounded-full border border-corduroy-200 px-4">
                  <div className="flex items-center gap-1 border-r border-corduroy-200 py-2 pr-2">
                    <Icon className="size-6 text-primary-800" name="layerGroup" />
                    <span className="text-lg font-bold md:text-xl">{'-'}</span>
                  </div>

                  <div className="pl-2 text-sm font-light md:text-base">Blocks</div>
                </div>
                <div className="flex w-fit items-center rounded-full border border-corduroy-200 px-4">
                  <div className="flex items-center gap-1 border-r border-corduroy-200 py-2 pr-2">
                    <span className="text-lg font-bold md:text-xl">{'-'}</span>
                  </div>

                  <div className="pl-2 text-sm font-light md:text-base">
                    <Icon className="size-8 text-yellow-500" name="crown" />
                  </div>
                </div>
              </div>

              <div className="text-lg font-light">
                <b>53/s</b> Tokens
              </div>
            </div>
          </div>

          <div className="flex min-w-fit flex-row items-center gap-2 sm:flex-col sm:justify-center sm:px-4">
            <div className="flex size-9 items-center justify-center rounded-full bg-primary-800 p-1.5">
              <LogoWhiteIcon />
            </div>
            <div className="flex flex-col gap-0.5">
              <div className="text-xl font-bold sm:text-center">{0} NES</div>
              <div className="text-sm/none font-light text-corduroy-500 sm:text-center">
                Earned by node to date
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card className="mb-4">
        <div className="flex flex-wrap items-center gap-3">
          <h2 className="text-lg font-normal">Requests served</h2>

          <div className="flex gap-1.5">
            {chartTabs.map((tab) => (
              <button
                aria-selected={tab.value === period}
                className="h-7 w-11 rounded-full bg-corduroy-100 text-center text-sm font-medium leading-7 text-corduroy-800 transition-colors aria-selected:bg-primary-800 aria-selected:text-white hover:bg-primary-100 hover:text-corduroy-800 aria-selected:hover:bg-primary-1000 aria-selected:hover:text-white xs:w-12"
                key={tab.value}
                onClick={() => setPeriod(tab.value)}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-[3fr_1fr]">
          <div className="relative -ml-1 -mr-2 h-60">
            {(isLoading || !hasChatData) && (
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-corduroy-100 px-5 py-2 text-sm font-semibold text-corduroy-500">
                {isLoading ? <Spinner className="size-5 text-primary-1000" /> : 'No Data'}
              </div>
            )}
            <Chart data={[]} isEmptyData={!hasChatData} periodType={period} />
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-4 rounded-xl border border-corduroy-200 px-4 py-3">
              <div className="flex size-11 items-center justify-center rounded-lg bg-primary-40 p-1.5">
                <Icon className="size-full text-primary-800" name="cloudCheck" />
              </div>
              <div className="flex flex-col">
                <div className="text-xl text-primary-800">
                  {isLoading ? <Spinner className="mb-2 size-5" /> : '-'}
                </div>
                <div className="text-base">Total requests served</div>
                <div className="text-sm font-light text-corduroy-500">Last {period}</div>
              </div>
            </div>
            <div className="flex items-center gap-4 rounded-xl border border-corduroy-200 px-4 py-3">
              <div className="flex size-11 items-center justify-center rounded-lg bg-primary-40 p-1.5">
                <Icon className="size-full text-primary-800" name="chartBar" />
              </div>
              <div className="flex flex-col">
                <div className="text-xl text-primary-800">-</div>
                <div className="text-base">Avg request served per day</div>
                <div className="text-sm font-light text-corduroy-500">Last {period}</div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <Card className="flex flex-col">
          <h2 className="text-lg font-normal">Model it mostly served</h2>

          <div className="flex flex-1 flex-col gap-4">
            <div className="flex h-full flex-1 items-center justify-center py-10 text-center text-base font-light text-corduroy-500">
              No data
            </div>
          </div>
        </Card>

        <div className="grid grid-cols-1 gap-4">
          <Card>
            <h2 className="text-lg font-normal">Specifications</h2>

            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-center py-10 text-center text-base font-light text-corduroy-500">
                No data
              </div>
            </div>
          </Card>
          <Card>
            <h2 className="text-lg font-normal">Penalties</h2>

            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-center py-10 text-center text-base font-light text-corduroy-500">
                No data
              </div>
            </div>
          </Card>
        </div>
      </div>
    </AnimateRoute>
  );
};
