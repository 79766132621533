import { Navigate, Outlet } from 'react-router-dom';

import { GrazProvider } from 'graz';

import { useUser } from 'app/stores/user';
import { GalleryLayout as Layout } from 'app/ui/GalleryLayout';
// import { Layout } from 'app/ui/Layout';
import { Loading } from 'app/ui/Loading';
import { nesaTestnet } from 'shared/config/networks/nesaTestnet';

interface Props {
  redirectRoute?: string;
}

export const AuthGuard = ({ redirectRoute = '/preview' }: Props) => {
  const { isPending, user } = useUser();

  if (user) {
    return (
      <GrazProvider
        grazOptions={{
          autoReconnect: false,
          chains: [nesaTestnet],
        }}
      >
        <Layout>
          <Outlet context={{ user }} />
        </Layout>
      </GrazProvider>
    );
  }

  if (isPending) {
    return <Loading />;
  }

  return <Navigate replace to={redirectRoute} />;
};
