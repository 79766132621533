import type { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

export const LayoutWrapper = (props: ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={twMerge('mx-auto max-w-[90rem] px-3 xl:px-24 2xl:box-content 2xl:px-44', props.className)}
    >
      {props.children}
    </div>
  );
};
