import axios from 'axios';
import crypto from 'crypto';
import { split } from 'shamirs-secret-sharing';

import { useEvent } from './useEvent';

const getServerUrlByName = (name: string) => `https://dkms-${name.toLocaleLowerCase()}-test.nesa.ai`;
const DKMS_SECRETS_ENDPOINT = import.meta.env.VITE_DKMS_SECRETS_ENDPOINT;
const DKMS_SERVER_NAMES = ['A', 'B', 'C'];
const NUM_SHARES = DKMS_SERVER_NAMES.length;

export const useMessageEncryption = () => {
  const encrypt = useEvent(
    (query: string, key: Buffer, iv: Buffer): { authTag: string; encrypted: string } => {
      const cipher = crypto.createCipheriv('aes-256-gcm', key, iv);
      let encrypted = cipher.update(query, 'utf8', 'hex');
      encrypted += cipher.final('hex');

      const authTag = cipher.getAuthTag().toString('hex');
      return { authTag, encrypted };
    },
  );

  const decrypt = useEvent((encryptedQuery: string, key: Buffer, iv: Buffer, authTag: string): string => {
    const decipher = crypto.createDecipheriv('aes-256-gcm', key, iv);
    decipher.setAuthTag(Buffer.from(authTag, 'hex'));
    let decrypted = decipher.update(encryptedQuery, 'hex', 'utf8');
    decrypted += decipher.final('utf8');

    return decrypted;
  });

  const storeShares = useEvent(async ({ shares, userId }: { shares: string[]; userId?: string }) => {
    if (shares.length !== NUM_SHARES) {
      throw new Error('Invalid # of shares');
    }

    const promises = DKMS_SERVER_NAMES.map((serverName, index) => {
      return axios.post<{ keyId: string }>(
        `${getServerUrlByName(serverName)}${DKMS_SECRETS_ENDPOINT}`,
        JSON.stringify({ share: shares[index], userId }),
        { headers: { 'content-type': 'application/json' } },
      );
    });

    const results = await Promise.all(promises);

    return DKMS_SERVER_NAMES.reduce<Record<string, string>>((acc, serverName, i) => {
      acc[serverName] = results[i].data?.keyId;

      return acc;
    }, {});
  });

  const retrieveShares = useEvent(async ({ keyIdInfo }: { keyIdInfo: Record<string, string> }) => {
    const serverNames = Object.keys(keyIdInfo);

    if (serverNames.length !== NUM_SHARES) {
      throw new Error('Invalid # of keyIdInfos');
    }

    const promises = serverNames.map((serverName) => {
      return axios.get<{
        keyId: string;
        share: string;
        userId: string;
      }>(`${getServerUrlByName(serverName)}${DKMS_SECRETS_ENDPOINT}/${keyIdInfo[serverName]}`);
    });

    const results = await Promise.all(promises);
    const resultData = results.map(({ data }) => data);

    return resultData;
  });

  const encryptQuery = useEvent(
    async ({ content, context, userId }: { content: string; context: string; userId: string }) => {
      const isProd = import.meta.env.MODE === 'production';

      if (!isProd) return {};

      // Step 1: Generate symmetric key and IV
      const key = crypto.randomBytes(32); // 256-bit key
      const contentIv = crypto.randomBytes(12); // 96-bit IV for GCM
      const contextIv = context ? crypto.randomBytes(12) : Buffer.from('', 'hex'); // 96-bit IV for GCM

      // Step 2: Encrypt Content/Context
      const contentEncrypted = encrypt(content, key, contentIv);
      const contextEncrypted = context ? encrypt(context, key, contextIv) : '';

      // Step 3: Split encryption key into shares
      const keyShares = split(key, {
        shares: NUM_SHARES,
        threshold: Math.floor(NUM_SHARES / 2) + 1,
      });

      console.log(`query: content/${content}, context/${context}`);
      // console.log(`key: ${key.toString('hex')}`);
      console.log(`contentEncrypted: ${JSON.stringify(contentEncrypted)}`);
      console.log(`contextEncrypted: ${JSON.stringify(contextEncrypted)}`);
      console.log(`contentIv: ${contentIv.toString('hex')}`);
      console.log(`contextIv: ${contextIv.toString('hex')}`);

      // Step 4: Store shares
      const storeResults = await storeShares({
        shares: keyShares.map((k) => k.toString('hex')),
        userId,
      });

      const encryption = {
        contentIv: contentIv.toString('hex'),
        contextIv: context ? contextIv.toString('hex') : '',
        keyShares: storeResults,
      };

      return { contentEncrypted, contextEncrypted, encryption };
    },
  );

  const encryptMessage = useEvent(async ({ message, userId }: { message: string; userId: string }) => {
    const isProd = import.meta.env.MODE === 'production';

    if (!isProd) return {};

    // Step 1: Generate symmetric key and IV
    const key = crypto.randomBytes(32); // 256-bit key
    const messageIv = crypto.randomBytes(12); // 96-bit IV for GCM

    // Step 2: Encrypt Message
    const messageEncrypted = encrypt(message, key, messageIv);

    // Step 3: Split encryption key into shares
    const keyShares = split(key, {
      shares: NUM_SHARES,
      threshold: Math.floor(NUM_SHARES / 2) + 1,
    }) as Buffer[];

    console.log(`query: message/${message}`);
    // console.log(`key: ${key.toString("hex")}`);
    console.log(`messageEncrypted: ${JSON.stringify(messageEncrypted)}`);
    console.log(`messageIv: ${messageIv.toString('hex')}`);

    // Step 4: Store shares
    const storeResults = await storeShares({
      shares: keyShares.map((k: Buffer) => k.toString('hex')),
      userId,
    });

    const encryptionParams = {
      keyShares: storeResults,
      messageIv: messageIv.toString('hex'),
    };

    return { encryptionParams, messageEncrypted };
  });

  return { decrypt, encrypt, encryptMessage, encryptQuery, retrieveShares, storeShares };
};
