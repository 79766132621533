import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { ReviewSummary } from './types';

import { reviewsKeys } from './queryKeys';

export const useGetReviewSummaryQuery = (
  { modelId }: { modelId: string },
  options: { enabled?: boolean } = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const res = await axiosInstance.get<ReviewSummary>(`reviews/models/${modelId}/summary`, {
        params: { modelId },
        signal,
      });

      return res.data;
    },
    queryKey: reviewsKeys.list({ modelId }),
    ...(options || {}),
  });
};
