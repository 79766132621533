import type { ComponentProps } from 'react';
import { useId } from 'react';

import { twMerge } from 'tailwind-merge';

import { theme } from 'app/theme';

type StarProps = {
  color?: string;
  percent: number;
} & ComponentProps<'svg'>;

export const Star = ({
  color = theme.colors.yellow[500],
  percent,
  ...props
}: ComponentProps<'svg'> & StarProps) => {
  const id = useId();
  return (
    <svg height={20} width={20} {...props} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={id}>
          <stop offset={`${percent}%`} stopColor={color} />
          <stop offset={`${percent}%`} stopColor="transparent" />
        </linearGradient>
      </defs>
      <path
        d="M9.61527 1.55724C9.73643 1.16842 10.264 1.16842 10.3852 1.55724L12.1812 7.32078C12.2353 7.49467 12.3908 7.6124 12.5661 7.6124H18.3781C18.7702 7.6124 18.9332 8.13556 18.616 8.37587L13.914 11.9379C13.7721 12.0454 13.7128 12.2359 13.767 12.4098L15.563 18.1733C15.6841 18.5621 15.2573 18.8855 14.9401 18.6452L10.2381 15.0831C10.0963 14.9756 9.90416 14.9756 9.7623 15.0831L5.0603 18.6452C4.74309 18.8855 4.31629 18.5621 4.43745 18.1733L6.23345 12.4098C6.28764 12.2359 6.22828 12.0454 6.08642 11.9379L1.38442 8.37586C1.06721 8.13556 1.23023 7.6124 1.62233 7.6124H7.43432C7.60967 7.6124 7.76507 7.49467 7.81926 7.32078L9.61527 1.55724Z"
        fill={`url(#${id})`}
      />
      <path
        clipRule="evenodd"
        d="M7.81905 7.32126C7.76487 7.49515 7.60946 7.61288 7.43411 7.61288H1.62212C1.23002 7.61288 1.067 8.13604 1.38421 8.37634L6.08621 11.9384C6.22807 12.0459 6.28743 12.2364 6.23325 12.4103L4.43724 18.1738C4.31608 18.5626 4.74288 18.8859 5.06009 18.6456L9.76209 15.0836C9.90395 14.9761 10.096 14.9761 10.2379 15.0836L14.9399 18.6456C15.2571 18.8859 15.6839 18.5626 15.5628 18.1738L13.7668 12.4103C13.7126 12.2364 13.7719 12.0459 13.9138 11.9384L18.6158 8.37635C18.933 8.13604 18.77 7.61288 18.3779 7.61288H12.5659C12.3905 7.61288 12.2351 7.49515 12.1809 7.32126L10.3849 1.55772C10.2638 1.1689 9.73622 1.1689 9.61506 1.55772L7.81905 7.32126ZM13.154 6.34677L11.5398 1.16648C11.0551 -0.388822 8.94488 -0.388829 8.46023 1.16648L6.84597 6.34677H1.62212C0.0537485 6.34677 -0.598363 8.43941 0.670485 9.40064L4.89667 12.6022L3.28241 17.7825C2.79776 19.3378 4.50497 20.6312 5.77382 19.6699L10 16.4683L14.2262 19.6699C15.495 20.6312 17.2022 19.3378 16.7176 17.7825L15.1033 12.6022L19.3295 9.40065C20.5984 8.43942 19.9463 6.34677 18.3779 6.34677H13.154Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

type StarRatingProps = {
  className?: string;
  containerClassName?: string;
  rating: number;
  starColor?: string;
  starsCount?: number;
  svgProps?: ComponentProps<'svg'>;
};

export const StarRating = ({
  className,
  containerClassName,
  rating,
  starColor,
  starsCount = 5,
  svgProps,
}: StarRatingProps) => {
  return (
    <div className={twMerge('flex', className)}>
      {new Array(starsCount).fill(null).map((_, i) => {
        let percent = 0;

        const starCount = i + 1;
        if (starCount <= rating) percent = 100;
        if (starCount > rating && starCount - rating < 1) {
          percent = Math.round((rating % 1) * 100);
        }
        return (
          <div className={twMerge('flex size-6 items-center justify-center', containerClassName)} key={i}>
            <Star {...svgProps} color={starColor} percent={percent} />
          </div>
        );
      })}
    </div>
  );
};
