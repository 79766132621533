const coordinates = [
  ['Fontaine-sur-Bois', 48.85, 2.48],
  ['Frejus', 43.43, 6.74],
  ['Chalons-en-Champagne', 48.95, 4.37],
  ['Chambery', 45.56, 5.93],
  ['Champigny-sur-Marne', 48.82, 2.49],
  ['Charleville-Mezières', 49.77, 4.72],
  ['To the tent', 46.81, 1.69],
  ['Cholet', 47.07, -0.88],
  ['Noisy-le-Grand', 48.85, 2.56],
  ['Euro', 49.02, 1.15],
  ['Aix-en-Provence', 43.53, 5.45],
  ['Pessakes', 44.81, -0.64],
  ['Poitiers', 46.58, 0.33],
  ['Reims', 49.25, 4.03],
  ['Rennes', 48.11, -1.67],
  ['Rouen', 49.44, 1.1],
  ['Rube', 50.69, 3.17],
  ['Rueil-Malmaison', 48.88, 2.19],
  ['Sarcelles', 49, 2.38],
  ['Saint-Denis', 48.93, 2.37],
  ['Saint-Malo', 48.65, -2.02],
  ['Saint-Maur-des-Fossés', 48.79, 2.49],
  ['Nanterre', 48.89, 2.21],
  ['Narbonne', 43.18, 3],
  ['Neuilly-sur-Seine', 48.88, 2.27],
  ['Nim', 43.83, 4.36],
  ['Nice', 43.7, 7.27],
  ['Noir', 46.32, -0.46],
  ['Aubervilliers', 48.92, 2.38],
  ['They-are-Bua', 48.94, 2.49],
  ['Orleans', 47.9, 1.9],
  ['Pantin', 48.89, 2.41],
  ['Pow', 43.3, -0.37],
  ['Perpignan', 42.7, 2.9],
  ['Pessakes', 44.81, -0.64],
  ['Poitiers', 46.58, 0.33],
  ['Reims', 49.25, 4.03],
  ['Rennes', 48.11, -1.67],
  ['Rouen', 49.44, 1.1],
  ['Rube', 50.69, 3.17],
  ['Rueil-Malmaison', 48.88, 2.19],
  ['Sarcelles', 49, 2.38],
  ['Saint-Denis', 48.93, 2.37],
  ['Saint-Malo', 48.65, -2.02],
  ['Saint-Maur-des-Fossés', 48.79, 2.49],
  ['Saint-Nazaire', 47.28, -2.2],
  ['Saint-Brieuc', 48.52, -2.77],
  ['Saint-Etienne', 45.43, 4.39],
  ['Sergi', 49.04, 2.08],
  ['Strasbourg', 48.58, 7.75],
  ['Tarb', 43.23, 0.08],
  ['Poison', 48.3, 4.09],
  ['Toulon', 43.12, 5.93],
  ['Toulouse', 43.6, 1.44],
  ['Stand up', 47.39, 0.7],
  ['Turkuen', 50.72, 3.16],
  ['Fontaine-sur-Bois', 48.85, 2.48],
  ['Frejus', 43.43, 6.74],
  ['Chalons-en-Champagne', 48.95, 4.37],
  ['Chambery', 45.56, 5.93],
  ['Champigny-sur-Marne', 48.82, 2.49],
  ['Charleville-Mezières', 49.77, 4.72],
  ['To the tent', 46.81, 1.69],
  ['Cholet', 47.07, -0.88],
  ['Noisy-le-Grand', 48.85, 2.56],
  ['Euro', 49.02, 1.15],
  ['Courbevois', 48.9, 2.26],
  ['La Roche-sur-Yon', 46.67, -1.43],
  ['La Rochelle', 46.17, -1.15],
  ['La Seine-sur-Mer', 43.1, 5.88],
  ['Laval', 48.07, -0.76],
  ['Le Mans', 48, 0.2],
  ['Levallois-Perret', 48.89, 2.29],
  ['Lille', 50.63, 3.06],
  ['Limoges', 45.83, 1.26],
  ['Lyon', 45.75, 4.85],
  ['Lorient', 47.75, -3.37],
  ['Blood', 43.55, 7.01],
  ['Camper', 48, -4.1],
  ['Stapler', 48.8, 2.27],
  ['Clermont-Ferrand', 45.78, 3.09],
  ['Paris', 48.85, 2.35],
  ['Avignon', 43.95, 4.81],
  ['Albie', 43.93, 2.15],
  ['Amiens', 49.9, 2.3],
  ['Ange', 47.47, -0.55],
  ['Antibes', 43.56, 7.13],
  ['Aniers-sur-Seine', 48.92, 2.28],
  ['Argenteuil', 48.95, 2.25],
  ['Arles', 43.68, 4.63],
  ['Ajaccio', 41.92, 8.74],
  ['Bezanson', 47.25, 6.02],
  ['Bezier', 43.34, 3.21],
  ['Belfort', 47.64, 6.85],
  ['Blue', 47.59, 1.33],
  ['Beauvais', 49.43, 2.08],
  ['Bordeaux', 44.84, -0.58],
  ['Brest', 48.39, -4.49],
  ['Brive-la-Gaillarde', 45.15, 1.53],
  ['Boulogne-Billancourt', 48.83, 2.25],
  ['Burj', 47.08, 2.4],
  ['Berlin', 52.52, 13.41],
  ['Alain', 51.76, 7.89],
  ['Alain', 48.84, 10.09],
  ['Arnsberg', 51.38, 8.08],
  ['Augsburg', 48.37, 10.9],
  ['Aachen', 50.78, 6.08],
  ['Aschaffenburg', 49.98, 9.15],
  ['Bad Salzuflen', 52.09, 8.74],
  ['Bad Homburg', 50.23, 8.62],
  ['Baden Baden', 48.76, 8.24],
  ['Bayreuth', 49.95, 11.58],
  ['Bamberg', 49.9, 10.9],
  ['Bergem', 50.96, 6.64],
  ['Bergisch Gladbach', 50.99, 7.13],
  ['Bergkamen', 51.62, 7.64],
  ['Bielefeld', 52.03, 8.53],
  ['Bremerhaven', 53.55, 8.58],
  ['Waiblingen', 48.83, 9.32],
  ['Wesel', 51.67, 6.62],
  ['Weimar', 50.98, 11.33],
  ['Wetzlar', 50.56, 8.5],
  ['Willich', 51.26, 6.55],
  ['Wilhelmshaven', 53.53, 8.11],
  ['Wiesbaden', 50.08, 8.25],
  ['Witten', 51.44, 7.35],
  ['Frankfurt am Main', 50.12, 8.68],
  ['Frankfurt an der Oder', 52.35, 14.55],
  ['Friedrichshafen', 47.66, 9.48],
  ['Fulda', 50.55, 9.68],
  ['Furth', 49.48, 10.99],
  ['Hagen', 51.36, 7.47],
  ['Heidelberg', 49.41, 8.69],
  ['Heidenheim an der Brenz', 48.68, 10.15],
  ['Hildesheim', 52.15, 9.95],
  ['Hurth', 50.87, 6.87],
  ['Zwickau', 50.73, 12.49],
  ['Celle', 52.62, 10.08],
  ['Schweinfurt', 50.05, 10.22],
  ['Schwäbisch Gmünd', 48.8, 9.8],
  ['Schwerin', 53.63, 11.41],
  ['Schwerte', 51.44, 7.57],
  ['Speyer', 49.32, 8.43],
  ['Mülheim an der Ruhr', 51.43, 6.88],
  ['Munster', 51.96, 7.63],
  ['Munich', 48.14, 11.58],
  ['Neu-Ulm', 48.39, 10.01],
  ['Neubrandenburg', 53.56, 13.28],
  ['Neuwied', 50.43, 7.47],
  ['Neumunster', 54.07, 9.98],
  ['Noyce', 51.2, 6.69],
  ['Neustadt an der Weinstrasse', 49.35, 8.14],
  ['Norderstedt', 53.69, 9.98],
  ['Nordhorn', 52.43, 7.07],
  ['Nuremberg', 49.45, 11.08],
  ['Oberhausen', 51.48, 6.86],
  ['Euskirchen', 50.66, 6.79],
  ['Oldenburg', 53.14, 8.21],
  ['Osnabrück', 52.27, 8.05],
  ['Offenburg', 48.47, 7.94],
  ['Paderborn', 51.72, 8.75],
  ['Passau', 48.57, 13.43],
  ['Dormagen', 51.1, 6.83],
  ['Dorsten', 51.66, 6.97],
  ['Dortmund', 51.51, 7.47],
  ['Dresden', 51.05, 13.74],
  ['Duisburg', 51.43, 6.77],
  ['Duren', 50.8, 6.49],
  ['Rome', 41.89, 12.51],
  ['Obverse', 40.97, 14.21],
  ['Alessandria', 44.91, 8.61],
  ['Altamura', 40.83, 16.55],
  ['Andria', 41.23, 16.3],
  ['Ancona', 43.59, 13.5],
  ['Arezzo', 43.46, 11.88],
  ['Asti', 44.9, 8.21],
  ['Afragola', 40.92, 14.31],
  ['Bagheria', 38.08, 13.51],
  ['Bari', 41.12, 16.87],
  ['Barletta', 41.31, 16.28],
  ['Bergamo', 45.7, 9.67],
  ['Bisceglie', 41.24, 16.5],
  ['Reggio Calabria', 38.11, 15.66],
  ['Reggio Emilia', 44.7, 10.63],
  ['Rimini', 44.06, 12.57],
  ['Savona', 44.31, 8.48],
  ['Salerno', 40.68, 14.79],
  ['Madrid', 40.42, -3.7],
  ['Avila', 40.66, -4.7],
  ['Aviles', 43.55, -5.92],
  ['Alicante', 38.35, -0.48],
  ['Albacete', 38.99, -1.86],
  ['Benidorm', 38.54, -0.13],
  ['Bilbao', 43.26, -2.93],
  ['Burgos', 42.34, -3.7],
  ['Valencia', 39.47, -0.38],
  ['Valdemoro', 40.19, -3.68],
  ['Valladolid', 41.66, -4.72],
  ['Torremolinos', 36.62, -4.5],
  ['Torrejon de Ardoz', 40.46, -3.47],
  ['Utrera', 37.19, -5.78],
  ['Huelva', 37.27, -6.94],
  ['Amsterdam', 52.37, 4.89],
  ['Andorra la Vella', 42.51, 1.52],
  ['Athens', 37.98, 23.73],
  ['Belgrade', 44.8, 20.47],
  ['Berlin', 52.52, 13.41],
  ['Berne', 46.95, 7.45],
  ['Bratislava', 48.15, 17.11],
  ['Brussels', 50.85, 4.35],
  ['Budapest', 47.5, 19.04],
  ['Bucharest', 44.43, 26.11],
  ['Vaduz', 47.14, 9.52],
  ['Valletta', 35.9, 14.51],
  ['Warsaw', 52.23, 21.01],
  ['Vatican', 41.9, 12.45],
  ['Vein', 48.21, 16.37],
  ['Vilnius', 54.69, 25.28],
  ['Dublin', 53.33, -6.25],
  ['Zagreb', 45.81, 15.98],
  ['Kyiv', 50.45, 30.52],
  ['Kishinev', 47.01, 28.86],
  ['Copenhagen', 55.68, 12.57],
  ['Lisbon', 38.72, -9.13],
  ['London', 51.51, -0.13],
  ['Ljubljana', 46.05, 14.51],
  ['Luxembourg', 49.61, 6.13],
  ['Madrid', 40.42, -3.7],
  ['Minsk', 53.9, 27.57],
  ['Monaco', 43.73, 7.42],
  // ['Moscow', 55.75, 37.62],
  ['Nicosia', 35.18, 33.36],
  ['Oslo', 59.91, 10.75],
  ['Paris', 48.85, 2.35],
  ['Podgorica', 42.44, 19.26],
  ['Prague', 50.09, 14.42],
  ['Pristina', 42.67, 21.17],
  ['Reykjavik', 64.14, -21.9],
  ['Riga', 56.95, 24.11],
  ['Rome', 41.89, 12.51],
  ['San Marino', 43.94, 12.45],
  ['Sarajevo', 43.85, 18.36],
  ['Skopje', 42, 21.43],
  ['Sofia', 42.7, 23.32],
  ['Stockholm', 59.33, 18.06],
  ['Tallinn', 59.44, 24.75],
  ['Tirana', 41.33, 19.82],
  ['Helsinki', 60.17, 24.94],
  ['Washington', 38.9, -77.04],
  ['Abilene', 32.45, -99.73],
  ['Aurora', 41.76, -88.32],
  ['Idaho Falls', 43.47, -112.03],
  ['Iowa City', 41.66, -91.53],
  ['Akron', 41.08, -81.52],
  ['Alameda', 37.77, -122.24],
  ['Alexandria', 38.8, -77.05],
  ['Allen', 33.1, -96.67],
  ['Concord', 37.98, -122.03],
  ['Concord', 43.21, -71.54],
  ['Concord', 35.41, -80.58],
  ['Conway', 35.09, -92.44],
  ['Coral Springs', 26.27, -80.27],
  ['Corvallis', 44.56, -123.26],
  ['Crown', 33.88, -117.57],
  ['Corpus Christi', 27.8, -97.4],
  ['Costa Mesa', 33.64, -117.92],
  ['Cranston', 41.78, -71.44],
  ['Quincy', 42.25, -71],
  ['Coon Rapids', 45.12, -93.29],
  ['Cupertino', 37.32, -122.03],
  ['Rockville', 39.08, -77.15],
  ['Rocky Mount', 35.94, -77.79],
  ['Rocklin', 38.79, -121.24],
  ['Rockford', 42.27, -89.09],
  ['Roles', 35.77, -78.64],
  ['Rochester', 44.02, -92.47],
  ['Rochester', 43.15, -77.62],

  ['San Clemente', 33.43, -117.61],
  ['San Marcos', 33.14, -117.17],
  ['San Marcos', 29.88, -97.94],
  ['San Mateo', 37.56, -122.33],
  ['San Ramon', 37.78, -121.98],

  ['Tallahassee', 30.44, -84.28],
  ['Tulsa', 36.15, -95.99],
  ['Tampa', 27.95, -82.46],
  ['Tuscaloosa', 33.21, -87.57],
  ['Thousand Oaks', 34.17, -118.84],
  ['The Villages', 28.93, -81.96],

  ['Troy', 42.61, -83.15],
  ['Tulare', 36.21, -119.35],
  ['Tucson', 32.22, -110.93],
  ['White Plains', 41.03, -73.76],
  ['Wilmington', 39.75, -75.55],
  ['Los Angeles', 34.05, -118.24],
  ['Lawrence', 42.71, -71.16],
  ['Lawrence', 38.97, -95.24],
  ['Lawton', 34.61, -98.39],
  ['Lowell', 42.63, -71.32],
  ['Louisville', 38.25, -85.76],
  ['Lewisville', 33.05, -96.99],

  ['Beijing', 39.91, 116.4],
  ['Aksu', 41.18, 80.28],
  ['Alihe', 50.57, 123.72],
  ['Altai', 47.85, 88.13],
  ['Anda', 46.4, 125.32],
  ['Ancan', 32.68, 109.02],
  ['Anqing', 30.51, 117.05],
  ['Anshan', 41.12, 122.99],
  ['Anshun', 26.25, 105.93],
  ['Anyang', 36.1, 114.38],
  ['Aral', 40.54, 81.27],
  ['Aheng', 45.55, 126.98],
  ['Babu', 24.42, 111.52],
  ['Baiyin', 36.55, 104.17],
  ['Baicheng', 45.61, 122.82],
  ['Baishan', 41.94, 126.42],
  ['Baoding', 38.85, 115.49],
  ['Baotou', 40.65, 109.82],
  ['Baoqing', 46.33, 132.21],
  ['Baoshan', 46.56, 131.42],
  ['Accordion', 46.08, 127.4],
  ['Beidaihe', 39.82, 119.52],
  ['Binzhou', 37.37, 118.02],
  ['Bijie', 27.3, 105.29],
  ['Vanqine', 43.32, 129.76],
  ['Weinan', 34.5, 109.51],
  ['Vainin', 26.85, 104.23],
  ['Weifang', 36.71, 119.1],
  ['Weihai', 37.51, 122.11],
  ['Wenchang', 19.62, 110.75],
  ['Wenzhou', 28, 120.67],
  ['Guangzhou', 23.12, 113.25],
  ['Guangyuan', 32.44, 105.82],
  ['Guigang', 23.1, 109.61],
  ['Guilin', 25.28, 110.29],
  ['Guiyang', 26.58, 106.72],
  ['Guli', 28.88, 120.03],
  ['Gongzhuling', 43.5, 124.82],
  ['Dadonghai', 18.22, 109.51],
  ['Dali', 25.58, 100.21],
  ['Dalyan', 22.84, 113.25],
  ['Dalian', 38.91, 121.6],
  ['Dandong', 40.13, 124.4],
  ['Zhalaynor', 49.45, 117.7],
  ['Zhalantun', 48.01, 122.74],
  ['Zhangjiakou', 40.81, 114.88],
  ['Zhangjiajie', 29.13, 110.48],
  ['Zhangzhou', 24.51, 117.66],
  ['Zhangye', 38.93, 100.45],
  ['Zhanjiang', 21.28, 110.34],
  ['Zhaotong', 27.32, 103.72],
  ['Zhaoqing', 23.05, 112.46],
  ['Zhaozhou', 45.68, 125.32],
  ['Zhoukou', 33.63, 114.63],
  ['Zhoushan', 29.99, 122.21],
  ['Zhumadian', 32.98, 114.03],
  ['Zhongshu', 24.52, 103.77],
  ['Zhuhai', 22.28, 113.57],
  ['Zhujiajiao', 31.11, 121.06],
  ['Zhuzhou', 27.83, 113.15],
  ['Fushun', 41.89, 123.94],
  ['Fuyang', 32.9, 115.82],
  ['Fuyang', 30.05, 119.95],
  ['Haikou', 20.05, 110.34],
  ['Hailar', 49.2, 119.7],
  ['Haylin', 44.57, 129.39],
  ['Hami', 42.83, 93.51],
  ['Hanga', 39.25, 117.79],
  ['Hangzhou', 30.29, 120.16],
  ['Handan', 36.6, 114.47],
  ['Hanzhong', 33.08, 107.02],
  ['Harbin', 45.75, 126.65],
  ['Hede', 33.77, 120.26],
  ['Khotan', 37.11, 79.94],
  ['Huaibei', 33.97, 116.79],
  ['Huaihua', 27.56, 110],
  ['Huanggang', 23.68, 117],
  ['Huangzhou', 30.45, 114.8],
  ['Huangshan', 29.71, 118.31],
  ['Huangshi', 30.25, 115.05],
  ['Hulan', 45.98, 126.6],
  ['Humen', 22.82, 113.67],
  ['Hongqiao', 26.77, 112.11],
  ['Hunchun', 42.87, 130.36],
  ['Hohhot', 40.81, 111.65],
  ['Huizhou', 23.11, 114.42],
  ['Hebi', 35.9, 114.19],
  ['Hagan', 47.35, 130.3],
  ['Heihe', 50.24, 127.49],
  ['Helong', 42.54, 129],
  ['Hengshui', 37.73, 115.7],
  ['Hengyang', 26.89, 112.62],
  ['Hepo', 23.43, 115.83],
  ['Hefei', 31.86, 117.28],
  ['Heze', 35.24, 115.47],
  ['Heyuan', 23.73, 114.68],
  ['Cangzhou', 38.32, 116.87],
  ['Zaozhuang', 34.86, 117.55],
  ['Jiexiu', 37.02, 111.9],

  ['Singapore', 1.29, 103.85],

  ['Tokyo', 35.69, 139.69],
  ['Akashi', 34.66, 135.01],
  ['Akita', 39.72, 140.12],
  ['Ako', 34.75, 134.39],
  ['Anan', 33.92, 134.65],
  ['Anjo', 34.96, 137.08],
  ['Aomori', 40.82, 140.73],
  ['Tokyo', 35.69, 139.69],
  ['Akashi', 34.66, 135.01],
  ['Akita', 39.72, 140.12],
  ['Ako', 34.75, 134.39],
  ['Anan', 33.92, 134.65],
  ['Anjo', 34.96, 137.08],
  ['Aomori', 40.82, 140.73],
  ['Tajimi', 35.32, 137.13],
  ['Takamatsu', 34.33, 134.05],
  ['Takaoka', 36.75, 137.02],
  ['Takarazuka', 34.8, 135.36],
  ['Takasaki', 36.33, 139.02],
  ['Takayama', 36.13, 137.25],
  ['Tamano', 34.52, 133.95],
  ['Tanabe', 33.73, 135.37],
  ['Tatebayashi', 36.25, 139.53],
  ['Tateyama', 34.98, 139.87],
  ['Tendo', 38.35, 140.37],
  ['Nishinomiya', 34.72, 135.33],
  ['Nagasaki', 32.75, 129.88],
  ['Okayama', 34.65, 133.93],
  ['Okinawa', 26.34, 127.8],
  ['Ome', 35.78, 139.24],
  ['Omihachiman', 35.13, 136.1],
  ['Omura', 32.92, 129.95],
  ['Omuta', 33.03, 130.45],
  ['It', 34.85, 134.93],
  ['Onojo', 33.54, 130.48],
  ['Onomichi', 34.42, 133.2],
  ['Osaka', 34.69, 135.5],

  ['Seoul', 37.57, 126.98],
  ['Andon', 36.57, 128.72],
  ['Anson', 37.01, 127.27],
  ['Asan', 36.78, 127],
  ['Wonju', 37.35, 127.95],
  ['Iksan', 35.94, 126.95],
  ['Incheon', 37.46, 126.71],
  ['Yeosu', 34.76, 127.66],
  ['Gangneung', 37.75, 128.87],
  ['Gapyeong', 37.83, 127.51],
  ['Gwangju', 37.41, 127.26],
  ['Gwangju', 35.15, 126.92],
  ['Gyeongju', 35.84, 129.21],
  ['Gimcheon', 36.12, 128.12],
  ['Gongju', 36.46, 127.13],
  ['Kumi', 36.11, 128.34],
  ['Gunsan', 35.98, 126.71],
  ['Osan', 37.15, 127.07],
  ['Busan', 35.1, 129.04],
  ['Sanju', 36.42, 128.16],
  ['Seogwipo', 33.25, 126.56],
  ['Sokcho', 38.21, 128.59],
  ['Seosan', 36.78, 126.45],
  ['Cheonan', 36.81, 127.15],
  ['Chuncheon', 37.87, 127.73],
  ['Yanju', 37.83, 127.06],
  ['Yangsan', 35.34, 129.03],

  ['New Delhi', 28.64, 77.22],
  ['Avadi', 13.11, 80.11],
  ['Agartala', 23.84, 91.28],
  ['Agra', 27.18, 78.02],
  ['Ajmer', 26.45, 74.64],
  ['Adoni', 15.63, 77.27],
  ['Azamgarh', 26.07, 83.18],
  ['Aijal', 23.73, 92.72],
  ['Ayodhya', 26.8, 82.2],
  ['Akola', 20.71, 77],
  ['Asansol', 23.68, 86.98],
  ['Auraiya', 26.47, 79.51],
  ['Aurangabad', 19.88, 75.34],
  ['Ahmedabad', 23.03, 72.59],
  ['Bagalkot', 16.19, 75.7],
  ['Badayun', 28.04, 79.13],
  ['Balasore', 21.49, 86.93],
  ['Bali', 22.65, 88.34],
  ['Bangalore', 12.97, 77.59],
  ['Bangaon', 23.05, 88.83],
  ['Bankura', 23.23, 87.07],
  ['Baramati', 18.15, 74.58],
  ['Ram', 25.1, 76.52],
  ['Bargarh', 21.33, 83.62],
  ['Pudukkottai', 10.38, 78.82],
  ['Pune', 18.52, 73.86],
  ['Puri', 19.8, 85.82],
  ['Purnia', 25.78, 87.47],
  ['Rajahmundry', 17.01, 81.78],
  ['Rajkot', 22.29, 70.79],
  ['Raigarh', 21.9, 83.4],
  ['Raipur', 21.23, 81.63],
  ['Raichur', 16.21, 77.36],
  ['Jaunpur', 25.75, 82.69],
  ['Jodhpur', 26.27, 73.01],
  ['Jorhat', 26.76, 94.2],
  ['Dibrugarh', 27.48, 94.91],
  ['Dimapur', 25.91, 93.72],
  ['Dindukkal', 10.37, 77.98],
  ['Dis', 24.26, 72.18],
  ['Durg', 21.19, 81.28],
  ['Latour', 18.4, 76.57],
  ['Lone', 28.75, 77.29],
  ['Ludhiana', 30.91, 75.85],
  ['Lungley', 22.89, 92.74],
  ['Madurai', 9.92, 78.12],
  ['Madhubani', 26.35, 86.07],
  ['Mysore', 12.3, 76.64],
  ['Malappuram', 11.04, 76.08],
  ['Malegaon', 20.55, 74.53],
  ['Nelluru', 14.45, 79.99],
  ['Nirmala', 19.1, 78.34],
  ['Noida', 28.58, 77.33],
  ['Ongole', 15.5, 80.04],
  ['Osmanabad', 18.18, 76.04],

  ['London', 51.51, -0.13],
  ['Aberdeen', 57.14, -2.1],
  ['Acton', 51.51, -0.28],
  ['Bangor', 54.65, -5.67],
  ['Barnsley', 53.55, -1.48],
  ['Barry', 51.4, -3.28],
  ['Basildon', 51.57, 0.46],
  ['Bat', 51.38, -2.36],
  ['Bedford', 52.13, -0.47],
  ['Belfast', 54.6, -5.93],
  ['Since', 53.6, -2.3],
  ['Burnley', 53.8, -2.23],
  ['Burton-upon-Trent', 52.81, -1.64],
  ['Birkenhead', 53.39, -3.01],
  ['Birmingham', 52.48, -1.9],
  ['Blackburn', 53.75, -2.48],
  ['Blackpool', 53.82, -3.05],
  ['Bolton', 53.58, -2.43],
  ['Bournemouth', 50.72, -1.88],
  ['Bradford', 53.79, -1.75],
  ['Brighton', 50.83, -0.14],
  ['Brentwood', 51.62, 0.31],
  ['Bridgend', 51.51, -3.58],
  ['Bristol', 51.46, -2.6],
  ['Bootle', 53.47, -3.02],
  ['Washington', 54.9, -1.52],
  ['Wolverhampton', 52.59, -2.12],
  ['Worcester', 52.19, -2.22],
  ['Halifax', 53.72, -1.85],
  ['Hamilton', 55.77, -4.03],
  ['Hastings', 50.86, 0.57],
  ['Gateshead', 54.96, -1.6],
  ['Guildford', 51.24, -0.57],
  ['Glasgow', 55.87, -4.26],
  ['Gloucester', 51.87, -2.24],
  ['Luton', 51.88, -0.42],

  ['Oslo', 59.91, 10.75],
  ['Bergen', 60.39, 5.32],
  ['Drammen', 59.74, 10.2],
  ['Kristiansand', 58.15, 8],
  ['Sarpsborg', 59.28, 11.11],
  ['Stavanger', 58.97, 5.73],
  ['Tromsø', 69.65, 18.96],
  ['Trondheim', 63.43, 10.4],
  ['Fredrikstad', 59.22, 10.93],
  ['Shien', 59.21, 9.61],

  ['Warsaw', 52.23, 21.01],
  ['Bialystok', 53.13, 23.16],
  ['Belchatow', 51.37, 19.36],
  ['Bielsko-Biala', 49.82, 19.05],
  ['Bemovo', 52.25, 20.91],
  ['Will', 52.24, 20.99],
  ['Wroclaw', 51.1, 17.03],
  ['Gdansk', 54.35, 18.65],
  ['Gdynia', 54.52, 18.53],
  ['Myslowice', 50.21, 19.17],
  ['Nowy Sacz', 49.62, 20.7],
  ['Olsztyn', 53.78, 20.49],
  ['Raciborz', 50.09, 18.22],
  ['Ruda Slonska', 50.26, 18.86],
  ['Fish pie', 50.1, 18.54],
  ['Swietochlowice', 50.3, 18.92],
  ['Swidnica', 50.84, 16.49],
  ['Siedlce', 52.17, 22.29],
  ['Siemianowice-Slaskie', 50.33, 19.03],
  ['Slupsk', 54.46, 17.03],
  ['Sosnowiec', 50.29, 19.1],
  ['Midtown', 52.23, 21.02],
  ['Stalyova-Volya', 50.58, 22.05],
  ['Starachowice', 51.04, 21.07],
  ['Szczecin', 53.43, 14.55],
  ['Elk', 53.83, 22.36],
  ['Elbląg', 54.15, 19.41],
  ['Jaworzno', 50.21, 19.28],
  ['Jastrzębie-Zdrój', 49.96, 18.57],

  ['Ankara', 39.92, 32.85],
  ['Anamur', 36.08, 32.84],
  ['Antalya', 36.91, 30.7],
  ['Burdur', 37.72, 30.29],
  ['Bursa', 40.2, 29.06],
  ['Van', 38.49, 43.38],
  ['Viranshehir', 37.22, 39.76],
  ['Gaziantep', 37.06, 37.38],
  ['Gebze', 40.8, 29.43],
  ['Gemlik', 40.43, 29.16],
  ['Giresun', 40.92, 38.39],
  ['Denizli', 37.77, 29.09],
  ['Ceyhan', 37.02, 35.82],
  ['Cizre', 37.33, 42.18],
  ['Diyarbakir', 37.91, 40.22],
  ['Osmaniye', 37.07, 36.25],
  ['Pergamum', 39.12, 27.18],
  ['Uskudar', 41.02, 29.01],
  ['Ushak', 38.67, 29.41],
  ['Fatsa', 41.03, 37.5],
  ['Fethiye', 36.64, 29.13],
  ['Hakkari', 37.57, 43.74],
  ['Canakkale', 40.16, 26.41],
  ['Chankyry', 40.6, 33.62],
  ['Charshamba', 41.2, 36.72],

  ['Riyadh', 24.69, 46.72],
  ['Abha', 18.22, 42.51],
  ['Ash-Shafa', 21.07, 40.31],
  ['Jizan', 16.89, 42.55],
  ['Medina', 24.47, 39.61],
  ['Mecca', 21.43, 39.83],
  ['Khamis-Mushayt', 18.3, 42.73],
  ['El-Khobar', 26.28, 50.21],

  ['Cairo', 30.06, 31.25],
  ['Zifta', 30.71, 31.24],
  ["I'm going", 31.31, 30.3],
  ['Ismailia', 30.6, 32.27],
  ['Qalyub', 30.18, 31.21],
  ['Kom-Ombo', 24.48, 32.95],
  ['Luxor', 25.7, 32.64],
  ['Ez-Zakazig', 30.59, 31.5],
  ['Esna', 25.29, 32.55],

  ['Canberra', -35.28, 149.13],
  ['Adelaide', -34.93, 138.6],
  ['Ballarat', -37.57, 143.85],
  ['Banbury', -33.33, 115.64],
  ['Mackay', -21.15, 149.17],
  ['Mandura', -32.53, 115.72],
  ['Melbourne', -37.81, 144.96],
  ['Hobart', -42.88, 147.33],

  ['Buenos Aires', -34.61, -58.38],
  ['Azul', -36.78, -59.86],
  ['Cordova', -31.41, -64.18],
  ['Corrientes', -27.47, -58.83],
  ['La Plata', -34.92, -57.95],
  ['La Rioja', -29.41, -66.85],
  ['Luhan', -34.57, -59.11],
  ['Pergamino', -33.89, -60.57],
  ['Rio Gallegos', -51.62, -69.22],
  ['Rio Cuarto', -33.13, -64.35],
  ['Cipolletti', -38.93, -67.99],

  ['Brazil', -15.78, -47.93],
  ['Abreu i Lima', -7.91, -34.9],
  ['Alagoinhas', -12.14, -38.42],
  ['Alegreti', -29.78, -55.79],
  ['Almiranti-Tamandare', -25.32, -49.31],
  ['Alfenas', -21.43, -45.95],
  ['Altamira', -3.2, -52.21],
  ['Americana', -22.74, -47.33],
  ['Belo Horizonte', -19.92, -43.94],
  ['Belford-Roshu', -22.76, -43.4],
  ['Betin', -19.97, -44.2],
  ['Birigui', -21.29, -50.34],
  ['Blumenau', -26.92, -49.07],
  ['Boa Vista', 2.82, -60.67],
  ['Botucatu', -22.89, -48.45],
  ['Joao Pessoa', -7.12, -34.86],
  ['Juiz de Fora', -21.76, -43.35],
  ['Jundiai', -23.19, -46.88],
  ['Ibirite', -20.02, -44.06],
  ['Igarasu', -7.83, -34.91],
  ['Izhuy', -28.39, -53.91],
  ['Campo Largo', -25.46, -49.53],
  ['Campus do Jordao', -22.74, -45.59],
  ['Campos dos Goytacazes', -21.75, -41.33],
  ['Canoas', -29.92, -51.18],
  ['Caraguatatuba', -23.62, -45.41],
  ['Cruz Alta', -28.64, -53.61],
  ['Kurvelu', -18.76, -44.43],
  ['Curitiba', -25.43, -49.27],
  ['Cuiaba', -15.6, -56.1],
  ['Lavras', -21.25, -45],
  ['Lajeado', -29.47, -51.96],
  ['Mairiporan', -23.32, -46.59],
  ['Macapa', 0.04, -51.07],
  ['Macae', -22.38, -41.78],
  ['Manakapuru', -3.3, -60.62],
  ['Novo Hamburgo', -29.68, -51.13],
  ['Olinda', -8.01, -34.86],
  ['Ourinhos', -22.98, -49.87],
  ['Ortolandia', -22.86, -47.22],
  ['Pelotas', -31.77, -52.34],
  ['Penapolis', -21.42, -50.08],
  ['Peruibi', -24.32, -47],
  ['Petrolina', -9.4, -40.5],
  ['Petropolis', -22.51, -43.18],
  ['Picus', -7.08, -41.47],
  ['Pindamonyangaba', -22.92, -45.46],
  ['Pirapora', -17.35, -44.94],
  ['Piracicaba', -22.73, -47.65],
  ['Pirasununga', -22, -47.43],
  ['Pouso Alegre', -22.23, -45.94],
  ['Santana do Livramento', -30.89, -55.53],
  ['Santarem', -2.44, -54.71],

  ['Ottawa', 45.41, -75.7],
  ['Abbotsford', 49.06, -122.25],
  ['Barry', 44.4, -79.67],
  ['Burlington', 43.39, -79.84],
  ['Burnaby', 49.27, -122.95],
  ['Greater Sudbury', 46.49, -80.99],
  ['Brampton', 43.68, -79.77],
  ['Brantford', 43.13, -80.27],
  ['Vancouver', 49.25, -123.12],
  ['Victoria', 48.43, -123.37],
  ['Winnipeg', 49.88, -97.15],
  ['There', 43.84, -79.5],
  ['Halifax', 44.65, -63.57],
  ['Hamilton', 43.25, -79.85],
  ['Gatineau', 45.48, -75.7],
  ['Granby', 45.4, -72.73],
  ['Guelph', 43.55, -80.26],
  ['Delta', 49.14, -122.91],
  ['Drummondville', 45.88, -72.48],
  ['Calgary', 51.05, -114.09],
  ['Kamloops', 50.67, -120.32],
  ['Quebec', 46.81, -71.21],
  ['Cambridge', 43.36, -80.31],
  ['Kelowna', 49.88, -119.49],
  ['Kingston', 44.23, -76.48],
  ['Kitchener', 43.43, -80.51],
  ['Laval', 45.57, -73.69],
  ['Levi', 46.8, -71.18],
  ['Lethbridge', 49.7, -112.82],
  ['Longueuil', 45.53, -73.52],
  ['London', 42.98, -81.23],
  ['Markham', 43.87, -79.27],
  ['Medicine Hat', 50.04, -110.68],
  ['Milton', 43.52, -79.88],
  ['Mississauga', 43.58, -79.66],
  ['Moncton', 46.09, -64.8],
  ['Montreal', 45.51, -73.59],
  ['Maple Ridge', 49.22, -122.6],
  ['Nanaimo', 49.17, -123.94],
  ['Nepean', 45.35, -75.74],
  ['Niagara Falls', 43.1, -79.07],
  ['North Bay', 46.32, -79.47],
  ['North York', 43.77, -79.42],
  ['New Westminster', 49.21, -122.91],
  ['Newmarket', 44.05, -79.47],
  ['Oakville', 43.45, -79.68],
  ['Oshawa', 43.9, -78.85],
  ['Pickering', 43.9, -79.13],
  ['Peterborough', 44.3, -78.32],
  ['Prince George', 53.92, -122.75],
  ['Red Deer', 52.27, -113.8],
  ['Regina', 50.45, -104.62],
  ['Repentignies', 45.74, -73.45],
  ['Richmond', 49.17, -123.14],
  ['Richmond Hill', 43.87, -79.44],
  ['Saguenay', 48.42, -71.07],
  ['Sarnia', 42.98, -82.4],
  ['Saskatoon', 52.13, -106.67],
  ['Saint-Jean-sur-Richelieu', 45.31, -73.26],
  ['Saint Jerome', 45.78, -74],
  ['St. John', 45.27, -66.07],
  ["St. John's", 47.56, -52.71],
  ['Saint Hyacinthe', 45.63, -72.96],
  ['St. Catharines', 43.17, -79.24],
  ['Scarborough', 43.77, -79.26],
  ['Sault Ste Marie', 46.52, -84.33],
  ['Surrey', 49.11, -122.83],
  ['Thunder Bay', 48.38, -89.25],
  ['Terbon', 45.7, -73.65],
  ['Toronto', 43.7, -79.42],
  ['Trois-Rivieres', 46.35, -72.55],
  ['Winsor', 42.3, -83.02],
  ['Waterloo', 43.47, -80.52],
  ['Fredericton', 45.95, -66.67],
  ['Sherbrooke', 45.4, -71.9],
  ['Edmonton', 53.55, -113.47],
  ['Ajax', 43.85, -79.03],
  ['Etobicoke', 43.65, -79.57],

  ['Moscow', 55.75, 37.62],
  ['Astrakhan', 46.35, 48.04],
  ['Barnaul', 53.36, 83.76],
  ['Bryansk', 53.25, 34.37],
  ['Vladivostok', 43.11, 131.87],
  ['Volgograd', 48.72, 44.5],
  ['Voronezh', 51.67, 39.18],
  ['Ekaterinburg', 56.85, 60.61],
  ['Ivanovo', 57, 40.97],
  ['Izhevsk', 56.85, 53.2],
  ['Irkutsk', 52.3, 104.3],
  ['Kazan', 55.79, 49.12],
  ['Kaliningrad', 54.71, 20.51],
  ['Kemerovo', 55.33, 86.08],
  ['Kirov', 58.6, 49.66],
  ['Krasnodar', 45.04, 38.98],
  ['Krasnoyarsk', 56.02, 92.87],
  ['Lipetsk', 52.6, 39.57],
  ['Makhachkala', 42.98, 47.5],
  ['Naberezhnye Chelny', 55.73, 52.41],
  ['Nizhny Novgorod', 56.33, 44],
  ['Novokuznetsk', 53.76, 87.11],
  ['Novosibirsk', 55.04, 82.93],
  ['Omsk', 54.99, 73.37],
  ['Orenburg', 51.77, 55.1],
  ['Penza', 53.2, 45],
  ['Permian', 58.01, 56.25],
  ['Rostov-on-Don', 47.23, 39.72],
  ['Ryazan', 54.63, 39.69],
  ['Samara', 53.2, 50.15],
  ['Saint Petersburg', 59.94, 30.31],
  ['Saratov', 51.54, 46.01],
  ['Tolyatti', 53.53, 49.35],
  ['Tomsk', 56.5, 84.97],
  ['Tula', 54.2, 37.62],
  ['Tyumen', 57.15, 65.53],
  ['Ulyanovsk', 54.33, 48.39],
  ['Ufa', 54.74, 55.97],
  ['Khabarovsk', 48.48, 135.08],
  ['Cheboksary', 56.13, 47.25],
  ['Chelyabinsk', 55.15, 61.43],
  ['Yaroslavl', 57.63, 39.87],

  ['Astana', 51.18, 71.45],
  ['Aktau', 43.65, 51.17],
  ['Aktobe', 50.28, 57.21],
  ['Almaty', 43.26, 76.93],
  ['Atyrau', 47.12, 51.88],
  ['Baikonur', 45.62, 63.32],
  ['Balkhash', 46.85, 75],
  ['Zhanaozen', 43.34, 52.86],
  ['Zhezkazgan', 47.78, 67.77],
  ['Karaganda', 49.8, 73.1],
  ['Kentau', 43.52, 68.5],
  ['Kokshetau', 53.28, 69.4],
  ['Kostanay', 53.21, 63.62],
  ['Kulsary', 46.95, 54.02],
  ['Kyzylorda', 44.85, 65.5],
  ['Kyzylorda', 44.85, 65.51],
  ['Pavlodar', 52.28, 76.97],
  ['Petropavlovsk', 54.87, 69.15],
  ['Ridder', 50.34, 83.51],
  ['Ore', 52.97, 63.12],
  ['Sarkand', 45.41, 79.92],
  ['Families', 50.43, 80.27],
  ['Taldykorgan', 45.02, 78.37],
  ['Taraz', 42.9, 71.37],
  ['Temirtau', 50.05, 72.96],
  ['Turkestan', 43.3, 68.25],
  ['Uralsk', 51.23, 51.37],
  ['Ust-Kamenogorsk', 49.97, 82.61],
  ['Shymkent', 42.3, 69.6],
  ['Ekibastuz', 51.72, 75.32],

  ['Abuja', 9.06, 7.5],
  ['Addis Ababa', 9.02, 38.75],
  ['Accra', 5.56, -0.2],
  ['Algeria', 36.73, 3.09],
  ['Antananarivo', -18.91, 47.54],
  ['Asmara', 15.34, 38.93],
  ['Bamako', 12.65, -8],
  ['Bangi', 4.36, 18.56],
  ['Banjul', 13.45, -16.58],
  ['Bissau', 11.86, -15.6],
  ['Brazzaville', -4.27, 15.28],
  ['Bujumbura', -3.38, 29.36],
  ['Victoria', -4.62, 55.46],
  ['Windhoek', -22.56, 17.08],
  ['Gaborone', -24.65, 25.91],
  ['Dakar', 14.69, -17.44],
  ['Djibouti', 11.59, 43.15],
  ['Juba', 4.85, 31.58],
  ['Dodom', -6.17, 35.74],
  ['Cairo', 30.06, 31.25],
  ['Kampala', 0.32, 32.58],
  ['Kigali', -1.95, 30.06],
  ['Kinshasa', -4.33, 15.31],
  ['Conakry', 9.54, -13.68],
  ['Libreville', 0.39, 9.45],
  ['Lilongwe', -13.97, 33.79],
  ['Lome', 6.13, 1.22],
  ['Luanda', -8.84, 13.23],
  ['Lusaka', -15.41, 28.29],
  ['Malabo', 3.76, 8.78],
  ['Maputo', -25.97, 32.58],
  ['Maseru', -29.32, 27.48],
  ['Mbabane', -26.32, 31.13],
  ['Mogadishu', 2.04, 45.34],
  ['Monrovia', 6.3, -10.8],
  ['Moroni', -11.7, 43.26],
  ['Nairobi', -1.28, 36.82],
  ["N'Djamena", 12.11, 15.04],
  ['Niamey', 13.51, 2.11],
  ['Nouakchott', 18.09, -15.98],
  ['Port Louis', -20.16, 57.5],
  ['Porto-Novo', 6.5, 2.6],
  ['Praia', 14.93, -23.51],
  ['Pretoria', -25.74, 28.19],
  ['Rabat', 34.01, -6.83],
  ['Sao Tome', 0.34, 6.73],
  ['Tripoli', 32.89, 13.19],
  ['Tunisia', 36.82, 10.17],
  ['Ouagadougou', 12.37, -1.53],
  ['Freetown', 8.49, -13.24],
  ['Harare', -17.83, 31.05],
  ['Khartoum', 15.55, 32.53],
  ['Yamoussoukro', 6.82, -5.28],
  ['Yaounde', 3.87, 11.52],

  ['Asuncion', -25.29, -57.65],
  ['Bogota', 4.61, -74.08],
  ['Brazil', -15.78, -47.93],
  ['Buenos Aires', -34.61, -58.38],
  ['Georgetown', 6.8, -58.16],
  ['Caracas', 10.49, -66.88],
  ['Quito', -0.23, -78.52],
  ['Lima', -12.04, -77.03],
  ['Montevideo', -34.9, -56.19],
  ['Paramaribo', 5.87, -55.17],
  ['Santiago', -33.46, -70.65],
  ['Sucre', -19.03, -65.26],

  ['Abu Dhabi', 24.47, 54.37],
  ['Amman', 31.96, 35.95],
  ['Ankara', 39.92, 32.85],
  ['Astana', 51.18, 71.45],
  ['Ashgabat', 37.95, 58.38],
  ['Baghdad', 33.34, 44.4],
  ['Baku', 40.38, 49.89],
  ['Bangkok', 13.75, 100.5],
  ['Bandar Seri Begawan', 4.89, 114.94],
  ['Beirut', 33.89, 35.5],
  ['Bishkek', 42.87, 74.59],
  ['Vientiane', 17.97, 102.6],
  ['Dhaka', 23.71, 90.41],
  ['Damascus', 33.51, 36.29],
  ['Jakarta', -6.21, 106.85],
  ['Doha', 25.29, 51.53],
  ['Dushanbe', 38.54, 68.78],
  ['Yerevan', 40.18, 44.51],
  ['Islamabad', 33.72, 73.04],
  ['Kabul', 34.53, 69.17],
  ['Kathmandu', 27.7, 85.32],
  ['Colombo', 6.93, 79.85],
  ['Kuala Lumpur', 3.14, 101.69],
  ['Male', 4.18, 73.51],
  ['Manama', 26.23, 50.59],
  ['Manila', 14.6, 120.98],
  ['Muscat', 23.58, 58.41],
  ['New Delhi', 28.64, 77.22],
  ['Beijing', 39.91, 116.4],
  ['Phnom Penh', 11.56, 104.92],
  ['Pyongyang', 39.03, 125.75],
  ['Sana', 15.35, 44.21],
  ['Seoul', 37.57, 126.98],
  ['Singapore', 1.29, 103.85],
  ['Taipei', 25.05, 121.53],
  ['Tashkent', 41.26, 69.22],
  ['Tbilisi', 41.69, 44.83],
  ['Tehran', 35.69, 51.42],
  ['Tokyo', 35.69, 139.69],
  ['Thimphu', 27.47, 89.64],
  ['Ulaanbaatar', 47.91, 106.88],
  ['Hanoi', 21.02, 105.84],
  ['El Kuwait', 29.37, 47.98],
  ['Riyadh', 24.69, 46.72],
].map(([city, a, b]) => [city, b, a]);

export const geojson = {
  features: [
    ...coordinates.map((coordinate) => ({
      geometry: { coordinates: [coordinate[1], coordinate[2]], type: 'Point' },
      properties: { description: coordinate[0] as string, title: 'Mapbox' },
      type: 'Feature',
    })),
    {
      geometry: {
        coordinates: [-74.9912, 42.3305],
        type: 'Point',
      },
      properties: {
        description: 'New York',
        title: 'Mapbox',
      },
      type: 'Feature',
    },
    {
      geometry: {
        coordinates: [5.6617, 44.8191],
        type: 'Point',
      },
      properties: {
        description: 'Limoges',
        title: 'Mapbox',
      },
      type: 'Feature',
    },
    {
      geometry: {
        coordinates: [-2, 41.4191],
        type: 'Point',
      },
      properties: {
        description: 'Madrid',
        title: 'Mapbox',
      },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [-5, 38.4191], type: 'Point' },
      properties: { description: 'Seville', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [-3, 38.4191], type: 'Point' },
      properties: { description: 'Seville', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: {
        coordinates: [-3, 38.4191],
        type: 'Point',
      },
      properties: {
        description: 'Seville',
        title: 'Mapbox',
      },
      type: 'Feature',
    },
    {
      geometry: {
        coordinates: [12, 55.4191],
        type: 'Point',
      },
      properties: {
        description: 'Denmark',
        title: 'Mapbox',
      },
      type: 'Feature',
    },
    {
      geometry: {
        coordinates: [11, 54.4191],
        type: 'Point',
      },
      properties: {
        description: 'Germany',
        title: 'Mapbox',
      },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [6, 50.4191], type: 'Point' },
      properties: { description: 'Belgium', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [12, 50.4191], type: 'Point' },
      properties: { description: 'Germany', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [11, 60.4191], type: 'Point' },
      properties: { description: 'Norway', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [11.5, 60.8191], type: 'Point' },
      properties: { description: 'Norway', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [16.5, 59.8191], type: 'Point' },
      properties: { description: 'Sweden', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [15.9, 58.9191], type: 'Point' },
      properties: { description: 'Sweden', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [15.9, 49.9191], type: 'Point' },
      properties: { description: 'Czechia', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [15.0, 49.3191], type: 'Point' },
      properties: { description: 'Czechia', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [14.9, 48.7191], type: 'Point' },
      properties: { description: 'Austria', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [14.9, 45.7191], type: 'Point' },
      properties: { description: 'Slovenia', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [21, 45.7191], type: 'Point' },
      properties: { description: 'Romania', title: 'Mapbox' },
      type: 'Feature',
    },
    {
      geometry: { coordinates: [100, 20.7191], type: 'Point' },
      properties: { description: 'Thailand', title: 'Mapbox' },
      type: 'Feature',
    },
  ],
  type: 'FeatureCollection',
};

export const chips = [
  { model: '7800X3D', name: 'AMD' },
  { model: '7600X', name: 'AMD' },
  { model: '5900X', name: 'AMD' },
  { model: 'M3 MAX', name: 'Apple' },
  { model: 'M1 Pro', name: 'Apple' },
  { model: 'i7-13900K', name: 'Intel' },
  { model: 'i5-13600K', name: 'Intel' },
  { model: 'i5-12400F', name: 'Intel' },
  { model: 'i5-9600K', name: 'Intel' },
  { model: 'w7-2425', name: 'Intel' },
  { model: 'RTX 4090', name: 'Nvidia' },
  { model: 'RTX 4080', name: 'Nvidia' },
  { model: 'RTX 3090', name: 'Nvidia' },
  { model: 'A100', name: 'Nvidia' },
  { model: 'H100', name: 'Nvidia' },
];
