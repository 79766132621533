import { useQuery } from '@tanstack/react-query';

import type { PeriodType } from 'pages/Wallet/types';
import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { messagesKeys } from './queryKeys';

type Response = {
  _id: string;
  count: number;
  date: string;
}[];

export const useGetLLMModelStatisticsByPeriod = (
  { groupBy, id }: { groupBy: PeriodType; id: string },
  options: UseQueryOptions<Response> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<Response>(`/messages/models/${id}/statistics`, {
        params: { groupBy },
        signal,
      });

      return data;
    },
    queryKey: messagesKeys.list({ groupBy, id, type: 'single-llm-model-stat' }),
    ...options,
  });
};
