import { useEffect, useMemo, useState } from 'react';

import { useIntersectionObserver } from '@uidotdev/usehooks';

import type { Model } from 'shared/api/models/types';
import type { TableSort } from 'shared/ui/Table';

import { useModelListQuery } from 'shared/api/models/useGetModelListQuery';
import { useGetModelTypesQuery } from 'shared/api/models/useGetModelTypesQuery';
import { ContentLoader } from 'shared/ui/ContentLoader';
import { Select } from 'shared/ui/Select';
import { Spinner } from 'shared/ui/Spinner';
import { Table } from 'shared/ui/Table';

import { columns } from './config';
import { Skeleton } from './ui/Skeleton';

export const LeaderBoardTab = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [sort, setSort] = useState<TableSort<keyof Model>>({ key: 'likes', type: 'desc' });

  const [ref, intersection] = useIntersectionObserver({ rootMargin: '30px' });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } = useModelListQuery({
    direction: sort.type,
    sort: sort.key,
    type: selectedCategory,
  });

  const { data: modelTypesFull, isLoading: isLoadingModelTypes } = useGetModelTypesQuery();

  const modelTypes = modelTypesFull?.types.map(({ model_type }) => model_type);

  const isLoading = status === 'pending';

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [intersection?.isIntersecting, hasNextPage, fetchNextPage]);

  const tableData = useMemo(() => {
    if (!data) return [];
    return data?.pages.flatMap((el) => el);
  }, [data]);

  return (
    <>
      <h2 className="py-6 text-xl font-semibold text-corduroy-900 lg:text-2xl">Leaderboard</h2>

      <div className="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-3">
        {modelTypes && (
          <Select
            key={selectedCategory}
            onValueChange={setSelectedCategory}
            placeholder="All model categories"
            value={selectedCategory}
          >
            <Select.Content className="z-10">
              {modelTypes.map((type) => {
                return (
                  <Select.Item
                    key={type}
                    onPointerDown={
                      type === selectedCategory
                        ? (e) => {
                            e.preventDefault();
                            setSelectedCategory(undefined);
                          }
                        : undefined
                    }
                    value={type}
                  >
                    {type}
                  </Select.Item>
                );
              })}
            </Select.Content>
          </Select>
        )}
        {isLoadingModelTypes && (
          <ContentLoader height="40" type="secondary" width="100%">
            <rect height="40" rx="8" ry="8" width="100%" x="0" y="0" />
          </ContentLoader>
        )}
      </div>

      <div className="overflow-x-auto">
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Table
              className="min-w-[58rem]"
              columns={columns}
              data={tableData}
              onSortChange={setSort}
              sort={sort}
            />
            <div ref={ref} />
          </>
        )}
        {isFetchingNextPage && (
          <div className=" flex justify-center py-5">
            <Spinner className=" size-8" />
          </div>
        )}
      </div>
    </>
  );
};
