import { type InputHTMLAttributes, forwardRef } from 'react';

import { twMerge } from 'tailwind-merge';

import { Label } from '../Label';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  error?: string;
  innerClassName?: string;
  label?: string;
  rows?: number;
}

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, disabled, error, innerClassName, label, rows = 4, ...props }: Props, ref) => {
    return (
      <div className={twMerge('relative flex flex-col gap-0 pb-4', className)}>
        {label && <Label>{label}</Label>}
        <textarea
          className={twMerge(
            'rounded-xl border border-corduroy-100 bg-white p-2 text-base font-normal outline-none transition-colors',
            'placeholder:font-light hover:border-corduroy-300 focus:border-corduroy-300 2xl:text-sm',
            'disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-corduroy-200',
            innerClassName,
          )}
          disabled={disabled}
          ref={ref}
          rows={rows}
          {...props}
        ></textarea>
        {error && <div className="">{error}</div>}
      </div>
    );
  },
);
