import { useReducer } from 'react';

import shallowEqual from 'shallowequal';

export type StateXAction<S extends Record<string, unknown>> = ((prev: S) => Partial<S> | null) | Partial<S>;

const reducer = <State extends Record<string, unknown>>(state: State, action: StateXAction<State>) => {
  const change = typeof action === 'function' ? action(state) : action;

  if (!change) return state;

  const newState = { ...state, ...change };

  if (shallowEqual(state, newState)) return state;

  return newState;
};

type Reducer<S extends Record<string, unknown>> = (state: S, action: StateXAction<S>) => S;

export const useStateX = <State extends Record<string, unknown>>(initialState: State) => {
  return useReducer<Reducer<State>>(reducer, initialState);
};
