import { type ReactNode, useState } from 'react';

// import { motion } from 'framer-motion';
import { twJoin, twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';
import type { ClassName } from 'shared/types';

import { Badge } from '../Badge';
import { ContentLoader } from '../ContentLoader';
import { Icon } from '../Icon';
import { StretchedSkeleton } from '../StretchedSkeleton';
import { ModelCardBottomInfo } from './ui/ModelCardBottomInfo';

type Props = {
  bottomSlot?: ReactNode;
  descriptionClassName?: string;
  imgClassName?: string;
  isDescriptionPreview?: boolean;
  isLoading?: boolean;
  model?: Model;
  onChipClick?: (chipType?: 'reviews') => void;
  onClick?: () => void;
  size?: 'l' | 'm';
} & ClassName;

export const ModelCard = ({
  bottomSlot: BottomSlot,
  className,
  descriptionClassName,
  imgClassName,
  isDescriptionPreview,
  isLoading,
  model,
  onChipClick,
  onClick,
  size = 'm',
}: Props) => {
  const [statusImage, setStatusImage] = useState('loading');
  const [titleIsLineClamp2, setTitleIsLineClamp2] = useState(true);

  return (
    <div className={className}>
      <div className={twJoin('flex gap-4', !!onClick && 'cursor-pointer')} onClick={onClick}>
        <div
          className={twMerge(
            'relative size-14 overflow-hidden rounded-lg xs:@sm:size-32 xs:@sm:min-w-32 lg:@sm:size-28 lg:@sm:min-w-28 2xl:@sm:size-32 2xl:@sm:min-w-32',
            imgClassName,
          )}
        >
          {!isLoading && (
            <img
              className="size-full overflow-hidden object-cover object-center"
              onError={() => setStatusImage('error')}
              onLoad={() => setStatusImage('success')}
              src={model?.image}
            />
          )}
          {statusImage === 'loading' && (
            <ContentLoader className="absolute left-0 top-0" height="100%" width="100%">
              <rect height="100%" rx="5" ry="5" width="100%" x="0" y="0" />
            </ContentLoader>
          )}
        </div>
        <div className="flex flex-1 flex-col justify-between">
          <div className="flex">
            <div className="relative flex-1 grow">
              <div
                className={twMerge(
                  'relative mb-1 min-h-5 break-all text-lg/5 font-bold capitalize',
                  size === 'l' && 'mb-2 min-h-6 lg:text-xl/6',
                )}
                ref={(el) => {
                  if (el) {
                    setTitleIsLineClamp2(el.clientHeight > 30);
                  }
                }}
              >
                <StretchedSkeleton enable={isLoading} />

                {!isLoading && model?.name}
              </div>

              <div
                className={twMerge(
                  'relative min-h-5 text-ellipsis whitespace-pre-line text-sm font-light text-corduroy-600',
                  size === 'l' && 'lg:text-base/5',
                  isDescriptionPreview ? (titleIsLineClamp2 ? 'line-clamp-2' : 'line-clamp-3') : '',
                  descriptionClassName,
                  isLoading && 'min-h-12',
                )}
              >
                <StretchedSkeleton className="min-h-12" enable={isLoading} />

                {!isLoading && model?.description}
              </div>
            </div>
            {false && (
              <div className="flex w-fit flex-col gap-0.5">
                <Badge className="gap-0.5 bg-yellow-500 text-white">
                  {model?.likes} <Icon className="size-3 p-0 text-white" name="starSolid" />
                </Badge>
              </div>
            )}
          </div>

          {BottomSlot || (
            <ModelCardBottomInfo
              className="mt-2 hidden xs:@md:flex"
              isLoading={isLoading}
              latency={model?.latency}
              likes={model?.likes}
              onChipClick={onChipClick}
              reviewCount={model?.reviewsCount}
              size={size}
            />
          )}
        </div>
      </div>
      {!BottomSlot && (
        <ModelCardBottomInfo
          className="mt-2 xs:@md:hidden"
          isLoading={isLoading}
          latency={model?.latency}
          likes={model?.likes}
          onChipClick={onChipClick}
          reviewCount={model?.reviewsCount}
          size={size}
        />
      )}
    </div>
  );
};
