import arbitrumOrbitSrc from './ui/assets/d-apps/arbitrum-orbit.png';
import dymensionSrc from './ui/assets/d-apps/dymension.png';
import opStackSrc from './ui/assets/d-apps/op-stack.png';
import polygonCdkSrc from './ui/assets/d-apps/polygon-cdk.png';
import rollkitSrc from './ui/assets/d-apps/rollkit.png';
import sovereignSdkSrc from './ui/assets/d-apps/sovereign-sdk.png';
import DeveloperResourcesDocIcon from './ui/assets/developer-resources/documentation.svg?react';
import DeveloperResourcesPayIcon from './ui/assets/developer-resources/pay.svg?react';
import DeveloperResourcesTutorialIcon from './ui/assets/developer-resources/tutorial.svg?react';
import altLayerSrc from './ui/assets/services/alt-layer.png';
import astriaSrc from './ui/assets/services/astria.png';
import calderaSrc from './ui/assets/services/caldera.png';
import conduitSrc from './ui/assets/services/conduit.png';
import gatewaySrc from './ui/assets/services/gateway.png';
import gelatoSrc from './ui/assets/services/gelato.png';
import karnotSrc from './ui/assets/services/karnot.png';
import lumozSrc from './ui/assets/services/lumoz.png';
import snapchainSrc from './ui/assets/services/snapchain.png';
import vistaraSrc from './ui/assets/services/vistara.png';
import zeeveSrc from './ui/assets/services/zeeve.png';

export const anchorCards = [
  {
    description: 'Pick a rollup framework to begin building.',
    link: '#choose',
    linkText: 'Choose',
    title: 'Build',
  },
  {
    description: 'Plug Nesa into your existing chain.',
    link: '#integrate',
    linkText: 'Start',
    title: 'Integrate',
  },
  {
    description: 'Explore Rollup-as-a-Service to deploy quickly.',
    link: '#deploy',
    linkText: 'Explore',
    title: 'Deploy',
  },
];

export const dAppTabs = [
  { label: 'All', value: 'all' },
  { label: 'Ethereum', value: 'ethereum' },
  { label: 'Sovereign', value: 'sovereign' },
];

export const dApps = [
  {
    description: 'Arbitrum Orbit is the ideal way to permissionlessly launch your own custom chain.',
    externalLinks: [{ label: 'Explore Arbitrum Orbit', link: '' }],
    image: arbitrumOrbitSrc,
    isReady: true,
    title: 'Arbitrum Orbit',
    type: 'ethereum',
  },
  {
    description: 'Dymension is a home for easily deployable and lightning fast app-chains, called RollApps.',
    externalLinks: [{ label: 'Explore Dymension', link: '' }],
    image: dymensionSrc,
    isReady: true,
    title: 'Dymension',
    type: 'sovereign',
  },
  {
    description:
      'The OP Stack is a modular, open-source blueprint for highly scalable, highly interoperable blockchains of all kinds.',
    externalLinks: [
      { label: 'Explore OP Stack', link: '' },
      { label: 'Ethereum fallback documentation', link: '' },
    ],
    image: opStackSrc,
    isReady: true,
    title: 'OP Stack',
    type: 'ethereum',
  },
  {
    description:
      'Polygon CDK (Chain Development Kit) makes it easy for developers to design and launch zero-knowledge (ZK) L2s on Ethereum, on demand.',
    externalLinks: [],
    image: polygonCdkSrc,
    isReady: false,
    title: 'Polygon CDK',
    type: 'sovereign',
  },
  {
    description:
      'Rollkit is a rollup framework that gives developers the freedom to deploy rollups throughout the modular stack.',
    externalLinks: [{ label: 'Explore Rollkit', link: '' }],
    image: rollkitSrc,
    isReady: true,
    title: 'Rollkit',
    type: 'ethereum',
  },
  {
    description:
      'A framework for building seamlessly scalable and interoperable rollups that can run on any blockchain.',
    externalLinks: [{ label: 'Explore Sovereign SDK', link: '' }],
    image: sovereignSdkSrc,
    isReady: true,
    title: 'Sovereign SDK',
    type: 'sovereign',
  },
];

export const developerResourcesCards = [
  {
    description: 'Documentation for the Nesa network.',
    icon: <DeveloperResourcesDocIcon className="size-12" />,
    link: '',
    linkText: 'Explore',
    title: 'Nesa documentation',
  },
  {
    description: 'Overview of paying for blob transactions and Nesa’s fee market.',
    icon: <DeveloperResourcesPayIcon className="size-12" />,
    link: '',
    linkText: 'Learn',
    title: 'Pay for blobspace',
  },
  {
    description: 'Learn how to publish and retrieve transaction data from Nesa.',
    icon: <DeveloperResourcesTutorialIcon className="size-12" />,
    link: '',
    linkText: 'Follow',
    title: 'Blob tutorial',
  },
];

export const developerResourcesCards2 = [
  {
    description: 'Use Nesa as the DA layer for your Ethereum L2.',
    link: '',
    linkText: 'Integrate with NESA',
    title: 'Blobstream',
  },
  {
    description: 'Use the Nesa-node API to publish and retrieve transactions from Nesa.',
    link: '',
    linkText: 'Node API Documentation',
    title: 'Node API',
  },
];

export const servicesTabs = [
  { label: 'All', value: 'all' },
  { label: 'ARBITRUM ORBIT', value: 'arbitrum-orbit' },
  { label: 'OP STACK', value: 'op-stack' },
  { label: 'POLYGON CDK', value: 'polygon-cdk' },
  { label: 'STARKNET STACK', value: 'starknet-stack' },
];

export const servicesCards = [
  {
    description:
      'We offer a versatile rollup stack including Arbitrum Orbit, OP stack, Polygon zkEVM and StarkWare.',
    image: altLayerSrc,
    link: '',
    title: 'AltLayer',
    type: 'arbitrum-orbit',
  },
  {
    description:
      "Astria lets rollups share a single decentralized network of sequencers that's simple and permissionless to join.",
    image: astriaSrc,
    link: '',
    title: 'Astria',
    type: 'op-stack',
  },
  {
    description:
      'Caldera makes it easy to launch performant, customizable Arbitrum Orbit and OP Stack rollups. No code required.',
    image: calderaSrc,
    link: '',
    title: 'Caldera',
    type: 'polygon-cdk',
  },
  {
    description:
      'Deploy a rollup in a few clicks, no code required. Fully-managed, production grade OP Stack and Arbitrum Orbit rollups on Ethereum.',
    image: conduitSrc,
    link: '',
    title: 'Conduit',
    type: 'starknet-stack',
  },
  {
    description: 'Create your own zkEVM with all the infrastructure you need.',
    image: gatewaySrc,
    link: '',
    title: 'Gateway',
    type: 'arbitrum-orbit',
  },
  {
    description:
      'The all-in-one Ethereum Rollup as a Service Platform. Deploy production-grade & fully-serviced L2 rollups natively integrated with tools like oracles, bridges, data indexers and Account Abstraction.',
    image: gelatoSrc,
    link: '',
    title: 'Gelato',
    type: 'op-stack',
  },
  {
    description:
      'Karnot abstracts all the difficult parts of managing your own app chain so that you can build what really matters.',
    image: karnotSrc,
    link: '',
    title: 'Karnot',
    type: 'polygon-cdk',
  },
  {
    description: 'A decentralized ZK-RaaS network featuring ZKP mining.',
    image: lumozSrc,
    link: '',
    title: 'Lumoz',
    type: 'starknet-stack',
  },
  {
    description:
      'Leverage the power of ZK Rollups to scale your on-chain game or DeFi app cheaply and securely, without compromise',
    image: snapchainSrc,
    link: '',
    title: 'Snapchain',
    type: '',
  },
  {
    description:
      'Vistara is building a Hardware availability layer for the modular web aiming to democratize access to decentralized hardware.',
    image: vistaraSrc,
    link: '',
    title: 'Vistara',
    type: 'op-stack',
  },
  {
    description:
      'Build, Customize, and Launch your production-grade Rollups with Zeeve’s Rollups as a Service (RaaS).',
    image: zeeveSrc,
    link: '',
    title: 'Zeeve',
    type: 'arbitrum-orbit',
  },
];
