import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { LlmMessage, LlmMessageListResponse, LlmSessionMessagesRequestParams } from './types';

import { messagesKeys } from './queryKeys';

export const getLlmSessionMessageQueryKey = ({
  limit = 20,
  sessionId,
  skip,
  userId,
  ...params
}: LlmSessionMessagesRequestParams) => {
  return messagesKeys.list({ ...params, limit, sessionId, skip, type: 'llm', userId });
};

export const useGetLlmSessionMessagesQuery = (
  { limit = 20, modelName, sessionId, userId, ...params }: LlmSessionMessagesRequestParams,
  options: { enabled?: boolean; staleTime?: number } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: LlmMessage[] = [], all: LlmMessage[][]) => {
      return last?.length === limit ? all?.length : null;
    },
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosInstance.get<LlmMessageListResponse>(
        `messages/llm-sessions/${sessionId}/users/${userId}`,
        {
          params: {
            ...params,
            limit: limit,
            sessionId,
            skip: pageParam * limit,
            userId,
          },
          signal,
        },
      );

      return res.data.list;
    },
    queryKey: getLlmSessionMessageQueryKey({ ...params, limit, modelName, sessionId, userId }),
    ...(options || {}),
  });
};
