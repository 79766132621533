import type { ComponentProps, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';

export const BackButton = ({
  children,
  className,
  ...props
}: PropsWithChildren<ComponentProps<'button'>>) => {
  return (
    <button
      className={twMerge(
        'flex cursor-pointer items-center gap-1 bg-transparent font-light text-corduroy-500 outline-none transition-colors hover:text-corduroy-600 disabled:cursor-not-allowed disabled:text-corduroy-300',
        className,
      )}
      {...props}
    >
      <Icon className="rotate-90" name="arrowDownSm" />
      {children}
    </button>
  );
};
