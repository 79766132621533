import { DateTime } from 'luxon';

import type { QueryHistory } from 'shared/api/queryHistory/types';

import type { PeriodType } from './types';

export const getDatesByPeriod = (type: PeriodType) => {
  const date = DateTime.now();

  if (type === 'day') {
    const start = date.minus({ hours: 23 });
    return {
      endDate: date.toISO(),
      startDate: start.startOf('hour').toISO(),
    };
  }

  if (type === 'week') {
    const start = date.minus({ days: 6 });
    return {
      endDate: date.endOf('day').toISO(),
      startDate: start.startOf('day').toISO(),
    };
  }

  if (type === '2week') {
    const start = date.minus({ days: 13 });
    return {
      endDate: date.endOf('day').toISO(),
      startDate: start.startOf('day').toISO(),
    };
  }

  if (type === 'month') {
    const start = date.minus({ days: 28 });
    return {
      endDate: date.endOf('day').toISO(),
      startDate: start.startOf('day').toISO(),
    };
  }

  const start = date.minus({ months: 11 });
  return {
    endDate: date.endOf('day').toISO(),
    startDate: start.startOf('month').toISO(),
  };
};

export const formatDataForChart = (type: PeriodType, data?: QueryHistory[]) => {
  if (!data) return [];

  const dataByDate = data.reduce<Map<string, QueryHistory[]>>((acc, el) => {
    const date = DateTime.fromISO(el.date || '')
      .toLocal()
      .startOf(type === 'year' ? 'month' : type === 'day' ? 'hour' : 'day')
      .toISO();

    if (date) {
      const item = acc.get(date);
      if (item) {
        item.push(el);
      } else {
        acc.set(date, [el]);
      }
    }

    return acc;
  }, new Map());

  const date = DateTime.now();

  const dataForChart: { countTransactions: null | number; date: string }[] = [];

  const pushItem = (date: string) => {
    const item = dataByDate.get(date);
    dataForChart.push({
      countTransactions: item?.length || 0,
      date,
    });
  };

  let start = DateTime.fromISO(getDatesByPeriod(type).startDate).toLocal();

  if (!start.isValid) return [];

  if (type === 'year') {
    while (start <= date) {
      pushItem(start.toISO());

      start = start.plus({ months: 1 });
    }
  }

  if (type === 'day') {
    while (start <= date) {
      pushItem(start.toISO());

      start = start.plus({ hour: 1 });
    }
  }

  while (start <= date) {
    pushItem(start.toISO());

    start = start.plus({ day: 1 });
  }

  return dataForChart;
};
