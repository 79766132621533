import { useModelListQuery } from 'shared/api/models/useGetModelListQuery';
import { useStateX } from 'shared/hooks/useStateX';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Spinner } from 'shared/ui/Spinner';

import type { FilterState } from './ui/GalleryFilters/GalleryFilters';

import { getFiltersParams } from './helpers/getFiltersParams';
import { Gallery } from './ui/Gallery';
import { GalleryFilters } from './ui/GalleryFilters';

export const GalleryHome = () => {
  const [filters, setFilters] = useStateX<FilterState>({});

  const sortFilters = getFiltersParams(filters);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } = useModelListQuery({
    direction: sortFilters.direction,
    // search: globalSearch,
    sort: sortFilters.sort || undefined,
    type: filters.type ? filters.type : undefined,
  });

  const modelsFull = data?.pages.flat();

  return (
    <AnimateRoute className="flex flex-1 grow flex-col overflow-y-hidden">
      <GalleryFilters filters={filters} onFilterChange={setFilters} />

      {isPending ? (
        <div className="flex w-full flex-col items-center justify-between py-14">
          <Spinner className="size-8" />
        </div>
      ) : (
        <Gallery
          fetchNextPage={fetchNextPage}
          filters={filters}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          layout
          modelList={modelsFull}
          onFilterChange={setFilters}
        />
      )}
    </AnimateRoute>
  );
};
