import { memo } from 'react';

import type { TableProps, TableRowExt } from '../types';

import { Td } from './td';

type Props<ColumnKey extends string, Row extends TableRowExt<ColumnKey>> = {
  row: Row;
} & Pick<TableProps<ColumnKey, Row>, 'columns' | 'onRowClick'>;

const TrTd = <ColumnKey extends string, Row extends TableRowExt<ColumnKey>>({
  columns,
  onRowClick,
  row,
}: Props<ColumnKey, Row>) => {
  return (
    <tr className="group" onClick={() => onRowClick?.(row)}>
      {columns.map((column) => (
        <Td column={column} key={column.key} row={row} />
      ))}
    </tr>
  );
};

const TrTdMemo = memo(TrTd) as typeof TrTd;

export { TrTdMemo as TrTd };
