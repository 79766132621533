import { useNavigate } from 'react-router-dom';

import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import previewSrc from 'shared/ui/assets/nesa-bg.jpg';
import previewMobileSrc from 'shared/ui/assets/nesa-mobile-bg.jpg';

import checkSrc from './assets/check-l.svg';
import uploadSrc from './assets/upload.svg';

export const Whitelist = () => {
  const navigate = useNavigate();

  console.log('Whitelist');

  return (
    <AnimateRoute className="flex min-h-dvh flex-col items-center justify-center px-3">
      <div
        className="absolute z-[-1] hidden min-h-dvh w-full bg-cover bg-center bg-no-repeat md:block"
        style={{ backgroundImage: `url(${previewSrc})` }}
      ></div>
      <div
        className="absolute z-[-1] block min-h-dvh w-full bg-cover bg-center bg-no-repeat md:hidden"
        style={{ backgroundImage: `url(${previewMobileSrc})` }}
      ></div>

      <div className="relative flex w-full flex-col items-center justify-center gap-4 text-center">
        <img className="absolute mb-4 h-32 translate-y-[calc(-100%-40px)]" src={uploadSrc} />

        <p className="max-w-80 text-lg/5 font-extralight text-white lg:max-w-full 3xl:text-xl/6">
          {`Due to high demand,`} <br /> {`we're rolling out access to testnet in phases.`}
        </p>
        <h1 className="relative flex min-w-fit items-center gap-2  text-lg font-extralight text-tusk-100 lg:text-2xl 3xl:text-3xl">
          <img alt="check" className="size-8 lg:size-10" src={checkSrc} />
          {`You've been added to the waitlist!`}
        </h1>

        <Button color="primary" onClick={() => navigate('/preview')}>
          Back to Home
        </Button>
      </div>
    </AnimateRoute>
  );
};
