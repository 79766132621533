import type { PropsWithChildren } from 'react';

import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { twMerge } from 'tailwind-merge';

export const RadioGroup = ({ children, ...props }: PropsWithChildren<RadixRadioGroup.RadioGroupProps>) => {
  return <RadixRadioGroup.Root {...props}>{children}</RadixRadioGroup.Root>;
};

type RadioProps = {
  indicatorClassName?: string;
  itemClassName?: string;
} & RadixRadioGroup.RadioGroupItemProps;

const Radio = ({
  children,
  className,
  id,
  indicatorClassName,
  itemClassName,
  ...props
}: PropsWithChildren<RadioProps>) => {
  return (
    <div className={twMerge('flex items-center gap-2', className)}>
      <RadixRadioGroup.Item
        className={twMerge(
          'size-5 cursor-default rounded-full border border-corduroy-300  bg-white outline-none transition-colors hover:bg-corduroy-100',
          itemClassName,
        )}
        id={id}
        {...props}
      >
        <RadixRadioGroup.Indicator
          className={twMerge(
            "relative flex size-full items-center justify-center after:block after:size-full after:rounded-[50%] after:border-4 after:border-primary-900 after:content-['']",
            indicatorClassName,
          )}
        />
      </RadixRadioGroup.Item>
      <label className="text-sm/none" htmlFor={id}>
        {children}
      </label>
    </div>
  );
};

RadioGroup.Item = Radio;
