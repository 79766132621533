import type { AxiosError } from 'axios';

import type { PropsWithChildren } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAccount } from 'graz';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { useUser } from 'app/stores/user';
import { LoginModal } from 'features/LoginModal';
import { WalletProviderModal } from 'features/WalletProviderModal';
import { FaucetRequestState } from 'shared/api/faucet/types';
import { useRequestTokensMutation } from 'shared/api/faucet/useRequestTokensMutation';
import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';

import twitterSrc from '../assets/twitter-x.svg';

const Step = ({
  children,
  className,
  isCompleted,
}: PropsWithChildren<{ isCompleted?: boolean } & ClassName>) => {
  return (
    <div
      className={twMerge(
        'absolute left-4 top-3 flex size-8 items-center justify-center rounded-full bg-corduroy-100 p-2 font-semibold text-corduroy-600',
        isCompleted && 'bg-primary-200',
        className,
      )}
    >
      {isCompleted ? <Icon className="size-5 min-w-5 text-primary-800" name="check" /> : children}
    </div>
  );
};

const Title = ({ children, className }: PropsWithChildren<ClassName>) => {
  return <h2 className={twMerge('text-base font-medium md:text-lg', className)}>{children}</h2>;
};

export const FaucetContent = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isWalletProviderOpen, setIsWalletProviderOpen] = useState(false);
  const navigate = useNavigate();
  const { data: account } = useAccount();
  const { user } = useUser();

  const { isPending, mutateAsync: requestTokens } = useRequestTokensMutation();

  const twitterNotVerified = !user?.verification?.twitter?.id;
  const hasToConnectWallet = !account?.bech32Address;
  const canRequestTokens = !hasToConnectWallet;

  const handleRequestToken = async () => {
    try {
      if (!user) return toast.error('You are not logged in');
      if (!account?.bech32Address) return toast.error('No wallet connected');

      const { error, state, tokenAmount } = await requestTokens({
        address: account.bech32Address,
        chain: 'Testnet',
      });

      if (error) {
        toast.info(error);
      } else if (state === FaucetRequestState.complete) {
        const tokenAmountNumber = Number(tokenAmount) / Math.pow(10, nesaTestnet.currencies[0].coinDecimals);

        toast.info(`${tokenAmountNumber} NES sent to your wallet!`);
      } else if (state === FaucetRequestState.queued) {
        const tokenAmountNumber = Number(tokenAmount) / Math.pow(10, nesaTestnet.currencies[0].coinDecimals);

        toast.info(`${tokenAmountNumber} NES will be delivered soon!`);
      }
    } catch (e) {
      console.log('e', e);
      toast.error(
        ((e as AxiosError)?.response?.data as { message?: string })?.message || 'Something went wrong',
      );
    }
  };

  return (
    <>
      <div className="mx-auto -mt-7 flex w-full max-w-3xl flex-col gap-6 px-2">
        <Card className={twMerge('relative flex flex-col items-center justify-center gap-6 p-4')}>
          <Step isCompleted={!twitterNotVerified}>1</Step>

          <Title>Authenticate (Optional)</Title>

          <p className="text-center text-corduroy-600 ">
            {user ? (
              <>Link your twitter account to get 2X tokens</>
            ) : (
              'Login & Authenticate your twitter account'
            )}
          </p>

          {user ? (
            <Button
              className=" flex w-full gap-3 border-none bg-[#1D9BF0]"
              disabled={!twitterNotVerified}
              onClick={() => navigate('/settings')}
              size="medium"
            >
              <img className="size-6" src={twitterSrc} />
              Authenticate
            </Button>
          ) : (
            <Button
              className=" flex w-full gap-3"
              disabled={!twitterNotVerified}
              onClick={() => setIsLoginOpen(true)}
              size="medium"
            >
              Login
            </Button>
          )}
        </Card>
        <Card
          className={twMerge(
            'relative flex flex-col items-center justify-center gap-6 p-4',
            // !canRequestTokens && 'brightness-90',
          )}
        >
          <Step>2</Step>

          <Title>Your Nesa Wallet Address</Title>

          {account?.bech32Address && (
            <Input
              disabled={!canRequestTokens}
              placeholder="Input Nesa wallet address to receive token"
              readOnly
              value={account?.bech32Address || ''}
            />
          )}

          {hasToConnectWallet ? (
            <Button
              className="w-full"
              disabled={!hasToConnectWallet}
              onClick={() => setIsWalletProviderOpen(true)}
              size="medium"
              variant="filled"
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              className="w-full"
              disabled={!canRequestTokens}
              isLoading={isPending}
              onClick={handleRequestToken}
              size="medium"
            >
              Request{twitterNotVerified === false && ' (2X)'} Tokens
            </Button>
          )}
        </Card>

        <LoginModal isOpen={isLoginOpen} onOpenChange={setIsLoginOpen} />
        <WalletProviderModal onOpenChange={setIsWalletProviderOpen} open={isWalletProviderOpen} />
      </div>
    </>
  );
};
