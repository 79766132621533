import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { GetModelTypesParams, ModelTypeListResponse } from './types';

import { modelsKeys } from './queryKeys';

export const useGetModelTypesQuery = (
  params: GetModelTypesParams = {},
  options: UseQueryOptions<ModelTypeListResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<ModelTypeListResponse>(`/models/types`, {
        params,
        signal,
      });

      return data;
    },
    queryKey: modelsKeys.list(params),
    staleTime: Infinity,
    ...options,
  });
};
