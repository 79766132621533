export const getErrorMessage = (error: string) => {
  if (error.includes('agent not found by model name')) {
    return { text: 'Model is not supported. Please try another one.', type: 'agentNotFound' };
  }

  if (error.includes('request rejected')) {
    return { text: 'Request rejected', type: 'userRejected' };
  }

  if (error.includes(' does not exist on chain')) {
    return {
      text: `You don’t have any NES in your wallet. Go to the Faucet to get some.`,
      type: 'existsOnChain',
    };
  }

  if (error.includes('insufficient coin balance')) {
    return {
      text: 'Your wallet needs more tokens. You can get them in the Faucet.',
      type: 'insufficientBalance',
    };
  }

  return { text: 'The network is experiencing high traffic. Please try again later.', type: 'default' };
};
