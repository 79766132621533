import { AnimatePresence, motion } from 'framer-motion';
import Lottie from 'lottie-react';
import { twJoin } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';

// import aggregatingAnimaiton from '../assets/aggregating.json';
import conductingAnimation from '../assets/conducting.json';
// import deliveringAnimation from '../assets/delivering.json';
// import encryptingAnimation from '../assets/encrypting.json';
// import fetchingAnimation from '../assets/fetching.json';
// import loadingAnimation from '../assets/loading.json';
import sendingAnimation from '../assets/sending.json';
// import statusCompletedIcon from '../assets/status-completed.svg?react';
import statusConnectedChainIcon from '../assets/status-connected-chain.svg?react';
import statusConnectingValidatorIcon from '../assets/status-connecting-validator.svg?react';
import statusErrorIcon from '../assets/status-error.svg?react';
import statusInferenceValidatorIcon from '../assets/status-inference-validator.svg?react';
import statusReceivingResponseIcon from '../assets/status-receiving-response.svg?react';
import statusWaitingForQueryIcon from '../assets/status-waiting-for-query.svg?react';
import successAnimation from '../assets/success.json';
import { ERROR_CODES } from '../config';

export type Step = {
  animation?: Record<string, unknown>;
  icon?: React.FunctionComponent<
    {
      title?: string | undefined;
    } & React.SVGProps<SVGSVGElement>
  >;
  id: string;
  repeat?: number;
  text: string;
};

export const STEPS: Partial<Record<string, Step>> = {
  200: {
    animation: successAnimation,
    id: 'success',
    text: 'Completed',
  },

  300: {
    icon: statusConnectedChainIcon,
    id: 'connecting-chain',
    repeat: Infinity,
    text: 'Connecting to Nesa chain',
  },
  301: {
    icon: statusConnectedChainIcon,
    id: 'connected-chain',
    text: 'Connected to Nesa chain',
  },
  302: {
    icon: statusInferenceValidatorIcon,
    id: 'inference-validator',
    repeat: Infinity,
    text: 'Choosing an inference validator',
  },
  303: {
    icon: statusConnectingValidatorIcon,
    id: 'connecting-validator',
    repeat: Infinity,
    text: 'Connecting to the validator',
  },
  304: {
    icon: statusWaitingForQueryIcon,
    id: 'waiting-for-query',
    text: 'Waiting for query',
  },

  305: {
    animation: conductingAnimation,
    id: 'conducting',
    repeat: Infinity,
    text: 'Conducting inference',
  },
  306: {
    icon: statusReceivingResponseIcon,
    id: 'receiving-response',
    repeat: Infinity,
    text: 'Receiving responses',
  },
  307: {
    animation: sendingAnimation,
    id: 'sending',
    text: 'Task completed, wait for another query',
  },
  311: {
    icon: statusErrorIcon,
    id: 'lockAmount',
    text: 'LockAmount cannot be less than x, LockAmount check',
  },
  312: {
    icon: statusErrorIcon,
    id: 'register-failed',
    text: 'Sign register session failed.',
  },
  313: {
    icon: statusErrorIcon,
    id: 'session-error',
    text: 'Register session error',
  },
  314: {
    icon: statusErrorIcon,
    id: 'params-format-error',
    text: 'Chain params format error',
  },
  315: {
    icon: statusErrorIcon,
    id: 'params-error',
    text: 'Chain params error',
  },
  316: {
    icon: statusErrorIcon,
    id: 'sdk-error',
    text: 'SDK client init error',
  },
  317: {
    icon: statusErrorIcon,
    id: 'wallet-connect-error',
    text: 'Wallet connect error',
  },
  318: {
    icon: statusErrorIcon,
    id: 'wallet-connect-error2',
    text: 'Wallet connect error',
  },
};

type Props = {
  code: null | number;
  errorMessage: null | string;
};

export const ProgressStep = ({ code, errorMessage }: Props) => {
  const activeStep = typeof code === 'number' && STEPS[code];

  return (
    <AnimatePresence>
      {activeStep ? (
        <motion.div
          animate={{ opacity: 1 }}
          className={twJoin(
            'relative flex items-center justify-between gap-4 rounded-lg p-2',
            ERROR_CODES.includes(code) ? 'bg-red-800/15' : 'bg-primary-100',
          )}
          initial={{ opacity: 0 }}
        >
          <div className="flex w-full items-center gap-2">
            <div className="relative flex size-12 items-center justify-center overflow-hidden rounded-lg bg-white/90">
              {activeStep.animation && (
                <AnimatePresence>
                  <motion.div
                    animate={{ opacity: 1 }}
                    className="absolute size-full overflow-hidden "
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0 }}
                    key={activeStep.id}
                  >
                    <Lottie animationData={activeStep.animation} loop={activeStep.repeat === Infinity} />
                  </motion.div>
                </AnimatePresence>
              )}
              <AnimatePresence>
                {activeStep.icon && (
                  <motion.div
                    animate={ERROR_CODES.includes(code) ? undefined : { opacity: 0.5, scale: 0.9 }}
                    className="absolute"
                    key={activeStep.id}
                    transition={
                      ERROR_CODES.includes(code)
                        ? undefined
                        : { duration: 0.7, repeat: activeStep.repeat ?? 2, repeatType: 'mirror' }
                    }
                  >
                    <activeStep.icon className="size-7" />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="flex flex-1 flex-col gap-0.5">
              <AnimatePresence>
                <div className="relative h-5 min-w-fit text-base font-semibold">
                  <motion.div
                    animate={{ opacity: 1 }}
                    className="absolute top-1/2 -translate-y-1/2 text-sm/none font-semibold md:text-base/none"
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0 }}
                    key={activeStep.id}
                  >
                    {errorMessage || activeStep.text}
                  </motion.div>
                </div>
              </AnimatePresence>
            </div>
          </div>

          {!ERROR_CODES.includes(code) && (
            <div className="flex min-w-fit items-center gap-1 rounded-lg bg-white/60 p-2">
              <Icon className="size-6 text-primary-800" name="infoCircle" />

              <div className="flex flex-col gap-0">
                <span className="text-sm/none font-semibold">5 sec</span>
                <span className="text-xs text-corduroy-500">Estimated time</span>
              </div>
            </div>
          )}
        </motion.div>
      ) : (
        <motion.div
          animate={{ opacity: 1 }}
          className="flex h-16 items-center gap-1 rounded-lg bg-corduroy-200 p-4 text-xs font-semibold text-corduroy-600"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
        >
          <Icon name="infoCircle" />
          No pending inference
        </motion.div>
      )}
    </AnimatePresence>
  );
};
