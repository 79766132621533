import { twJoin, twMerge } from 'tailwind-merge';

import { theme } from 'app/theme';
import { Icon } from 'shared/ui/Icon';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  className?: string;
  isLoading?: boolean;
  latency?: number;
  likes?: number;
  price?: number;
  reviewCount?: number;
};

export const GalleryCardBottomInfo = ({ className, isLoading, latency, likes, reviewCount }: Props) => {
  const baseClassName = 'relative mr-2 flex min-h-5 max-w-fit items-center text-xs font-normal';
  return (
    <div className={twMerge('flex flex-wrap justify-between gap-3 xs:gap-2', className)}>
      <div className="flex w-9 gap-2">
        <Tooltip content="reviews" side="top">
          <div className={twMerge(baseClassName, isLoading && 'min-w-9')}>
            <StretchedSkeleton enable={isLoading} rx={12} ry={12} />

            {!isLoading && (
              <div className="group flex items-center gap-1 text-black">
                <Icon className="size-4 text-blue-800" name="messageSquare" />

                <span className="mt-px">{reviewCount || 0}</span>
              </div>
            )}
          </div>
        </Tooltip>

        <Tooltip content="likes" side="top">
          <div className={twJoin(baseClassName, isLoading && 'min-w-20')}>
            <StretchedSkeleton enable={isLoading} rx={12} ry={12} />

            {!isLoading && (
              <div className="group flex items-center gap-1 text-black">
                <Icon className="size-4 text-blue-800" name="star" />

                <span className="mt-px">{likes || 0}</span>
              </div>
            )}
          </div>
        </Tooltip>
      </div>

      <Tooltip content="latency" side="top">
        <div className={twJoin(baseClassName, isLoading && 'min-w-24')}>
          <StretchedSkeleton className="min-w-24" enable={isLoading} rx={12} ry={12} />

          {!isLoading && (
            <div className="group flex items-center gap-1 text-black">
              <Icon
                className="size-4 text-transparent"
                name="boltSolid"
                svgProps={{ stroke: theme.colors.blue[800], strokeWidth: 1.2 }}
              />

              <span className="mt-px">{`< ${latency || 3}sec avg`}</span>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
