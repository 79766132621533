import { useEffect, useMemo, useRef, useState } from 'react';

import { useClickAway } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
// eslint-disable-next-line import/default
import mapboxgl from 'mapbox-gl';

import { ComingSoonModal } from 'features/ComingSoonModal';
import { uuidv4 } from 'shared/helpers/uuid';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Icon } from 'shared/ui/Icon';

import { chips, geojson } from './config';

export const Map = () => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const [isNodeOpen, setIsNodeOpen] = useState(false);
  const [location, setLocation] = useState<string>();
  const [isComingOpen, setIsComingOpen] = useState(false);

  const ref = useClickAway<HTMLDivElement>(() => {
    setIsNodeOpen(false);
  });

  const randomDevice = useMemo(() => {
    return { chip: chips[Math.floor(Math.random() * chips.length)], id: uuidv4() };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      setIsComingOpen(true);
    }, 7000);
  }, []);

  useEffect(() => {
    if (map.current || !mapContainer.current) return; // initialize map only once

    // eslint-disable-next-line import/no-named-as-default-member
    map.current = new mapboxgl.Map({
      center: [10, 37.8],
      // center: [-121.403732, 40.492392],
      container: mapContainer.current,
      doubleClickZoom: false,
      scrollZoom: false,
      style: 'mapbox://styles/mapbox/light-v10',
      zoom: 3,
    });

    map.current.on('load', () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map.current?.addSource('devices', { data: geojson as unknown as any, type: 'geojson' });
    });

    // add markers to map
    for (const feature of geojson.features) {
      // create a HTML element for each feature
      const el = document.createElement('div');
      el.className = 'marker';

      // make a marker for each feature and add to the map
      // eslint-disable-next-line import/no-named-as-default-member
      const marker = new mapboxgl.Marker(el)
        .setLngLat(feature.geometry.coordinates as [number, number])
        .addTo(map.current);
      // new mapboxgl.Marker(el).setLngLat([-70.9912, 42.3305]).addTo(map.current);
      // new mapboxgl.Marker(el).setLngLat(feature.geometry.coordinates).addTo(map.current);

      marker.getElement().addEventListener('click', () => {
        setLocation(feature.properties.description);
        setIsNodeOpen(true);
      });
    }
  }, []);

  // TODO: fix max height/max wight!
  return (
    <AnimateRoute className="relative flex grow flex-col overflow-hidden">
      <div
        className="absolute inset-y-0 size-full max-h-[calc(100vh-var(--header-height))] grow overflow-hidden"
        id="map"
        ref={mapContainer}
      />

      <AnimatePresence>
        {isNodeOpen && (
          <motion.div
            animate={{ opacity: 1 }}
            className="absolute inset-0 bg-corduroy-900/30"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <motion.div
              animate={{ left: 12, opacity: 1 }}
              className="absolute inset-x-3 top-3 z-10 flex max-w-xl flex-col overflow-hidden rounded-3xl bg-corduroy-50 sm:right-auto sm:w-full"
              exit={{ left: 100, opacity: 0 }}
              initial={{ left: 100, opacity: 0 }}
              ref={ref}
            >
              <div className="flex items-center gap-1 border-b border-corduroy-200 bg-white px-5 py-3">
                <Icon className="text-corduroy-600" name="location" /> {location}
                <Icon
                  className="absolute right-2 cursor-pointer text-corduroy-600 transition-colors hover:text-primary-900"
                  name="close"
                  onClick={() => {
                    setIsNodeOpen(false);
                  }}
                />
              </div>

              <div className="flex flex-col px-5  py-3">
                <div className="flex items-center justify-between gap-2 rounded-2xl bg-white p-2 drop-shadow-smAll">
                  <div className="flex flex-1 items-center gap-2">
                    <div className="size-6 min-w-6 rounded-full border-4 border-primary-100 bg-primary-800"></div>

                    <div>Device ID: {randomDevice.id}</div>
                  </div>

                  <div className="flex items-center gap-1 rounded-xl bg-[#EEEFF3] py-1 pl-1 pr-3">
                    <Icon className="size-6 text-corduroy-500" name="cpu" />

                    <div className="flex flex-col">
                      <div className="whitespace-nowrap text-sm text-corduroy-900">
                        {randomDevice.chip.model}
                      </div>
                      <div className="text-xs font-light text-corduroy-500">{randomDevice.chip.name}</div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* <NodeModel location={location} onOpenChange={setIsNodeOpen} open={isNodeOpen} /> */}

      <ComingSoonModal
        isOpen={isComingOpen}
        text="The Nesa global map will be available at mainnet. Check back soon."
      />
    </AnimateRoute>
  );
};
