import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { ExternalLink } from 'app/ui/ExternalLink';

type Props = ClassName;

export const TermsLinks = ({ className }: Props) => {
  return (
    <div className={twMerge('flex gap-2', className)}>
      <ExternalLink target="_blank" to="https://www.legacy.nesa.ai/terms">
        Terms of use
      </ExternalLink>
      <div className="h-4 w-px bg-corduroy-200" />
      <ExternalLink target="_blank" to="https://www.legacy.nesa.ai/privacypolicy">
        Privacy Policy
      </ExternalLink>
    </div>
  );
};
