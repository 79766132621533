import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { RequestResetPasswordParams } from './types';

export const useRequestResetPasswordMutation = (
  options: UseMutationOptions<null, Error, RequestResetPasswordParams> = {},
) => {
  return useMutation({
    mutationFn: async (params: RequestResetPasswordParams) => {
      const { data } = await axiosInstance.post<null>('/auth/reset_password', params);

      return data;
    },
    ...options,
  });
};
