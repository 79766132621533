import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

export const useDeleteReviewMutation = (options: UseMutationOptions<null, Error, { id: string }> = {}) => {
  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const { data } = await axiosInstance.delete<null>(`/reviews/${id}`);

      return data;
    },
    ...options,
  });
};
