import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { FaucetRequestResponse, RequestTokensParams } from './types';

export const useRequestTokensMutation = (
  options: UseMutationOptions<FaucetRequestResponse, Error, RequestTokensParams> = {},
) => {
  return useMutation({
    mutationFn: async ({ address }: RequestTokensParams) => {
      const { data } = await axiosInstance.post<FaucetRequestResponse>(
        `/faucet`,
        JSON.stringify({ walletAddress: address }),
      );

      return data;
    },
    ...options,
  });
};
