export const modelsKeys = {
  base: ['models'],
  list: (params: Record<string, boolean | null | number | string | string[]>) => [
    ...modelsKeys.base,
    'types',
    params,
  ],
  previewList: (params: Record<string, null | number | string>) => [
    ...modelsKeys.base,
    'types',
    'preview',
    params,
  ],
  types: () => [...modelsKeys.base, 'types'],
};
