import { fromBase64 } from '@cosmjs/encoding';

export const getReceivedAmount = (
  events?: { attributes: { key: string; value: string }[]; type: string }[],
) => {
  if (!events) return '0';

  const value = events
    .find((x) => x.type === 'coin_received')
    ?.attributes.filter((x) => ['YW1vdW50', 'amount'].includes(x.key))
    .map((x) =>
      (x.key === 'amount' ? x.value : String.fromCharCode(...fromBase64(x.value))).replace('unes', ''),
    )?.[0];

  if (!value) {
    return '0';
  }

  return value;
};
