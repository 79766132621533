import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { CreateQueryHistoryParams, QueryHistory } from './types';

export const useCreateQueryHistoryMutation = (
  options: UseMutationOptions<QueryHistory, Error, CreateQueryHistoryParams> = {},
) => {
  return useMutation({
    mutationFn: async (params: CreateQueryHistoryParams) => {
      const { data } = await axiosInstance.post<QueryHistory>(`/query-history`, params);

      return data;
    },
    ...options,
  });
};
