import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { CreateReviewParams, CreateReviewResponse } from './types';

export const useCreateReviewMutation = (
  options: UseMutationOptions<CreateReviewResponse, Error, CreateReviewParams> = {},
) => {
  return useMutation({
    mutationFn: async ({ modelId, ...params }: CreateReviewParams) => {
      const { data } = await axiosInstance.post<CreateReviewResponse>(`/reviews/models/${modelId}`, params);

      return data;
    },
    ...options,
  });
};
