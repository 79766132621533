import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = {
  currentReviewsCount: number;
  onReviewsClick?: () => void;
  rating: number;
  reviewsCount: number;
} & ClassName;

export const StarRow = ({ className, currentReviewsCount, onReviewsClick, rating, reviewsCount }: Props) => {
  return (
    <div className={twMerge('flex items-center gap-3', className)}>
      <div className="min-w-fit font-light text-corduroy-700">{rating} star</div>

      <div className="relative h-1.5 w-full overflow-hidden rounded-full bg-corduroy-200">
        <div
          className="absolute left-0 h-full rounded-full bg-primary-800"
          style={{ width: `${(currentReviewsCount * 100) / reviewsCount}%` }}
        ></div>
      </div>

      <div
        className="w-fit min-w-28 cursor-pointer text-sm text-corduroy-500 underline transition-colors hover:text-corduroy-600"
        onClick={onReviewsClick}
      >
        {currentReviewsCount} reviews
      </div>
    </div>
  );
};
