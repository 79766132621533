import { type InputHTMLAttributes, type ReactNode, forwardRef } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Label } from '../Label';

export type InputProps = {
  classNameInput?: string;
  classNameInputWrapper?: string;
  endSlot?: ReactNode;
  error?: string;
  errorSpacing?: boolean;
  label?: string;
  size?: 'medium' | 'small';
  startSlot?: ReactNode;
} & ClassName &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      classNameInput,
      classNameInputWrapper,
      disabled,
      endSlot: EndSlot,
      error,
      errorSpacing,
      label,
      size = 'small',
      startSlot: StartSlot,
      ...inputProps
    }: InputProps,
    ref,
  ) => {
    return (
      <div className={twMerge('relative flex w-full flex-col', errorSpacing && 'pb-4', className)}>
        {label && <Label>{label}</Label>}
        <div
          className={twMerge(
            'flex w-full max-w-full items-center rounded-lg bg-white px-5 font-display font-normal transition-colors',
            // 'outline outline-1 outline-offset-0 outline-transparent',
            'border border-corduroy-200 outline outline-1 outline-offset-0 outline-transparent',
            'focus-within:border-corduroy-300 hover:border-corduroy-300',
            !!error && 'border-pink-500 focus-within:border-pink-500 hover:border-pink-500',
            !!StartSlot && 'pl-2',
            !!EndSlot && 'pr-2',
            disabled && 'pointer-events-none cursor-not-allowed bg-corduroy-200',
            size === 'small' && 'h-10 text-sm lg:text-base 2xl:h-10',
            size === 'medium' && 'h-12 text-sm',
            classNameInputWrapper,
          )}
        >
          {StartSlot && <div className="mr-2 inline-flex select-none">{StartSlot}</div>}

          <input
            {...inputProps}
            className={twMerge(
              'h-full w-inherit bg-white/0 text-corduroy-900 placeholder:font-light placeholder:leading-none placeholder:text-corduroy-400 focus:outline-none',
              classNameInput,
            )}
            disabled={disabled}
            ref={ref}
          />

          {EndSlot && <div className="ml-2 inline-flex min-w-max select-none">{EndSlot}</div>}
        </div>
        {error && (
          <div className="absolute bottom-3 translate-y-full text-[10px] leading-none text-pink-500">
            {error}
          </div>
        )}
      </div>
    );
  },
);
