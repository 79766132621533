import { useState } from 'react';

import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

import {
  anchorCards,
  applications,
  developerResourcesCards,
  developerResourcesCards2,
  technologyPartners,
} from './config';
import { Card, CardDescription, CardLink, CardTitle } from './ui/Card';
import { LayoutWrapper } from './ui/LayoutWrapper';
import arrowSrc from './ui/assets/arrow.svg';
import chatIconSrc from './ui/assets/chat.svg';
import heroBg from './ui/assets/hero.png';

export const Ecosystem = () => {
  const [selectedDAppTab] = useState('all');

  return (
    <AnimateRoute className="relative mx-2 mt-10 flex-1 py-6 md:mr-4 md:mt-0 lg:py-16">
      <div className="absolute top-0 z-0 h-80 w-full overflow-hidden rounded-2xl bg-black">
        <img className="absolute right-0 max-h-80 opacity-30" src={heroBg} />
      </div>
      <LayoutWrapper className="relative">
        <h1 className="text-left font-klapt text-4xl font-semibold uppercase text-white lg:text-7xl">
          Nesa Ecosystem
        </h1>
        <p className="mb-6 mt-4 text-left text-xl font-light text-white lg:mb-10 lg:mt-6 lg:text-2xl">
          Discover the decentralized world of Nesa.
        </p>
        <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
          {anchorCards.map((el, i) => (
            <Card
              as="a"
              className="row-span-3 grid cursor-pointer grid-rows-subgrid gap-0 bg-tusk-100 transition-all duration-200 hover:brightness-90"
              corners
              href={el.link}
              key={i}
              rel={el.link.startsWith('http') ? 'noreferrer' : undefined}
              target={el.link.startsWith('http') ? '_blank' : undefined}
            >
              <CardTitle className="text-steel-1000">{el.title}</CardTitle>
              <CardDescription>{el.description}</CardDescription>
              <div className="mt-6 flex items-center gap-1">
                <span className="text-base font-light uppercase">{el.linkText}</span>
                <Icon className="size-4 -rotate-90 text-black" name="arrowDownLongSm" />
              </div>
            </Card>
          ))}
        </div>
      </LayoutWrapper>
      <LayoutWrapper className="relative mt-16 lg:mt-24" id="applications">
        <h2 className="text-center font-klapt text-3xl font-semibold text-corduroy-900 lg:text-5xl">
          Applications on Nesa
        </h2>
        <p className="mb-12 mt-4 text-center text-lg font-light text-corduroy-800 lg:text-xl">
          Explore select applications building on Nesa or running AI inference on the network.
        </p>
        {/* <div className="mb-12 flex justify-center gap-1">
          {applicationTabs.map((tab) => (
            <ButtonTab
              key={tab.value}
              onClick={() => setSelectedDAppTab(tab.value)}
              selected={tab.value === selectedDAppTab}
            >
              {tab.title}
            </ButtonTab>
          ))}
        </div> */}
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {applications
            .filter((el) => selectedDAppTab === 'all' || el.type === selectedDAppTab)
            .map((el) => (
              <Card
                className="row-span-4 grid grid-rows-subgrid gap-0 border border-[#DCEEED] bg-white"
                key={el.icon}
              >
                <div className="mb-6">
                  <img alt="" className="size-28 lg:size-20" src={el.icon} />
                </div>
                <CardTitle>{el.title}</CardTitle>
                <CardDescription>{el.description}</CardDescription>
                {el.link ? (
                  <CardLink href={el.link} rel="noreferrer" target="_blank">
                    Explore
                  </CardLink>
                ) : (
                  <div />
                )}
              </Card>
            ))}
        </div>
        <div className="mt-14 flex justify-center">
          <Button
            as="a"
            className="pl-2 text-black"
            color="primary"
            href="http://docs.nesa.ai/"
            rel="noreferrer"
            size="medium"
            target="_blank"
            variant="filled-light"
          >
            <div className="mr-1 flex size-11 items-center justify-center rounded-full bg-primary-800">
              <img src={arrowSrc} />
            </div>
            Integrate with Nesa
          </Button>
        </div>
      </LayoutWrapper>
      <LayoutWrapper className="relative mt-20 lg:mt-28" id="start">
        <h2 className="text-center font-klapt text-5xl font-semibold text-corduroy-900">
          Developer resources
        </h2>

        <div className="mb-10 mt-16 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {developerResourcesCards.map((el, i) => (
            <Card
              className="row-span-4 grid grid-rows-subgrid gap-0 border border-[#D7E5E4] bg-white"
              key={i}
            >
              <div className="mb-6 lg:mb-8">{el.icon}</div>
              <CardTitle>{el.title}</CardTitle>
              <CardDescription className="text-corduroy-600">{el.description}</CardDescription>
              <CardLink href={el.link} rel="noreferrer" target="_blank">
                {el.linkText}
              </CardLink>
            </Card>
          ))}
        </div>

        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
          {developerResourcesCards2.map((el, i) => (
            <Card
              className="row-span-3 grid grid-rows-subgrid gap-0 border border-primary-800 bg-primary-40 2xl:px-8 2xl:py-10"
              key={i}
            >
              <div className="text-3xl font-semibold text-corduroy-900 2xl:text-4xl">{el.title}</div>
              <div className="mb-10 mt-4 text-lg font-light text-corduroy-800 2xl:text-lg">
                {el.description}
              </div>
              <div>
                <Button
                  as="a"
                  className="pl-1 text-black"
                  color="primary"
                  href={el.link}
                  rel="noreferrer"
                  size="medium"
                  target="_blank"
                  variant="filled-light"
                >
                  <div className="mr-1 flex size-11 items-center justify-center rounded-full bg-primary-800">
                    <img src={arrowSrc} />
                  </div>
                  {el.linkText}
                </Button>
              </div>
            </Card>
          ))}
        </div>
      </LayoutWrapper>
      <LayoutWrapper className="relative mt-20 lg:mt-28">
        <h2 className="text-center font-klapt text-5xl font-semibold text-corduroy-900">
          Technology Partners
        </h2>
        <p className="mb-12 mt-4 text-center text-lg font-light text-corduroy-800 lg:text-xl">
          The Nesa network is supported by great infrastructure.
        </p>
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {technologyPartners.map((el) => (
            <Card
              className="row-span-4 grid grid-rows-subgrid gap-0 bg-corduroy-50 drop-shadow-smAll"
              key={el.icon}
            >
              <img alt="" className="mb-6 size-20" src={el.icon} />
              <CardTitle className="font-display">{el.title}</CardTitle>
              <CardDescription className="whitespace-pre-line text-base/5 text-corduroy-700">
                {el.description}
              </CardDescription>
              <CardLink href={el.link} rel="noreferrer" target="_blank">
                Explore
              </CardLink>
            </Card>
          ))}
        </div>
      </LayoutWrapper>
      <LayoutWrapper className="mt-20 lg:mt-28">
        <div className="flex flex-col items-start gap-6 rounded-3xl bg-white p-6 drop-shadow-smAll md:flex-row md:items-center lg:px-8 lg:py-7">
          <div className="flex size-16 items-center justify-center rounded-full rounded-bl-none bg-primary-800">
            <img src={chatIconSrc} />
          </div>
          <div className="flex-1">
            <div className="text-3xl font-normal text-black">Explore your options</div>
            <div className="mt-1 text-lg font-light text-corduroy-800">
              Contact us to find solutions for your app{`'`}s needs.
            </div>
          </div>
          <Button
            as="a"
            className="pl-1 text-black"
            color="primary"
            href="http://docs.nesa.ai/"
            rel="noreferrer"
            size="medium"
            target="_blank"
            variant="filled-light"
          >
            <div className="mr-1 flex size-11 items-center justify-center rounded-full bg-primary-800">
              <img src={arrowSrc} />
            </div>
            Get In Touch
          </Button>
        </div>
      </LayoutWrapper>
    </AnimateRoute>
  );
};
