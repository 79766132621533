import { useMediaQuery } from '@uidotdev/usehooks';

import { theme } from 'app/theme';

export const useMinWidthMediaQuery = (breakpoint: keyof (typeof theme)['screens']) => {
  return useMediaQuery(`(min-width: ${theme.screens[breakpoint]})`);
};

export const useMaxWidthMediaQuery = (breakpoint: keyof (typeof theme)['screens']) => {
  return useMediaQuery(`(max-width: ${theme.screens[breakpoint]})`);
};
