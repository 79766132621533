import type { Model } from 'shared/api/models/types';
import type { TableColumn } from 'shared/ui/Table';

import { formatNumber } from 'shared/helpers/formatNumber';

export const columns: TableColumn<keyof Model, Model>[] = [
  {
    key: 'name',
    renderTd: ({ name }) => {
      const nameToSplit = name.includes('/') ? name : `/${name}`;
      const [title, des] = nameToSplit.split('/');

      return (
        <>
          <div className="text-xs font-semibold text-corduroy-500">{title}</div>
          <div className="text-base text-corduroy-900">{des}</div>
        </>
      );
    },
    thClassName: 'w-[40%]',
    title: 'Name',
    withSort: true,
  },
  { key: 'type', renderTd: ({ type }) => type, thClassName: 'w-[12%]', title: 'Category' },
  {
    key: 'ranking',
    renderTd: ({ ranking }) => (typeof ranking === 'number' ? ranking : '---'),
    thClassName: 'w-[12%]',
    title: 'Ranking',
    withSort: true,
  },
  {
    key: 'price',
    renderTd: ({ price }) => (typeof price === 'number' ? formatNumber(price, { decimals: 4 }) : '---'),
    thClassName: 'w-[12%]',
    title: 'NES',
    withSort: true,
  },
  {
    key: 'likes',
    renderTd: ({ likes }) => (typeof likes === 'number' ? formatNumber(likes, { decimals: 0 }) : '---'),
    thClassName: 'w-[12%]',
    title: 'Likes',
    withSort: true,
  },
];
