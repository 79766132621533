import type { HTMLMotionProps } from 'framer-motion';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useIntersectionObserver } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';

import type { Model } from 'shared/api/models/types';
import type { ClassName } from 'shared/types';

import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { GalleryCard } from 'shared/ui/GalleryCard';
import { Icon } from 'shared/ui/Icon';
import { Select } from 'shared/ui/Select';

import type { FilterState, SortFilter } from '../GalleryFilters/GalleryFilters';

type Props = {
  fetchNextPage: () => void;
  filters: FilterState;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  modelList: Model[] | undefined;
  onFilterChange: (filters: FilterState) => void;
} & ClassName &
  HTMLMotionProps<'div'>;

export const Gallery = ({
  fetchNextPage,
  filters,
  hasNextPage,
  isFetchingNextPage,
  modelList,
  onFilterChange,
}: Props) => {
  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const navigate = useNavigate();
  const itemsCount = modelList?.length ?? 0;
  const sm = useMinWidthMediaQuery('sm');

  const onSortChange = (value: SortFilter) => {
    onFilterChange({ sort: value });
  };

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  return (
    <div className="relative mt-3 grid max-h-full w-full grid-flow-dense grid-cols-1 justify-between  gap-2 overflow-y-scroll rounded-2xl bg-blue-50 p-2 scrollbar-none sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {!sm && (
        <div className="flex items-center justify-between">
          <span className="ml-2 text-base font-bold text-blue-900">Filter</span>
          <div className="right-4 box-border flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
            <Select
              className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
              iconClassName="mr-1"
              iconName="arrowDownUp"
              onValueChange={onSortChange}
              value={filters.sort ? filters.sort : undefined}
            >
              <Select.Content className="z-10 p-1">
                <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                  Sort by
                </div>
                <Select.Item
                  className="text-sm text-corduroy-750 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="newest"
                >
                  Newest
                </Select.Item>
                <Select.Item
                  className="border-b border-blue-50 text-sm text-corduroy-750 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="oldest"
                >
                  Oldest
                </Select.Item>
                <Select.Item
                  className="text-sm text-corduroy-750 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="name-lowest"
                >
                  Name Ascending
                </Select.Item>
                <Select.Item
                  className="border-b border-blue-50 text-sm text-corduroy-750 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="name-highest"
                >
                  Name Decending
                </Select.Item>
                <Select.Item
                  className="text-sm text-corduroy-750 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="ranking-highest"
                >
                  Most Rated
                </Select.Item>
                <Select.Item
                  className="text-sm text-corduroy-750 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="likes-highest"
                >
                  Most Likes
                </Select.Item>
                <Select.Item
                  className="border-b border-blue-50 text-sm text-corduroy-750 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="downloads-highest"
                >
                  Most Downloaded
                </Select.Item>
                <Select.Item
                  className="text-sm text-corduroy-750 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="price-highest"
                >
                  Price Highest
                </Select.Item>
                <Select.Item
                  className="text-sm text-corduroy-750 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="price-lowest"
                >
                  Price Lowest
                </Select.Item>
              </Select.Content>
            </Select>

            <Select
              className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
              disabled
              iconClassName="mr-1"
              iconName="filter"
            >
              <Select.Content className="z-10 px-2 pb-2 pt-1">
                <div className="mb-2 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                  Filter
                </div>

                <div className="flex w-full flex-col">
                  <div className="flex justify-between pb-1.5">
                    <div className="text-xs font-medium text-corduroy-650">Data Range</div>
                    <div className="text-xs font-medium text-primary-800">Reset</div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div className="h-3.5 text-[0.6875rem] font-medium text-gray-400">From</div>
                    <div className="h-9 rounded-lg border border-corduroy-150 px-2 py-2.5">hi</div>
                  </div>
                </div>

                {/* <hr className="border-t border-blue-50" /> */}

                <div className="mt-2 flex h-8 w-full justify-between">
                  <div className="flex items-center gap-2 rounded-lg bg-corduroy-25 pl-2.5 pr-3">
                    <Icon className="size-3 text-blue-800" name="refresh" />
                    <div className="mt-px text-[0.8125rem] font-medium text-blue-800">Reset all</div>
                  </div>

                  <div className="flex items-center gap-2 rounded-lg bg-primary-800 pl-2.5 pr-3">
                    <Icon className="size-3 text-tusk-100" name="check" />
                    <div className="mt-px text-[0.8125rem] font-semibold text-white">Apply</div>
                  </div>
                </div>
              </Select.Content>
            </Select>
          </div>
        </div>
      )}

      {itemsCount > 0 ? (
        modelList?.map((model, i) => {
          return (
            <AnimatePresence key={model._id}>
              <motion.div
                animate={{ opacity: 1 }}
                className="group/card cursor-pointer"
                initial={{ opacity: 0 }}
                key={model._id}
                onClick={() => {
                  navigate(`/models/${model._id}`, {
                    state: { imgSrc: model.image, latency: model.latency, price: model.price },
                  });
                }}
                ref={i === modelList.length - 2 ? ref : undefined}
              >
                <GalleryCard isDescriptionPreview model={model} />
              </motion.div>
            </AnimatePresence>
          );
        })
      ) : (
        <div className="py-5 text-center text-corduroy-600">No items found</div>
      )}
      {isFetchingNextPage && (
        <>
          <GalleryCard isLoading />
          <GalleryCard isLoading />
          <GalleryCard isLoading />
          <GalleryCard isLoading />
        </>
      )}
    </div>
  );
};
