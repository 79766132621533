import type { FormEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useLocalStorage } from '@uidotdev/usehooks';
import { AxiosError } from 'axios';

import type { ModelTypePreviewItem } from 'shared/api/models/types';

import { useUser } from 'app/stores/user';
import { useLogoutMutation } from 'shared/api/user/useLogoutMutation';
import { useSendEmailVerificationMutation } from 'shared/api/verification/useSendEmailVerificationMutation';
import { useVerifyDiscordMutation } from 'shared/api/verification/useVerifyDiscordMutation';
import { useVerifyTwitterMutation } from 'shared/api/verification/useVerifyTwitterMutation';
// import { useEmailVerificationMutation } from 'shared/api/verification/useEmailVerificationMutation';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';

type FormValues = {
  discord: string;
  email: string;
  firstName: string;
  lastName: string;
};

export const Settings = () => {
  const { isUpdating, updateUser, user } = useUser();

  const [, saveActiveCategory] = useLocalStorage<ModelTypePreviewItem | undefined>('activeCategory');
  const { mutateAsync: logout } = useLogoutMutation();

  const {
    formState: { errors, isValid },
    getValues,
    register,
  } = useForm<FormValues>({
    defaultValues: {
      email: user?.email || '',
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
    },
    mode: 'onChange',
  });

  const { isPending, mutateAsync } = useSendEmailVerificationMutation();
  const { isPending: isDiscordPending, mutateAsync: verifyDiscord } = useVerifyDiscordMutation();
  const { isPending: isTwitterPending, mutateAsync: verifyTwitter } = useVerifyTwitterMutation();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      e.preventDefault();

      const values = getValues();

      await updateUser({ first_name: values.firstName, last_name: values.lastName }, { sendRequest: true });

      toast.success('Changes are saved');
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toast.error(axiosError || 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handleVerifyEmail = async () => {
    try {
      if (!user) {
        return;
      }
      await mutateAsync();

      toast.success('Email is sent');
    } catch (e) {
      console.error('send error', e);
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toast.error(axiosError || 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <AnimateRoute className="p-6">
      <Card>
        <h2 className="mb-4 text-lg font-semibold">Settings</h2>

        <form className="grid grid-cols-2 gap-4" onSubmit={handleSubmit}>
          <Input
            endSlot={
              user?.verification?.email ? (
                <Icon className="text-primary-800" name="check" />
              ) : (
                <Button className="-mr-2" color="secondary" isLoading={isPending} onClick={handleVerifyEmail}>
                  Verify
                </Button>
              )
            }
            label="Email"
            readOnly
            value={user?.email}
          />
          <Input
            endSlot={
              user?.verification?.discord?.id ? (
                <Icon className="text-primary-800" name="check" />
              ) : (
                <Button
                  className="-mr-2"
                  color="secondary"
                  isLoading={isDiscordPending}
                  onClick={async () => {
                    const { link } = await verifyDiscord();
                    window.open(link, '_self');
                  }}
                >
                  Verify
                </Button>
              )
            }
            label="Discord"
            placeholder="Your discord username"
            readOnly
            value={user?.verification?.discord?.username || ''}
          />
          <Input
            endSlot={
              user?.verification?.twitter?.username ? (
                <Icon className="text-primary-800" name="check" />
              ) : (
                <Button
                  className="-mr-2"
                  color="secondary"
                  isLoading={isTwitterPending}
                  onClick={async () => {
                    const { link } = await verifyTwitter();
                    window.open(link, '_self');
                  }}
                >
                  Verify
                </Button>
              )
            }
            label="Twitter"
            placeholder="Your twitter username"
            readOnly
            value={user?.verification?.twitter?.username || ''}
          />

          <Input
            label="First name"
            placeholder="Alex"
            {...register('firstName')}
            error={errors.firstName?.message}
          />
          <Input
            label="Last name"
            placeholder="Smith"
            {...register('lastName')}
            error={errors.lastName?.message}
          />

          <div className="col-span-2 flex justify-between">
            <Button
              color="secondary"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();

                logout();

                updateUser();

                saveActiveCategory(undefined);
              }}
            >
              Log out
            </Button>
            <Button className="w-fit" disabled={!isValid} isLoading={isUpdating} type="submit">
              Save changes
            </Button>
          </div>
        </form>
      </Card>
    </AnimateRoute>
  );
};
