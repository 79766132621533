import { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Icon } from 'shared/ui/Icon';
import { JsonEditor } from 'shared/ui/JsonEditor';

type Props = {
  data: Record<string, unknown>;
} & ClassName;

export const JsonData = ({ className, data }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <AnimatePresence>
      <motion.div
        animate={{ gridTemplateRows: '1fr', opacity: 1 }}
        className={twMerge(
          'grid cursor-pointer grid-cols-1 gap-0 overflow-hidden rounded-lg border border-corduroy-100 bg-corduroy-50 px-3 py-2',
          className,
        )}
        exit={{ gridTemplateRows: '0fr', opacity: 0 }}
        initial={{ gridTemplateRows: '0fr', opacity: 0 }}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <motion.div className="flex h-9 items-center justify-between">
          <h5 className="text-base font-semibold text-corduroy-900">JSON data</h5>

          <Icon
            className={twMerge('text-corduroy-600 transition-transform', !isExpanded && 'rotate-180')}
            name="arrowDownSm"
          />
        </motion.div>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              animate={{ gridTemplateRows: '1fr', opacity: 1 }} //grid-template-rows
              className="grid overflow-hidden"
              exit={{ gridTemplateRows: '0fr', opacity: 0 }}
              initial={{ gridTemplateRows: '0fr', opacity: 0 }}
              transition={{ duration: 0.3, ease: 'linear' }}
            >
              <JsonEditor className="my-0 py-0" mode="preview" value={data} />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </AnimatePresence>
  );
};
