import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { GetQueryHistoryByUserParams, QueryListPaginatedResponse } from './types';

import { queryHistoryKeys } from './queryKeys';

export const useGetQueryHistoryByUserQuery = (
  { userId, ...params }: GetQueryHistoryByUserParams,
  options: UseQueryOptions<QueryListPaginatedResponse>,
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<QueryListPaginatedResponse>('/query-history', {
        params: { ...params, userId },
        signal,
      });

      return data;
    },
    queryKey: queryHistoryKeys.list({ ...params, type: 'user-based', userId }),
    ...options,
  });
};
