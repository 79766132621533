import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { GetQueryHistoryByPeriodParams, QueryHistory } from './types';

import { queryHistoryKeys } from './queryKeys';

export const useGetQueryHistoryByPeriod = (
  { userId, ...params }: GetQueryHistoryByPeriodParams,
  options: UseQueryOptions<QueryHistory[]>,
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<QueryHistory[]>('/query-history/by-period', {
        params,
        signal,
      });

      return data;
    },
    queryKey: queryHistoryKeys.byPeriod({ ...params, userId }),
    ...options,
  });
};
