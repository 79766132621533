import { useState } from 'react';

import { ComingSoonModal } from 'features/ComingSoonModal';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { ButtonTab } from 'shared/ui/ButtonTab';
import { Icon } from 'shared/ui/Icon';

import {
  anchorCards,
  dAppTabs,
  dApps,
  developerResourcesCards,
  developerResourcesCards2,
  servicesCards,
  servicesTabs,
} from './config';
import { Card, DescriptionCard, TitleCard } from './ui/Card';
import { DescriptionBlock } from './ui/DescriptionBlock';
import { GridContent } from './ui/GridContent';
import { LayoutWrapper } from './ui/LayoutWrapper';
import { TitleBlock } from './ui/TitleBlock';
import ArrowDownIcon from './ui/assets/arrow-down-small.svg?react';
import developerResourcesBgSrc from './ui/assets/developer-resources-bg.png';
import coverBgSrc from './ui/assets/main-bg.png';

export const BuildOnNesa = () => {
  const [selectedDAppTab, setSelectedDAppTab] = useState('all');
  const [selectedServicesTab, setSelectedServicesTab] = useState('all');

  return (
    <AnimateRoute>
      <div
        className="bg-steel-900 bg-cover bg-center bg-no-repeat py-12 lg:py-20 2xl:pb-20 2xl:pt-28"
        style={{ backgroundImage: `url(${coverBgSrc})` }}
      >
        <LayoutWrapper>
          <h1 className="mb-10 pl-2 text-center text-4xl font-normal text-white lg:text-6xl 2xl:text-7xl">
            Build on <span className="font-semibold">Nesa</span>
          </h1>
          <GridContent className="md:grid-cols-1">
            {anchorCards.map((el) => (
              <Card
                as={'a'}
                className="row-span-3 grid cursor-pointer grid-rows-subgrid gap-0 bg-steel-800 transition-all duration-200 hover:brightness-90"
                href={el.link}
                key={el.link}
              >
                <TitleCard className="text-primary-800">{el.title}</TitleCard>
                <DescriptionCard className="text-white">{el.description}</DescriptionCard>
                <div className="mt-6 flex items-center gap-1">
                  <span className="text-xs font-medium text-white 2xl:text-sm">{el.linkText}</span>
                  <ArrowDownIcon className="size-4 fill-primary-1000" />
                </div>
              </Card>
            ))}
          </GridContent>
        </LayoutWrapper>
      </div>

      <div className="bg-white py-16 2xl:py-20" id="choose">
        <LayoutWrapper>
          <TitleBlock>Choose a dApp</TitleBlock>
          <DescriptionBlock>
            Get started quickly by using Nesa with leading dApps and services on the network.
          </DescriptionBlock>
          <div className="mb-8 flex flex-wrap items-center justify-center gap-5 2xl:mb-12">
            {dAppTabs.map((el) => (
              <ButtonTab
                className="uppercase"
                key={el.value}
                onClick={() => setSelectedDAppTab(el.value)}
                selected={el.value === selectedDAppTab}
              >
                {el.label}
              </ButtonTab>
            ))}
          </div>
          <GridContent>
            {dApps
              .filter((el) => el.type === selectedDAppTab || selectedDAppTab === 'all')
              .map((el, i) => (
                <Card
                  className="row-span-4 grid grid-rows-subgrid gap-0 border border-corduroy-200 bg-white"
                  key={i}
                >
                  <img alt="" className="mb-6 size-16 lg:mb-8 lg:size-20" src={el.image} />
                  <TitleCard>{el.title}</TitleCard>
                  <DescriptionCard>{el.description}</DescriptionCard>
                  <div className="mt-8 flex flex-col items-start justify-end gap-2">
                    {el.isReady ? (
                      el.externalLinks.map((el, i) => (
                        <div className="flex cursor-pointer items-center gap-2" key={i}>
                          <span className="text-sm text-corduroy-800 2xl:text-base">{el.label}</span>
                          <Icon className="size-5 text-primary-1000 2xl:size-6" name="arrowUpRightLong" />
                        </div>
                      ))
                    ) : (
                      <div className="inline-block rounded-lg bg-primary-1000 px-1.5 py-1 text-xs text-white 2xl:text-sm">
                        Coming Soon
                      </div>
                    )}
                  </div>
                </Card>
              ))}
          </GridContent>
        </LayoutWrapper>
      </div>

      <div className="relative bg-white py-20 2xl:py-20" id="integrate">
        <div
          className="absolute inset-x-0 top-0 h-[21rem] bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${developerResourcesBgSrc})` }}
        />
        <LayoutWrapper className="relative z-10">
          <TitleBlock className="mb-16">Developer resources</TitleBlock>

          <GridContent className="mb-10 md:grid-cols-1">
            {developerResourcesCards.map((el, i) => (
              <Card
                className="row-span-4 grid grid-rows-subgrid gap-0 border border-corduroy-200 bg-white shadow-sm"
                key={i}
              >
                <div className="mb-6 lg:mb-8">{el.icon}</div>
                <TitleCard>{el.title}</TitleCard>
                <DescriptionCard>{el.description}</DescriptionCard>
                <div className="mt-8 flex cursor-pointer items-center gap-2">
                  <span className="text-sm text-corduroy-800 2xl:text-base">{el.linkText}</span>
                  <Icon className="size-5 text-primary-1000 2xl:size-6" name="arrowUpRightLong" />
                </div>
              </Card>
            ))}
          </GridContent>
          <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
            {developerResourcesCards2.map((el, i) => (
              <Card
                className="row-span-3 grid grid-rows-subgrid gap-0 bg-primary-100 2xl:px-8 2xl:py-10"
                key={i}
              >
                <div className="text-3xl font-semibold text-corduroy-900 2xl:text-4xl">{el.title}</div>
                <div className="mb-10 mt-4 text-base font-normal text-corduroy-800 2xl:text-lg">
                  {el.description}
                </div>
                <div className="">
                  <button className="rounded-lg bg-steel-900 px-3.5 py-2 font-semibold text-white transition-all hover:bg-steel-800">
                    {el.linkText}
                  </button>
                </div>
              </Card>
            ))}
          </div>
        </LayoutWrapper>
      </div>

      <div className="bg-white py-10 pb-20" id="deploy">
        <LayoutWrapper>
          <TitleBlock>Rollups-as-a-Service</TitleBlock>
          <DescriptionBlock>
            Deploy end-to-end on managed infrastructure using a Rollup-as-a-Service provider.
          </DescriptionBlock>
          <div className="mb-8 flex flex-wrap items-center justify-center gap-5 2xl:mb-12">
            {servicesTabs.map((el) => (
              <ButtonTab
                className="uppercase"
                key={el.value}
                onClick={() => setSelectedServicesTab(el.value)}
                selected={el.value === selectedServicesTab}
              >
                {el.label}
              </ButtonTab>
            ))}
          </div>

          <GridContent className="mb-20 md:grid-cols-1">
            {servicesCards
              .filter((el) => el.type === selectedServicesTab || selectedServicesTab === 'all')
              .map((el, i) => (
                <Card
                  className="row-span-4 grid grid-rows-subgrid gap-0 border border-corduroy-200 bg-corduroy-50 shadow-sm"
                  key={i}
                >
                  <img alt="" className="mb-6 size-16 lg:mb-8 lg:size-20" src={el.image} />
                  <TitleCard>{el.title}</TitleCard>
                  <DescriptionCard>{el.description}</DescriptionCard>
                  <div className="mt-8 flex cursor-pointer items-center gap-2">
                    <span className="text-sm text-corduroy-800 2xl:text-base">Explore</span>
                    <Icon className="size-5 text-primary-1000 2xl:size-6" name="arrowUpRightLong" />
                  </div>
                </Card>
              ))}
          </GridContent>

          <div className="flex flex-col items-start gap-6 rounded-2xl border border-[#D7F3EE] bg-[#F4FFFD] p-6 md:flex-row md:items-center 2xl:px-8 2xl:py-9">
            <div className=" flex size-[4.5rem] items-center justify-center rounded-2xl bg-[#CEF6F0]">
              <Icon className="size-8 text-primary-1000" name="commentDots" />
            </div>
            <div className="flex-1">
              <div className="text-xl font-semibold text-corduroy-900 2xl:text-2xl">Explore your options</div>
              <div className="mt-2 text-base text-corduroy-800 2xl:text-lg">
                Contact us to find solutions for your app{`'`}s needs.
              </div>
            </div>
            <button className="rounded-lg bg-steel-900 px-3.5 py-2 font-semibold text-white transition-all hover:bg-steel-800">
              Get In Touch
            </button>
          </div>
        </LayoutWrapper>
      </div>
      <ComingSoonModal />
    </AnimateRoute>
  );
};
