import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { Review } from 'shared/api/reviews/types';

import { theme } from 'app/theme';
import { timeAgo } from 'shared/helpers/timeAgo';
import { Icon } from 'shared/ui/Icon';
import { StarRating } from 'shared/ui/StarRating';

type Props = {
  isOwner?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  review: Review;
};

export const ReviewItem = ({ isOwner, onDelete, onEdit, review }: Props) => {
  return (
    <div
      className={twMerge(
        'flex w-full flex-col gap-4 border-b border-corduroy-200 px-3 py-4',
        isOwner && 'rounded-xl bg-primary-40/50',
      )}
    >
      <div className="flex flex-wrap items-center justify-between gap-3">
        <div className="flex flex-wrap items-center gap-3">
          <div className="flex size-8 min-w-8 items-center justify-center rounded-full bg-primary-40 font-klapt">
            <span className="-mt-px font-bold uppercase leading-none text-primary-800">
              {review.user?.last_name?.[0] ?? review.user?.first_name?.[0] ?? 'U'}
            </span>
          </div>

          <div className="text-base/none font-normal text-black">
            {review.user?.first_name} {review.user?.last_name}
          </div>
          <div className="text-xs/none font-light italic text-corduroy-600">
            {review.timestamp && timeAgo(DateTime.fromMillis(review.timestamp))}
          </div>

          {isOwner && (
            <div className="flex items-center gap-3">
              {onEdit && (
                <div
                  className="flex cursor-pointer items-center gap-1 text-xs text-primary-800 transition-colors hover:text-primary-1000"
                  onClick={onEdit}
                >
                  <Icon className="-mt-px size-4" name="edit" /> Edit
                </div>
              )}
              {onDelete && (
                <div
                  className="flex cursor-pointer items-center gap-1 text-xs text-primary-800 transition-colors hover:text-primary-1000"
                  onClick={onDelete}
                >
                  <Icon className="-mt-px size-4" name="trash" /> Delete
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex items-center gap-2">
          <div className="text-base/none font-bold text-black">{review?.rating?.toFixed(1)}</div>
          <StarRating
            className="-mt-1"
            containerClassName="size-5"
            rating={review.rating}
            starColor={theme.colors.primary[800]}
            svgProps={{ height: 12, width: 12 }}
          />
        </div>
      </div>

      {review.feedback ? (
        <div className="text-base font-light">{review.feedback}</div>
      ) : (
        <div className="font-light text-corduroy-500">No text</div>
      )}
    </div>
  );
};
