import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { GetUserReviewsParams, Review } from './types';

import { reviewsKeys } from './queryKeys';

export const useGetUserReviewByModelQuery = (
  { modelId, userId, ...params }: GetUserReviewsParams,
  { enabled, ...options }: { enabled?: boolean } = {},
) => {
  return useQuery({
    enabled: !!userId && !!modelId && enabled,
    queryFn: async ({ signal }) => {
      const res = await axiosInstance.get<{ data: Review }>(`reviews/models/${modelId}`, {
        params,
        signal,
      });

      return res.data;
    },
    queryKey: reviewsKeys.list({ modelId, userId, ...params }),
    ...(options || {}),
  });
};
