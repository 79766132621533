import type { TxResponse } from 'shared/api/transactions/types';

import { getReceivedAmount } from './getReceivedAmount';
import { getTxMessage } from './getTxMessage';

export const getTxBalance = ({ events, send, tx }: TxResponse) => {
  const message = getTxMessage(tx.body.messages);

  const lockBalance = tx.body?.messages?.[0]?.lock_balance;

  if (message === 'RegisterSession' && lockBalance?.amount) {
    return lockBalance.amount;
  }

  if (!send) {
    return getReceivedAmount(events);
  }

  const transaction = tx?.body?.messages?.[0];
  const payment = transaction?.payment?.total_payment || transaction?.amount?.[0];

  return payment?.amount || '';
};
