export type RequestTokensParams = {
  address: string;
  chain: string;
};

export enum FaucetRequestState {
  unknown,
  received,
  complete,
  error,
  queued,
}

export type FaucetRequestParams = {
  walletAddress: string;
};

export type FaucetRequestResponse = {
  _id: string;
  error?: string;
  ipAddress: string;
  state: FaucetRequestState;
  timestamp: number;
  tokenAmount?: string;
  txHash?: string;
  userId: string;
  walletAddress: string;
};
