import { useNavigate } from 'react-router-dom';

import { useUser } from 'app/stores/user';
import { BackButton } from 'app/ui/BackButton';
import { EmptyQueryHistory } from 'features/EmptyQueryHistory';
import { useGetQueryHistoryByUserQuery } from 'shared/api/queryHistory/useGetQueryHistoryByUserQuery';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Spinner } from 'shared/ui/Spinner';

import { QueryHistoryCard } from './ui/QueryHistoryCard';

export const QueryHistory = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const { data, isPending } = useGetQueryHistoryByUserQuery(
    { limit: 100, skip: 0, userId: user!._id },
    { enabled: !!user?._id },
  );

  const historyList = data?.data || [];

  return (
    <AnimateRoute className="flex flex-1 flex-col p-6">
      <BackButton className="mb-2" onClick={() => navigate('/')}>
        Back to home
      </BackButton>

      <h1 className="mb-4 text-2xl font-semibold">Query history</h1>

      {isPending ? (
        <div className="flex size-full items-center justify-center">
          <Spinner className="size-6" />
        </div>
      ) : historyList.length ? (
        <div className="flex flex-col gap-4">
          {historyList.map((historyItem) => {
            return <QueryHistoryCard historyItem={historyItem} key={historyItem._id} />;
          })}
        </div>
      ) : (
        <div className="flex flex-1 items-center justify-center pb-48">
          <EmptyQueryHistory withButton />
        </div>
      )}
    </AnimateRoute>
  );
};
