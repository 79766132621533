import type { ComponentProps } from 'react';
import { Link } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

export const ExternalLink = (props: ComponentProps<typeof Link>) => {
  return (
    <Link
      {...props}
      className={twMerge(
        'text-xs font-medium text-white transition-all duration-200 hover:text-primary-900 2xl:text-base',
        props.className,
      )}
    />
  );
};
