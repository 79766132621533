import { AnimatePresence, motion } from 'framer-motion';

import logoSrc from '../../assets/images/logo.svg';

export const Loading = () => {
  return (
    <AnimatePresence>
      <motion.div
        animate={{ opacity: 1 }}
        className="fixed inset-0 z-50 flex h-dvh flex-col items-center justify-center gap-6 bg-white"
        exit={{ opacity: 0 }}
        initial={{ opacity: 1 }}
      >
        <motion.img
          animate={{ opacity: 1, scale: 1 }}
          className="size-16 lg:size-20 2xl:size-16"
          initial={{ opacity: 0.5, scale: 0.975 }}
          src={logoSrc}
          transition={{ duration: 1.5, ease: 'easeOut', repeat: 0 }}
        />

        <div className="relative flex h-1.5 w-full max-w-48 overflow-hidden rounded-full bg-corduroy-900 lg:h-2 lg:max-w-64 2xl:h-1.5 2xl:max-w-48">
          <motion.div
            animate={{ maxWidth: '100%', transition: { duration: 2 } }}
            className="absolute inset-y-0 left-0 w-full rounded-full bg-primary-800 bg-gradient-to-r from-primary-900 to-primary-800"
            initial={{ maxWidth: 0 }}
          ></motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
