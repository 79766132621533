import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { CreateErrorParams, CustomError } from './types';

export const useErrorSubmitMutation = (
  options: UseMutationOptions<CustomError, Error, CreateErrorParams> = {},
) => {
  return useMutation({
    mutationFn: async (params: CreateErrorParams) => {
      const { data } = await axiosInstance.post<CustomError>(`/errors`, params);

      return data;
    },
    ...options,
  });
};
