import { useParams } from 'react-router-dom';

import { LLMQuery } from 'features/LLMQuery';
import { ModelQuery } from 'features/ModelQuery';
import { useGetModelByIdQuery } from 'shared/api/models/useGetModelByIdQuery';

export const QueryPage = () => {
  const { id: modelId } = useParams<{ id: string }>();

  const { data: model } = useGetModelByIdQuery(modelId!, { enabled: !!modelId });
  const isLLM = model?.type === 'text-generation';

  return isLLM ? <LLMQuery /> : <ModelQuery />;
};
