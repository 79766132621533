import { twJoin, twMerge } from 'tailwind-merge';

import logoSrc from 'app/assets/images/logo-white.svg';
import { Badge } from 'shared/ui/Badge';
import { Icon } from 'shared/ui/Icon';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';
import { Tooltip } from 'shared/ui/Tooltip';
type Props = {
  className?: string;
  isLoading?: boolean;
  latency?: number;
  likes?: number;
  onChipClick?: (chip: 'reviews') => void;
  price?: number;
  reviewCount?: number;
  size?: 'l' | 'm';
};

export const ModelCardBottomInfo = ({
  className,
  isLoading,
  latency,
  likes,
  onChipClick,
  reviewCount,
  size = 'm',
}: Props) => {
  const baseClassName = twMerge(
    'relative flex min-h-8 max-w-fit items-center gap-1 rounded-full border border-corduroy-200 pl-0.5 pr-1 text-sm/none font-normal text-corduroy-700 xs:px-1.5 xs:py-0.5',
    size === 'l' && 'lg:text-base',
    isLoading && 'border-transparent',
  );
  return (
    <div className={twMerge('flex flex-wrap justify-start gap-3 xs:gap-2', className)}>
      <div className={twJoin(baseClassName, '')}>
        <StretchedSkeleton className="min-w-24" enable={isLoading} rx={12} ry={12} />

        <Icon
          className={twMerge('size-5 p-0 text-black', size === 'l' && 'lg:size-7 2xl:size-5')}
          name="bolt"
        />
        <span className="-mb-px mr-1">{`< ${latency || 3}sec avg`}</span>
      </div>
      <div className={twJoin(baseClassName, isLoading && '!min-w-24')}>
        <StretchedSkeleton className="min-w-24" enable={isLoading} rx={12} ry={12} />

        <Icon
          className={twMerge('size-5 p-0 text-black', size === 'l' && 'lg:size-7 2xl:size-5')}
          name="star"
        />
        <span className="-mb-px mr-1">{likes || 0}</span>

        {false && <Badge className="bg-pink-500 leading-none text-white">Top 1%</Badge>}
      </div>

      <Tooltip content="reviews" side="top">
        <div
          className={twJoin(
            baseClassName,
            !!onChipClick &&
              'min-w-fit cursor-pointer select-none transition-colors hover:border-primary-800',
            '!pl-1.5 font-bold text-black',
            size === 'l' && '!pl-1 !pr-2',
            isLoading && '!min-w-24',
          )}
          onClick={() => onChipClick?.('reviews')}
        >
          <StretchedSkeleton className="min-w-24" enable={isLoading} rx={12} ry={12} />

          {!isLoading && (
            <>
              <svg
                className="size-6 text-black"
                fill="none"
                stroke="currentColor"
                strokeWidth={1.5}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="-mb-px mr-1">{reviewCount || 0}</span>
            </>
          )}
        </div>
      </Tooltip>

      <Tooltip content="per token" side="top">
        <div className={twJoin(baseClassName, '!pl-1.5 font-bold text-black', size === 'l' && '!pl-1 !pr-2')}>
          <StretchedSkeleton className="min-w-24" enable={isLoading} rx={12} ry={12} />

          {!isLoading && (
            <>
              <div
                className={twMerge(
                  'flex size-5 items-center justify-center rounded-full bg-black p-1',
                  size === 'l' && 'size-6',
                )}
              >
                <img className="size-full" src={logoSrc} />
              </div>
              {/* <Icon className={twMerge('size-5 p-0', size === 'l' && 'lg:size-7 2xl:size-5')} name="nesaCoin" /> */}
              <span className="-mb-px font-bold">0.001 NES</span>
            </>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
