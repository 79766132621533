import type { HTMLAttributes, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

export const Chip = ({
  children,
  className,
  ...other
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <div
      className={twMerge('ml-1 rounded-full bg-primary-50 px-2 py-1 text-xs text-primary-800', className)}
      {...other}
    >
      {children}
    </div>
  );
};
