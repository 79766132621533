import { useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import { useAccount, useBalance, useDisconnect } from 'graz';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import CoinIcon from 'app/assets/images/logo-white.svg?react';
import { useUser } from 'app/stores/user';
import { WalletProviderModal } from 'features/WalletProviderModal';
import { useGetQueryHistoryByPeriod } from 'shared/api/queryHistory/useGetQueryHistoryByPeriod';
import { useAccountTxsQuery } from 'shared/api/transactions/useAccountTxsQuery';
import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { COIN_NAME } from 'shared/const';
import { formatNumber } from 'shared/helpers/formatNumber';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';
import { getShortenedAddress } from 'shared/helpers/getShortenedAddress';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';
import { Spinner } from 'shared/ui/Spinner';

import type { PeriodType, WalletTransaction } from './types';

import { chartTabs } from './config';
import { formatDataForChart, getDatesByPeriod } from './helpers';
import { getTxBalance } from './helpers/getTxBalance';
import { getTxMessage } from './helpers/getTxMessage';
import { Chart } from './ui/Chart';
import { Transactions } from './ui/Transactions';

export const Wallet = () => {
  const [periodTab, setPeriodTab] = useState<PeriodType>('day');

  const { disconnect } = useDisconnect();
  const { user } = useUser();

  const { data: account, isConnected, isConnecting } = useAccount();
  const [stopLoadingBalance, setStopLoadingBalance] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isProviderOpen, setIsProviderOpen] = useState(false);

  const { data: uBalance, isLoading: isBalanceLoading } = useBalance({
    bech32Address: account?.bech32Address,
    chainId: nesaTestnet.chainId,
    denom: 'unes',
    enabled: !!account?.bech32Address,
  });

  const { data: accountTxs, isLoading: isLoadingTxs } = useAccountTxsQuery(
    { address: account?.bech32Address, limit: 1000, orderBy: 'ORDER_BY_DESC' },
    { enabled: Boolean(account?.bech32Address) },
  );

  const { data: accountTxsSender, isLoading: isLoadingTxsSender } = useAccountTxsQuery(
    { address: account?.bech32Address, isSender: true, limit: 1000, orderBy: 'ORDER_BY_DESC' },
    { enabled: Boolean(account?.bech32Address) },
  );

  const filterDates = useMemo(() => getDatesByPeriod(periodTab), [periodTab]);

  const { data, isPending } = useGetQueryHistoryByPeriod(
    { endDate: filterDates.endDate, startDate: filterDates.startDate, userId: user!._id },
    { enabled: !!user?._id },
  );

  const transactions = useMemo(() => {
    if (!accountTxs?.tx_responses.length) return [];

    const sendTxs = accountTxsSender?.tx_responses.map((item) => ({ ...item, send: true })) || [];
    const result = [...sendTxs, ...accountTxs.tx_responses];

    result.sort(({ timestamp: timestampA }, { timestamp: timestampB }) => {
      if (!timestampA || !timestampB) return 0;

      return DateTime.fromISO(timestampA).diff(DateTime.fromISO(timestampB)).toMillis();
    });

    return result.reduce<WalletTransaction[]>((acc, el) => {
      const date = el.timestamp;
      const message = getTxMessage(el.tx.body.messages);

      const balance = getTxBalance(el);

      if (date && balance) {
        acc.push({
          data: message,
          date: date,
          gas: fromUnesToNes(el.gas_used).toString(),
          id: el.txhash,
          send: el.send,
          type: el.send ? 'sent' : 'received',
          value: fromUnesToNes(balance).toString(),
        });
      }

      return acc;
    }, []);
  }, [accountTxs, accountTxsSender]);

  const balanceBN = uBalance?.amount ? fromUnesToNes(uBalance.amount) : undefined;

  const chartData = useMemo(() => formatDataForChart(periodTab, data), [data, periodTab]);

  useEffect(() => {
    if (!account?.bech32Address) return;

    const timeout = setTimeout(() => {
      setStopLoadingBalance(true);
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [account?.bech32Address]);

  const isEmptyChartData = chartData.every((el) => el.countTransactions === 0);

  return (
    <div className="flex min-h-full w-full flex-col px-2 py-4 md:p-5 2xl:px-0">
      <section className="relative mx-auto flex w-full max-w-2xl flex-col gap-4">
        {!isConnected && (
          <div className="absolute inset-0 z-10 flex size-full items-center justify-center bg-white/80">
            <Button isLoading={isConnecting} onClick={() => setIsProviderOpen(true)}>
              Connect wallet{' '}
            </Button>
          </div>
        )}

        <header className="flex flex-wrap items-center justify-between gap-3 rounded-2xl bg-white px-4 py-3 drop-shadow-smAll 2xl:p-4">
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center rounded-full bg-primary-800">
              <CoinIcon className={twMerge('size-7  p-1.5 lg:size-8')} />
            </div>

            <div className="flex items-center gap-1 text-base/none font-light text-black 2xl:text-lg/none">
              {isBalanceLoading && !stopLoadingBalance ? (
                <Spinner className="size-3" />
              ) : balanceBN ? (
                <b>{formatNumber(balanceBN.toNumber(), { decimals: 6 })}</b>
              ) : (
                '-'
              )}{' '}
              {COIN_NAME}
            </div>
          </div>

          {isConnected && (
            <div className="flex items-center gap-3">
              <CopyToClipboard onCopy={() => toast.success('Copied')} text={account?.bech32Address || ''}>
                <div className="flex cursor-pointer items-center justify-center gap-1 rounded-full bg-tusk-100 px-3 py-1 text-sm text-corduroy-800 transition-colors hover:bg-tusk-300">
                  {account?.bech32Address ? getShortenedAddress(account.bech32Address) : ''}
                </div>
              </CopyToClipboard>

              <Popover>
                <Popover.Trigger>
                  <div className="flex size-7 cursor-pointer items-center justify-center rounded-full border border-corduroy-200 bg-corduroy-100 p-0.5 transition-colors hover:bg-corduroy-200">
                    <Icon className="text-black" name="more" />
                  </div>
                </Popover.Trigger>

                <Popover.Content align="end" className="p-0" side="bottom" sideOffset={8}>
                  <CopyToClipboard onCopy={() => toast.success('Copied')} text={account?.bech32Address || ''}>
                    <div className="flex cursor-pointer items-center gap-2 border-b border-corduroy-200 px-4 py-3 transition-colors hover:bg-corduroy-100 hover:text-primary-800">
                      <Icon name="copy" /> <span className="leading-none">Copy Address</span>
                    </div>
                  </CopyToClipboard>
                  <div
                    className="flex cursor-pointer items-center gap-2 px-4 py-3 transition-colors hover:bg-corduroy-100 hover:text-primary-800"
                    onClick={() => disconnect()}
                  >
                    <Icon name="powerOffCircle" /> <span className="leading-none">Disconnect Wallet</span>
                  </div>
                </Popover.Content>
              </Popover>
            </div>
          )}
        </header>

        <section className="flex flex-col rounded-2xl bg-white px-5 pb-1  pt-3 drop-shadow-smAll 2xl:py-4 ">
          <div className="flex flex-wrap items-center gap-3">
            <div className="flex-1 text-lg font-normal text-corduroy-500">Last activity</div>
            <div className="flex gap-1.5">
              {chartTabs.map((tab) => (
                <button
                  aria-selected={tab.value === periodTab}
                  className="h-7 w-11 rounded-full bg-corduroy-100 text-center text-sm font-medium leading-7 text-corduroy-800 transition-colors aria-selected:bg-primary-800 aria-selected:text-white hover:bg-primary-100 hover:text-corduroy-800 aria-selected:hover:bg-primary-1000 aria-selected:hover:text-white xs:w-12"
                  key={tab.value}
                  onClick={() => setPeriodTab(tab.value)}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </div>

          <div className="relative -ml-1 -mr-2 h-60">
            {(isPending || isEmptyChartData) && (
              <div
                className={twMerge(
                  'absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2 rounded-full bg-corduroy-100 px-5 py-2 text-sm font-semibold text-corduroy-500',
                  isPending && 'z-10',
                )}
              >
                {isPending ? <Spinner className="size-5 text-primary-1000" /> : 'No Data'}
              </div>
            )}
            <Chart data={chartData} isEmptyData={isEmptyChartData} periodType={periodTab} />
          </div>
        </section>
        <section className="flex flex-1 flex-col overflow-hidden rounded-2xl bg-white p-4 pb-0 drop-shadow-smAll">
          <div className="mb-1 flex items-center justify-between gap-4">
            <div className="flex-1 text-lg font-normal text-corduroy-500">Latest Transactions</div>
            <Button
              color="secondary"
              onClick={() => setShowMore((prev) => !prev)}
              size="extra-small"
              variant="filled-light"
            >
              {showMore ? 'View Less' : 'View More'}
            </Button>
          </div>
          <Transactions
            isLoading={isLoadingTxs || isLoadingTxsSender}
            showMore={showMore}
            transactions={transactions}
          />
        </section>
        <footer className="flex gap-3 p-4 pt-3">
          <Button className="flex-1" onClick={() => toast.info('Coming soon')}>
            Deposit NES
          </Button>
          <Button
            className="flex-1"
            color="secondary"
            onClick={() => toast.info('Coming soon')}
            variant="filled-light"
          >
            Withdraw NES
          </Button>
        </footer>
      </section>

      <WalletProviderModal onOpenChange={setIsProviderOpen} open={isProviderOpen} />
    </div>
  );
};
