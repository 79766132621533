import { NavLink } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Icon } from 'shared/ui/Icon';
import { Tooltip } from 'shared/ui/Tooltip';

import type { SidebarItem } from '../../types';

type Props = {
  icon?: string;
  iconClassName?: string;
  iconWrapperClassName?: string;
  isExpanded: boolean;
} & ClassName &
  SidebarItem;

export const SidebarLink = ({
  className,
  disabled,
  icon,
  iconClassName,
  iconWrapperClassName,
  isExpanded,
  name,
  onClick,
  path,
}: Props) => {
  return (
    <AnimatePresence>
      <Tooltip content={!isExpanded ? name : ''}>
        <NavLink
          className={twMerge(className, disabled && 'pointer-events-none')}
          key={path}
          onClick={onClick}
          onPointerDown={(e) => {
            e.preventDefault();
          }}
          to={path}
        >
          {({ isActive }) => (
            <div
              aria-disabled={disabled || undefined}
              className={twMerge(
                'flex h-9 w-fit items-center gap-2 rounded-lg p-2 text-gray-500 transition-colors hover:bg-blue-50',
                isActive && 'bg-tusk-100 text-blue-900 hover:bg-tusk-100',
              )}
            >
              <Icon className={twMerge('size-4 min-w-4', iconClassName)} name={icon} />
              {isExpanded && (
                <motion.div
                  animate={{ opacity: 1, width: 'auto' }}
                  className={twMerge(
                    'overflow-hidden whitespace-nowrap text-sm font-medium',
                    iconWrapperClassName,
                  )}
                  exit={{ opacity: 0, width: '0 !important' }}
                  initial={{ opacity: 0, width: 0 }}
                >
                  {name}
                </motion.div>
              )}
            </div>
          )}
        </NavLink>
      </Tooltip>
    </AnimatePresence>
  );
};
